.share-icons-container{
    align-items: center;
    top: 40px;
    height: 30px;
    right: 0px;
    justify-content: center;
    position: absolute;
    width: 30px;
    z-index: 1;
}

.share-icons-container .rt-share-icon-btn{
    background: transparent;
    border: none;
    margin-top: 6px;
}
