.filters-page-house-card-notes-box {
    display: flex;
    flex-direction: column;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    -webkit-transform: translate(-50%, -50%);
    z-index: 5;
    width: calc(100% - 20px);
    max-width: 700px;
    background-color: var(--third-bg);
    border-radius: 10px;
}

.filters-page-house-card-notes-box-narrow {
    display: flex;
    flex-direction: column;
    position: absolute;
    left: 50%;
    top: 0;
    transform: translate(-50%, 0);
    -webkit-transform: translate(-50%, 0);
    z-index: 5;
    width: 100%;
    max-width: 700px;
    background-color: var(--third-bg);
    border-radius: 10px;
    height: 248px;
}

.filters-page-house-card-notes-box-disabled {
    display: none;
}