.filters-page-house-card-content-active {
  display: flex;
  /* gap: 16px; */
  position: absolute;
  top: 16px;
  left: 400px;
  width: calc(100% - 32px - 385px);
  z-index: 1;
  /* background: #2c353c75; */
  border-radius: 5px;
  padding: 4px !important;
  flex-direction: column;
}

.filters-page-house-card-content-active-main-content-wrapper {
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: space-between;
}

.filters-page-house-card-content-active-right {
  align-items:flex-end;
}

.filters-page-house-card-content-active-right {
  display: flex;
  flex-direction: column;
  width: calc(35% - 8px);
}
.filters-page-house-card-content-active-left {
  display: flex;
  flex-direction: column;
  width: calc(65% - 8px);
}

.filters-page-house-card-content-active-right h4,
.filters-page-house-card-content-active-left  h4 {
  color: var(--blue) !important;
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: 26px;
  letter-spacing: 0.24px;
}

.filters-page-house-card-content-active-bottom {
  display: flex;
  width: 100%;
    /* overflow: hidden; */
}

.filters-page-house-card-content-active-bottom p {
  color: var(--second-bg);
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  letter-spacing: 0.32px;
  /* text-overflow: ellipsis; */
  /* display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 16;  
  white-space: pre-wrap;
  overflow: hidden; */
}

/* .filters-page-house-card-content-active-bottom p {
  display: -webkit-box;
  -webkit-line-clamp: 16;
  -webkit-box-orient: vertical;
  overflow: hidden;
  position: relative;
}

.filters-page-house-card-content-active-bottom a {
  content: '';
  position: absolute;
  bottom: 0;
  right: 0;
  width: 8px; Adjust the size of the dot
  height: 8px; Adjust the size of the dot
  background: radial-gradient(circle, red 50%, transparent 50%);
} */

.new-wrapper-main-both .filters-page-house-card-content-active-bottom {
  height: 180px;
}

.new-wrapper-main-both .filters-page-house-card-content-active {
  padding: 0 !important;
}

.filters-page-house-card-content-active-bottom {
  overflow: hidden !important;
  color: var(--second-bg);
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  letter-spacing: 0.32px;
  height: 260px;
  position: relative;
  line-height: 20px;
  text-align: justify;
}

.filters-page-house-card-content-active-dots {
  position: absolute;
  right: 0px;
  bottom: -2px;
  background-color: var(--third-bg);
  height: 20px;
  width: 60px;
  display: flex;
  justify-content: end;
  z-index: 1000000;
}

.filters-page-house-card-content-active-dots a {
  text-decoration: none;
  position: absolute;
  right: 0;
  bottom: 3px;

}

.filters-page-house-card-content-adress a {
  text-decoration: none;
  color: var(--blue);
}

.filters-page-house-card-content-active-dots a span {
  font-size: 20px !important;
  color: var(--blue);
}

.filters-page-house-card-content-active-dots a span:last-child {
  font-size: 16px !important;
}

.filters-page-house-card-content-active-dots > div {
  position: relative;
}

.filters-page-house-card-content-active-paragraph {
  color: var(--third-bg);
  top: 0;
  left: 0;
  position: absolute;
  background-color: var(--third-bg);
}

.filters-page-house-card-content-active-left,
.filters-page-house-card-content-active-right {
  overflow: hidden;
}

.filters-page-house-card-content-active-type h4,
.filters-page-house-card-content-adress h4{
  text-overflow: ellipsis; 
  white-space: nowrap; 
  overflow: hidden;
}

.filters-page-house-card-content-active-type {
  width: 100%;
  text-align: right;
}

.filters-page-house-card-content-bd-ba-active {
  display: flex;
  gap: 16px;
}

.filters-page-house-card-content-area-active>div,
.filters-page-house-card-content-bd-ba-active>div {
  display: flex;
  align-items: center;
}

.filters-page-house-card-content-area-active p,
.filters-page-house-card-content-bd-ba-active p {
  padding-left: 8px;
  color: var(--white);
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  letter-spacing: 0.24px;
}

.filters-page-house-card-content-active .filters-page-house-card-content-area-active-icon-block {
  height: 26px;
  display: flex;
  align-items: center;
}

/* .filters-page-house-card-wrapper-active:hover .slick-list {
  margin: 0 -1px !important;
}
.filters-page-house-card-wrapper-active:hover .slick-slide > div {
  padding: 0 1px !important;
} */