#sorting-block-wrapper {
  display: flex;
  flex-direction: column;
  position: relative;
}

#sorting-block-box {
  display: flex;
  justify-content: space-between;
  align-items: center;
  /* width:100%; */
  /* border-top: 1px solid var(--gray);
  padding-top: 24px; */
}

#sorting-block-input-wrapper ul li:first-child {
  border-bottom: 1px solid var(--gray);
}

#sorting-block-input-wrapper {
  /* width: 550px; */
  height: 55px;
  border-radius: 10px;
  /* background-color: var(--main-bg); */
}

#sorting-block-icons-wrapper {
  display: flex;
  gap: 12px;
  /* justify-content: space-between; */
  align-items: center;
  padding: 0 24px;
  border: 1px solid var(--gray);
  border-radius: 10px;
}

#sorting-block-icon-3,
#sorting-block-icon-2,
#sorting-block-icon-1_5,
#sorting-block-icon-1 {
  display: flex;
  align-items: center;
}

#sorting-block-icon-1:hover .sorting-block-icon-1-tooltip {
  display: block;
}

#sorting-block-icon-1_5:hover .sorting-block-icon-1_5-tooltip {
  display: block;
}

#sorting-block-icon-2:hover .sorting-block-icon-2-tooltip {
  display: block;
}

.sorting-block-icon-1-five-cube,
.sorting-block-icon-1-four-cube,
.sorting-block-icon-1-third-cube,
.sorting-block-icon-1-second-cube {
  /* position: absolute;
  left: 9.5px; */
  display: none;
  /* align-items: center; */
}  

#sorting-block-icon-1_5 svg:first-child  {
  margin-right: -5px;
}

#sorting-block-icon-0:hover,
#sorting-block-icon-1:hover, 
#sorting-block-icon-1_5:hover,
#sorting-block-icon-2:hover,
#sorting-block-icon-3:hover {
  cursor: pointer;
} 

#sorting-block-icon-3 {
  display: none;
}

#sorting-block-wrapper .sorting-block-mapList-wrapper {
  position:absolute;
  display: flex;
  flex-direction: column;
  padding: 8px;
  border-radius: 10px;
  background-color: #f9f9f9;
  box-shadow: 4px 0 30px 0 rgba(0,0,0,.25);
  gap: 16px;
  top: 52px;
  right: 55px;
  z-index: 3;
  cursor: initial;
}

#sorting-block-wrapper .sorting-block-mapList-context {
  font-size: 14px;
  padding: 10px;
}

#sorting-block-wrapper .sorting-block-mapList-context:hover {
  background-color: #e0e0e0;
  border-radius: 10px;
  cursor: pointer
}
