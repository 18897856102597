@media screen and (max-width: 1079px) {
  .MainHeaderLayer-ListingsInfo-wrapper {
    left: calc(50% - 80px);
  }
}

@media screen and (max-width: 599px) {
  .MainHeaderLayer-ListingsInfo-wrapper span:last-child {
    display: none;
  }
}

@media screen and (max-width: 768px) {
  .SpecsPopup-wrapper,
  .TypePopup-wrapper,
  .LocationPopup-wrapper {
    width: 100%;
    left: 0px;
    height: calc(100svh - 77px);
  }

  .main-container-wrapper {
    margin-left: auto;
  }

  /* .MainHeaderLayer-MainHeader-content-filters {
    margin-left: auto;
  } */

  /* .MainHeaderLayer-MainHeader-content-wrapper {
    height: 156px;
  } */

  /* .MainHeaderLayer-MainHeader-content {
    justify-content: space-between;
  } */
}

@media screen and (min-width: 769px) {
  .MainHeaderLayer-MainHeader-content-TripleSwitcherSliderButton-mapOnly {
    display: none !important;
  }

  .LocationPopup-wrapper-header-content-block {
    min-height: 400px;
  }

  .SpecsPopup-wrapper-header-content-block {
    min-height: 560px;
  }

  .TypePopup-wrapper-header-content-block {
    min-height: 520px;
  }
}

/* @media screen and (max-width: 540px) {
  .MainHeaderLayer-MainHeader-filters-wrapper .HeaderLayerSpecs-wrapper,
  .MainHeaderLayer-MainHeader-filters-wrapper .HeaderLayerType-wrapper {
    display: none;
  }
}

@media screen and (min-width: 400px) {
  .MainHeaderLayer-MainHeader-filters-wrapper .HeaderLayerSpecs-wrapper {
    display: flex;
  }
} */

/* @media screen and (max-width: 470px) {
  .MainHeaderLayer-MainHeader-content {
    padding: 0 0 0 8px;
  }

  .MainHeaderLayer-MainHeader-content-TripleSwitcherSliderButton-mapOnly {
    margin-right: 8px;
  }
} */

@media screen and (max-width: 548px) {
  .sorting-block-icon-3-filters-icons {
    display: flex;
  }
  .sorting-block-icon-3-filters-title {
    display: none;
  }

  .MainHeaderLayer-MainHeader-content-TripleSwitcherSliderButton-mapOnly,
  .sorting-block-icon-3-filters {
    width: 38px;
    background-color: var(--blue);
  }

  .MainHeaderLayer-MainHeader-content-TripleSwitcherSliderButton-mapOnly {
    background-color: var(--third-bg);
  }

  .MainHeaderLayer-MainHeader-content-TripleSwitcherSliderButton-mapOnly-icon {
    display: flex;
  }

  .MainHeaderLayer-MainHeader-content-TripleSwitcherSliderButton-mapOnly-title {
    display: none;
  }

  .HeaderLayerPrice-wrapper,
  .HeaderLayerType-wrapper,
  .HeaderLayerLocation-wrapper,
  .HeaderLayerSpecs-wrapper {
    background-color: var(--blue);
    color: var(--white);
  }

  .MainHeaderLayer-MainHeader-content-filters {
    margin-left: 0;
  }
  .MainHeaderLayer-MainHeader-content {
    justify-content: space-between;
  }

  /* /// */
  .MainHeaderLayer-MainHeader-content-wrapper
    .MainHeaderLayer-MainHeader-content
    .MainHeaderLayer-MainHeader-filters-wrapper:first-child {
    display: none;
  }

  .MainHeaderLayer-MainHeader-content {
    padding: 0 0 0 8px;
  }

  .MainHeaderLayer-MainHeader-content-TripleSwitcherSliderButton-mapOnly {
    margin-right: 8px;
  }
}

@media screen and (max-width: 416px) {
  .MainHeaderLayer-MainHeader-content,
  .MainHeaderLayer-MainHeader-filters-wrapper {
    gap: 6px;
  }
}
