.filters-page-base-input-map-list-wrapper {
  width: 80px;
  position: relative;
}

.filters-page-base-input-map-list-end-icon-wrapper {
  height: 22px;
  position: absolute;
  right: 4px;
  top: 6px;
  cursor: pointer;
}

.filters-page-base-input-map-list-wrapper input {
  border-radius: 6px;
  height: 100%;
  border: 1px solid var(--gray);
  cursor: pointer;
  height: 16px;
  padding: 8px !important;
  font-size: 14px;
  font-family: Inter;
  font-weight: 400;
}


.filters-page-base-input-map-list-wrapper input:is(:focus, :active, :visited, :focus-visible) {
  border-color: var(--gray) !important;
  outline: none!important;
  box-shadow: none;
}

.filters-page-base-input-map-list-wrapper input:read-only {
  background-color: var(--white);
}

.filters-page-base-input-map-list-wrapper input::-webkit-input-placeholder { 
  color: var(--main-bg);
}

.filters-page-base-input-map-list-wrapper input:-ms-input-placeholder {
  color: var(--main-bg);
}

.filters-page-base-input-map-list-wrapper input::placeholder {
  color: var(--main-bg);
}

/* //SCROLL BAR STYLE */

.filters-page-base-input-map-list-wrapper ul::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background-color: #F2F2F2;
  border-radius: 10px;
}

.filters-page-base-input-map-list-wrapper ul::-webkit-scrollbar {
  border-radius: 10px;
  width: 6px;
  background-color: #F2F2F2;
}

.filters-page-base-input-map-list-wrapper ul::-webkit-scrollbar-thumb {
  background-color: var(--gray) !important;
  border-radius: 10px;
}

/* LIST STyle */

.filters-page-base-input-map-list-wrapper ul {
  position: absolute;
  list-style-type: none;
  top: 54px;
  padding: 0;
  margin: 0;
  z-index: 5;
  border-bottom-left-radius: 6px;
  border-bottom-right-radius: 6px;
  border: 1px solid var(--gray);
  background-color: var(--main-bg);
  /* display: flex; */
  flex-direction: column;
  gap: 4px;
  box-sizing: border-box;
  max-height: 175px;
  overflow-y: auto;
  display: none;
  width: 100%
}

.filters-page-base-input-map-list-wrapper ul li:last-child p {
  border-bottom-left-radius:6px;
  /* border-bottom-right-radius: 6px; */
}

.filters-page-base-input-map-list-wrapper ul > li > p {
  background-color: var(--white);
  padding: 4px; /* Add some padding */
  text-decoration: none; /* Remove default text underline */
  font-weight: 400;
  font-size: 14px !important;
  line-height: 19px;
  font-family: inter;
  /* color: var(--white); */
  margin-bottom: 0;
}

.filters-page-base-input-map-list-wrapper ul li p:hover {
  background-color: var(--blue);
  color: var(--body-text);
  cursor: pointer;
  opacity: 1;
}

.filters-page-base-input-map-list-blue-wrapper {
  color: var(--blue) !important;
}

.filters-page-base-input-map-list-blue-wrapper:hover {
  background-color: var(--blue);
  color: var(--body-text)!important;;
  cursor: pointer;
  opacity: 1;
}