.increase-decrease-block {
  border: 1px solid var(--gray);
  border-radius: 10px;
  width: 100%;
  height: 100%;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: white;
} 

.increase-decrease-block-left-icon {
  position: absolute;
  top: 11px;
  left: 23px;
  cursor: pointer;
}

.increase-decrease-block-right-icon {
  position: absolute; 
  top: 16px;
  right: 23px;
  cursor: pointer;
}

.increase-decrease-block-value p {
  color: var(--second-bg);
  opacity: 0.7;
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}