#filters-block-filters-buttons-block-wrapper {
    display: flex;
    padding: 24px;
    gap: 8px;
    border: 1px solid var(--gray);
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
    background-color: var(--third-bg);
}

.filters-block-filters-buttons-block-wrapper-sticky {
    position: -webkit-sticky;
    position: sticky;
    bottom: 0px;
    z-index: 10;
    background-color: var(--white);
}

#filters-block-filters-buttons-block-wrapper button {
    border-radius: 4px;
    color: var(--body-text);
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: 0.32px;
    border: none;
    height: 43px;
    cursor: pointer;
}

#filters-block-filters-buttons-block-reset {
    background-color: var(--main-bg);
    width: 50%;
}

#filters-block-filters-buttons-block-save {
    background-color: var(--main-bg);
    width: 50%;
}

#filters-block-filters-buttons-block-apply {
    background-color: var(--blue);
    width: 50%;
}

#filters-block-filters-buttons-block-apply-loading {
    background-color: var(--blue);
    width: 50%;
    opacity: 0.8;
    cursor:initial !important
}