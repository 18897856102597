.home-wrapper {
    position: relative;
    z-index: 50;
}

.home-wrapper-spiner {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%) !important;
    -webkit-transform: translate(-50%, -50%) !important;
}

.home-wrapper-spiner-body {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%) !important;
    -webkit-transform: translate(-50%, -50%) !important;
}

.home-wrapper-spiner-gray-wrapper {
    top: 0;
    left: 0;
    position: fixed;
    /* width: 100svw; */
    height: 100vh;
    z-index: 1000000;
    opacity: 0;
}

/* /////////////////////////////// */
#new-footer {
    height: 500px;
    width: 100%;
    background-color: rgb(24, 23, 23);
}
