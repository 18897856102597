.filters-page-search-active-input-list-wrapper {
  height: 100%;
  width: 100%;
  position: relative;
}

.filters-page-search-active-input-list-end-icon-wrapper {
  height: 22px;
  position: absolute;
  right: 24px;
  top: 50%;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
}

.filters-page-search-active-input-list-wrapper input {
  border-radius: 10px;
  border: 1px solid var(--gray);
  cursor: pointer;
  height: 100%;
  color: var(--blue)
}


.filters-page-search-active-input-list-wrapper input:is(:focus, :active, :visited, :focus-visible) {
  border-color: var(--gray) !important;
  outline: none!important;
  box-shadow: none;
}

.filters-page-search-active-input-list-wrapper input:read-only {
  background-color: var(--white);
}

.filters-page-search-active-input-list-wrapper input::-webkit-input-placeholder { 
  color: var(--main-bg);
}

.filters-page-search-active-input-list-wrapper input:-ms-input-placeholder {
  color: var(--main-bg);
}

.filters-page-search-active-input-list-wrapper input::placeholder {
  color: var(--main-bg);
}

/* LIST STyle */

.filters-page-search-active-input-list-wrapper ul {
  position: absolute;
  list-style-type: none;
  top: 54px;
  padding: 0;
  margin: 0;
  z-index: 5;
  /* width: 550px; */
  border-bottom-left-radius: 10px !important;
  border-bottom-right-radius: 10px !important;
  border: 1px solid var(--gray) !important;
  background-color: var(--white);
  /* display: flex; */
  flex-direction: column;
  gap: 4px;
  /* box-sizing: border-box; */
  overflow-y: auto;
  display: none;
  max-height: 258px;
}

.filters-page-search-active-input-list-wrapper svg {
  cursor: pointer
}

.filters-page-search-active-input-list-no-data {
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
  border: 1px solid var(--gray);
  background-color: var(--white);
}

/* .filters-page-search-active-input-list-wrapper ul li:last-child p {
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
} */

/* .filters-page-search-active-input-list-no-data li .content:hover {
  background-color: white !important;
  color: var(--main-bg) !important;
  cursor: initial !important;
} */

.filters-page-search-active-input-list-wrapper .noResult,
.filters-page-search-active-input-list-wrapper .content {
  background-color: var(--white);
  padding: 12px 12px 12px 30px; /* Add some padding */
  text-decoration: none; /* Remove default text underline */
  font-weight: 400;
  font-size: 15px;
  line-height: 19px;
  font-family: inter;
  color: var(--main-bg);
  margin-bottom: 0;
}

.filters-page-search-active-input-list-wrapper .title {
  display: flex;
  align-items: center;
  padding: 12px; /* Add some padding */
}

.filters-page-search-active-input-list-wrapper .title p{
  margin-left: 5px;
  background-color: var(--white);
  text-decoration: none; /* Remove default text underline */
  font-size: 15px !important;
  line-height: 19px;
  font-family: inter;
  color: var(--main-bg);
  margin-bottom: 0;
  font-weight: 600 !important;
}

/* .filters-page-search-active-input-list-wrapper .content p:hover {
  background-color: var(--blue);
  color: var(--body-text);
  cursor: pointer;
} */

.filters-page-search-active-input-list-wrapper .content a {
  text-decoration: none;
  color: var(--main-bg);
}

.filters-page-search-active-input-list-wrapper .content:hover a{
  color: var(--body-text) !important;
}

.filters-page-search-active-input-list-wrapper .content:hover {
  background-color: var(--blue) !important;
  color: var(--body-text) !important;
  cursor: pointer !important;
  width: 100%;
}

.filters-page-search-active-input-list-wrapper ul .loader {
  height: 30px;
  width: 30px;
}

