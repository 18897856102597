.filters-page-house-card-note-content-popup {
    /* position: absolute; */
    /* width: calc(100% - 20px);
  max-width: 700px; */
    display: flex;
    justify-content: center;
    align-items: center;
    /* left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  -webkit-transform: translate(-50%, -50%); */
    background-color: var(--third-bg);
    z-index: 2;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    cursor: initial;
    border: 1px solid var(--gray);
    padding-top: 25px;
    z-index: 3;
    height: 129px;
    /* height: calc((100vw - 518px)*0.666666) !important; */
    border-bottom: none;
}

.filters-page-house-card-note-content-popup-narrow {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: var(--third-bg);
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    cursor: initial;
    border: 1px solid var(--gray);
    z-index: 3;
    border-bottom: none;
}

.dd {
    width: 100%;
    height: 100%;
    padding: 8px;
    border-radius: 10px;
    margin: 0 16px;
    border: 1px solid var(--gray);
    background-color: var(--white);
}

.dd-narrow {
    width: calc(100% - 16px);
    padding: 8px;
    border-radius: 10px;
    border: 1px solid var(--gray);
    background-color: var(--white);
    margin-top: 22px
}

div::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    background-color: #F2F2F2;
    border-radius: 10px;
}

div::-webkit-scrollbar {
    border-radius: 10px;
    width: 6px;
    background-color: #F2F2F2;
}

div::-webkit-scrollbar-thumb {
    background-color: var(--gray) !important;
    border-radius: 10px;
}

.filters-page-house-card-note-content-popup-content-paragraph {
    color: transparent;
}

.filters-page-house-card-note-content-popup-disabled {
    display: none;
}

.filters-page-house-card-notes-box-narrow .filters-page-house-card-note-content-popup-wrapper {
    position: relative;
    /* padding: 16px; */
    width: 100%;
}

.filters-page-house-card-notes-box-narrow .filters-page-house-card-note-content-popup-close {
    position: absolute;
    right: 4px;
    top: 4px;
    cursor: pointer;
}

.filters-page-house-card-notes-box .filters-page-house-card-note-content-popup-wrapper {
    position: relative;
    /* padding: 16px; */
    width: 100%;
    height: 100%;
}

.filters-page-house-card-notes-box .filters-page-house-card-note-content-popup-close {
    position: absolute;
    right: 4px;
    top: 4px;
    cursor: pointer;
}

.filters-page-house-card-note-content-popup-content-text p {
    color: var(--white);
    font-family: Inter;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    text-align: left;
}

.filters-page-house-card-note-content-popup-content-text {
    display: flex;
    gap: 8px
}

.filters-page-house-card-note-content-popup-content-date {
    color: var(--white);
    font-family: Inter;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    text-align: right;
}

.dd-narrow .filters-page-house-card-note-content-popup-content-wrapper {
    padding: 4px;
    display: flex;
    flex-direction: column-reverse;
    gap: 16px;
    overflow-y: auto;
    height: 102px;

    /* border: 1px solid var(--gray);
  border-radius: 8px;
  background-color: white; */
}

.dd .filters-page-house-card-note-content-popup-content-wrapper {
    padding: 4px;
    display: flex;
    flex-direction: column-reverse;
    gap: 16px;
    overflow-y: auto;
    height: 100px;
    /* border: 1px solid var(--gray);
  border-radius: 8px;
  background-color: white; */
}

.filters-page-house-card-note-content-popup-content-message-user-narrow {
    background-color: var(--green);
    margin-left: 25px;
}

.filters-page-house-card-note-content-popup-content-message-user {
    background-color: var(--green);
    margin-left: 70px;
}

.filters-page-house-card-note-content-popup-content-message-agent-narrow {
    background-color: var(--blue);
    margin-right: 25px;
}

.filters-page-house-card-note-content-popup-content-message-agent {
    background-color: var(--blue);
    margin-right: 70px;
}

.filters-page-house-card-note-content-popup-content-message-wrapper-narrow {
    border-radius: 8px;
    padding: 8px;
    display: flex;
    flex-direction: column;
    gap: 8px;
    position: relative;
}

.filters-page-house-card-note-content-popup-content-message-wrapper {
    border-radius: 8px;
    padding: 16px;
    display: flex;
    flex-direction: column;
    gap: 8px;
    position: relative;
}

.filters-page-house-card-note-content-popup-content-avatar-narrow {
    position: absolute;
    border-radius: 50%;
    width: 25px;
    height: 25px;
    padding: 2px;
    bottom: 2px;
}

.filters-page-house-card-note-content-popup-content-avatar {
    position: absolute;
    border-radius: 50%;
    width: 40px;
    height: 40px;
    padding: 5px;
    bottom: 5px;
}

.filters-page-house-card-note-content-popup-content-user-avatar {
    background-color: var(--green);
    left: -50px;
}

.filters-page-house-card-note-content-popup-content-user-avatar-narrow {
    background-color: var(--green);
    left: -28px;
}

.filters-page-house-card-note-content-popup-content-agent-avatar-narrow svg path,
.filters-page-house-card-note-content-popup-content-agent-avatar svg path,
.filters-page-house-card-note-content-popup-content-user-avatar-narrow svg path,
.filters-page-house-card-note-content-popup-content-user-avatar svg path {
    fill: var(--white)
}

.filters-page-house-card-note-content-popup-content-agent-avatar-narrow {
    background-color: var(--blue);
    right: -28px;
}

.filters-page-house-card-note-content-popup-content-agent-avatar {
    background-color: var(--blue);
    right: -50px;
}