.filters-page-multiple-active-input-values-wrapper {
  display: flex;
  align-items: center;
  flex-direction: column;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  background-color: var(--white);
  padding: 0 4px;
}

.filters-page-multiple-active-input-values-scroll {
  max-height: 135px;
  overflow-y: auto;
  width: 100%;
  padding-right: 4px;
}

.filters-page-multiple-active-input-value:hover {
  background-color: var(--body-text);
}

.filters-page-multiple-active-input-value {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 12.5px 0 12.5px 8px;
}

.filters-page-multiple-active-input-value-content {
  width: calc(100% - 16px);
}

.filters-page-multiple-active-input-value-content p{
  white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  color: var(--blue) !important;
}

.filters-page-multiple-active-input-value-close-icon {
  margin-right: 5px;
  cursor: pointer;
}

.filters-page-multiple-active-input-value-close-icon:hover {
  opacity: 0.7;
}

.filters-page-multiple-active-input-list-wrapper {
  height: auto !important;
  width: 100% !important;
  position: relative !important;
}

.filters-page-multiple-active-input-list-outside-wrapper {
  height: 22px;
  position: absolute;
  right: 24px;
  top: 50%;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
}

.filters-page-multiple-active-input-list-wrapper input {
  border-radius: 10px;
  border: 1px solid var(--gray);
  cursor: pointer;
  height: 100%;
  display: inline-block;
  padding-right: 40px !important;
  white-space: nowrap;
  overflow: hidden !important;
  text-overflow: ellipsis;
}


.filters-page-multiple-active-input-list-wrapper input:is(:focus, :active, :visited, :focus-visible) {
  border-color: var(--gray) !important;
  outline: none!important;
  box-shadow: none;
}

.filters-page-multiple-active-input-list-wrapper input:read-only {
  background-color: var(--white);
}

.filters-page-multiple-active-input-list-wrapper input::-webkit-input-placeholder { 
  color: var(--main-bg);
}

.filters-page-multiple-active-input-list-wrapper input:-ms-input-placeholder {
  color: var(--main-bg);
}

.filters-page-multiple-active-input-list-wrapper input::placeholder {
  color: var(--main-bg);
}

/* LIST STyle */

.filters-page-multiple-active-input-list-wrapper ul {
  position: absolute;
  list-style-type: none;
  top: 54px;
  padding: 0;
  margin: 0;
  z-index: 5;
  width: 550px;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
  border: 1px solid var(--gray);
  background-color: var(--white);
  /* display: flex; */
  flex-direction: column;
  gap: 4px;
  box-sizing: border-box;
  max-height: 375px;
  overflow-y: auto;
  display: none;
  max-height: 258px;
}

.filters-page-multiple-active-input-list-wrapper svg {
  cursor: pointer
}

.filters-page-multiple-active-input-list-wrapper ul li:last-child p {
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
}

.filters-page-multiple-active-input-list-wrapper ul > li > p {
  background-color: var(--white);
  padding: 12px; /* Add some padding */
  text-decoration: none; /* Remove default text underline */
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  font-family: inter;
  color: var(--main-bg);
  margin-bottom: 0;
}

.filters-page-multiple-active-input-list-wrapper ul li p:hover {
  background-color: var(--blue);
  color: var(--body-text);
  cursor: pointer;
}

.filters-page-multiple-active-input-list-wrapper ul .loader {
  height: 30px;
  width: 30px;
}