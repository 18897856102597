/* @media screen and (min-width: 2241px) and (max-width: 3041px) { */
@media screen and (max-width: 429px) {
  /* .filters-page-house-card-heard-list-wrapper-active, */
  .filters-page-house-card-heard-list-wrapper-active-list {
    flex-direction: row;
  }
}

@media screen and (min-width: 1440px) and (max-width: 1599px) {
  .filters-page-house-card-heard-list-wrapper-active {
    flex-direction: column;
  }
}

@media screen and (min-width: 2080px) and (max-width: 2399px) {
  .filters-page-house-card-heard-list-wrapper-active {
    flex-direction: column;
  }
}

@media screen and (min-width: 2780px) and (max-width: 3175px) {
  .filters-page-house-card-heard-list-wrapper-active {
    flex-direction: column;
  }
}

@media screen and (min-width: 3480px) and (max-width: 3969px) {
  .filters-page-house-card-heard-list-wrapper-active {
    flex-direction: column;
  }
}

@media screen and (min-width: 4180px) and (max-width: 4759px) {
  .filters-page-house-card-heard-list-wrapper-active {
    flex-direction: column;
  }
}

@media screen and (min-width: 4900px) {
  .filters-page-house-card-heard-list-wrapper-active {
    flex-direction: column;
  }
}

@media screen and (min-width: 769px) and (max-width: 836px) {
  .filters-page-house-card-heard-list-wrapper-active-list {
    flex-direction: row;
  }
}

@media screen and (min-width: 1440px) and (max-width: 1629px) {
  .filters-page-house-card-heard-list-wrapper-active-list {
    flex-direction: row;
  }
}
