/* @media screen and (min-width: 1920px) {
    #filters-page-block-content {
        justify-content: center;
        padding: 0 0 16px 0 !important;
        display: flex;
    }

    .sorting-block-header-wrapper-sticky {
        padding: 16px 16px !important;
    }
} */

/* @media screen and (min-width: 1920px) and (max-width: 2061px) {
    #filters-page-block-content-right {
        width: calc(100% - 48px - 370px);
    }

    .filters-page-house-card-img-container {
        height: calc(1.1*398px);
    }

    .filters-page-house-card-wrapper {
        width: calc(100%/3 - 11px);
        height: calc(1.1*398px);
    }

    #filters-page-block-content-left {
        max-width: 370px;
    }
} */
@media screen and (min-width: 1920px) and (max-width: 2061px) {
  /* list view */
  .filters-page-house-card-wrapper-list {
    width: calc(100% / 4 - 12px);
    /* height: calc(1.1*398px); */
    height: calc((100vw - 96px) / 4 * 0.666666);
  }

  .filters-page-house-card-wrapper-list .filters-page-house-card-img-container {
    /* height: calc(1.1*398px); */
    height: calc((100vw - 96px) / 4 * 0.666666);
  }

  .filters-page-house-card-note-content-popup-list {
    height: calc(16.66665vw - 110.33322px) !important;
  }

  /* both view */
  /* .filters-page-house-card-wrapper {
    width: calc(100% / 3 - 11px);
    height: calc((100vw - 800px - 64px) / 3 * 0.666666);
  }

  .filters-page-house-card-note-content-popup {
    height: calc((100vw - 1200px - 208px) / 3 * 0.666666);
  }

  .filters-page-house-card-wrapper .filters-page-house-card-img-container {
    height: calc((100vw - 800px - 64px) / 3 * 0.666666);
  } */

  /* both view top*/

  /* .sorting-block-header-wrapper-block-1 {
    width: calc(100% * 2 / 3 - 6px) !important;
  }

  .new-bottomHeaderPaginationComponent-wrapper-800
    .new-sorting-block-btn-wrapper-both {
    position: absolute;
    top: 16px;
    width: calc(100% * 2 / 3 - 28px);
    right: 16px;
    margin-top: 0;
    height: 55px;
    align-items: center;
    padding: 0 24px;
    border-radius: 10px;
    border: 1px solid var(--gray);
    gap: 12px;
  } */
}

/* @media screen and (min-width: 2062px) and (max-width: 3029px) {
    #filters-page-block-content-right {
        width: calc(100% - 48px - 370px);
    }

    .filters-page-house-card-img-container {
        height: calc(1.05*398px);
    }

    .filters-page-house-card-wrapper {
        width: calc(100%/4 - 12px);
        height: calc(1.05*398px);
    }

    #filters-page-block-content-left {
        max-width: 370px;
    }
} */

@media screen and (min-width: 2062px) and (max-width: 3029px) {
  .filters-page-house-card-wrapper-list {
    width: calc(100% / 5 - 14px);
    height: calc((100vw - 112px) / 5 * 0.666666);
  }

  .filters-page-house-card-wrapper-list .filters-page-house-card-img-container {
    height: calc((100vw - 112px) / 5 * 0.666666);
  }

  .filters-page-house-card-note-content-popup-list {
    height: calc((100vw - 112px) / 5 * 0.666666 - 96px) !important;
  }

  /* both view */
  /* .filters-page-house-card-wrapper {
    width: calc(100% / 4 - 12px);
    height: calc((100vw - 800px - 80px) / 4 * 0.666666);
  }

  .filters-page-house-card-note-content-popup {
    height: calc((100vw - 1300px - 208px) / 4 * 0.666666);
  }

   */

  /* .filters-page-house-card-wrapper .filters-page-house-card-img-container {
    height: calc((100vw - 800px - 80px) / 4 * 0.666666);
  } */

  /* both view top*/

  /* .sorting-block-header-wrapper-block-1 {
    width: calc(100% / 2 - 6px) !important;
  }

  .new-bottomHeaderPaginationComponent-wrapper-800
    .new-sorting-block-btn-wrapper-both {
    position: absolute;
    top: 16px;
    width: calc(100% * 3 / 4 - 28px);
    right: 16px;
    margin-top: 0;
    height: 55px;
    align-items: center;
    padding: 0 24px;
    border-radius: 10px;
    border: 1px solid var(--gray);
    gap: 12px;
  } */
}

/* @media screen and (min-width: 3030px) and (max-width: 3790px) {
    #filters-page-block-content-right {
        width: calc(100% - 48px - 370px);
    }

    .filters-page-house-card-img-container {
        height: calc(1.1*398px);
    }

    .filters-page-house-card-wrapper {
        width: calc(100%/5 - 13px);
        height: calc(1.1*398px);
    }

    #filters-page-block-content-left {
        max-width: 370px;
    }
} */

@media screen and (min-width: 3030px) and (max-width: 3790px) {
  .filters-page-house-card-wrapper-list {
    width: calc(100% / 6 - 14px);
    height: calc((100vw - 128px) / 6 * 0.666666);
  }

  .filters-page-house-card-wrapper-list .filters-page-house-card-img-container {
    height: calc((100vw - 128px) / 6 * 0.666666);
  }

  .filters-page-house-card-note-content-popup-list {
    height: calc((100vw - 128px) / 6 * 0.666666 - 96px) !important;
  }
  /* both view */
  /* .filters-page-house-card-wrapper {
    width: calc(100% / 5 - 13px);
    height: calc((100vw - 800px - 96px) / 5 * 0.666666);
  }

  .filters-page-house-card-note-content-popup {
    height: calc((100vw - 1500px - 208px) / 5 * 0.666666);
  }

  .filters-page-house-card-wrapper .filters-page-house-card-img-container {
    height: calc((100vw - 800px - 96px) / 5 * 0.666666);
  } */

  /* both view top*/

  /* .sorting-block-header-wrapper-block-1 {
    width: calc(100% * 2 / 5 - 6px) !important;
  }

  .new-bottomHeaderPaginationComponent-wrapper-800
    .new-sorting-block-btn-wrapper-both {
    position: absolute;
    top: 16px;
    width: calc(100% * 4 / 5 - 32px);
    right: 16px;
    margin-top: 0;
    height: 55px;
    align-items: center;
    padding: 0 24px;
    border-radius: 10px;
    border: 1px solid var(--gray);
    gap: 12px;
  }*/
}

/* @media screen and (min-width: 3791px) and (max-width: 4450px) {
    #filters-page-block-content-right {
        width: calc(100% - 48px - 370px);
    }

    .filters-page-house-card-img-container {
        height: calc(1.15*398px);
    }

    .filters-page-house-card-wrapper {
        width: calc(100%/6 - 14px);
        height: calc(1.15*398px);
    }

    #filters-page-block-content-left {
        max-width: 370px;
    }
} */

@media screen and (min-width: 3791px) and (max-width: 4450px) {
  .filters-page-house-card-wrapper-list {
    width: calc(100% / 7 - 15px);
    height: calc((100vw - 144px) / 7 * 0.666666);
  }

  .filters-page-house-card-wrapper-list .filters-page-house-card-img-container {
    height: calc((100vw - 144px) / 7 * 0.666666);
  }

  .filters-page-house-card-note-content-popup-list {
    height: calc((100vw - 144px) / 7 * 0.666666 - 96px) !important;
  }

  /* both view */
  /* .filters-page-house-card-wrapper {
    width: calc(100% / 6 - 14px);
    height: calc((100vw - 800px - 112px) / 6 * 0.666666);
  }

  .filters-page-house-card-note-content-popup {
    height: calc((100vw - 1650px - 208px) / 6 * 0.666666);
  }



  .filters-page-house-card-wrapper .filters-page-house-card-img-container {
    height: calc((100vw - 800px - 112px) / 6 * 0.666666);
  } */

  /* both view top*/

  /* .sorting-block-header-wrapper-block-1 {
    width: calc(100% * 2 / 6 - 12px) !important;
  }

  .new-bottomHeaderPaginationComponent-wrapper-800
    .new-sorting-block-btn-wrapper-both {
    position: absolute;
    top: 16px;
    width: calc(100% * 5 / 6 - 30px);
    right: 16px;
    margin-top: 0;
    height: 55px;
    align-items: center;
    padding: 0 24px;
    border-radius: 10px;
    border: 1px solid var(--gray);
    gap: 12px;
  }*/
}

/* @media screen and (min-width: 4451px) and (max-width: 5305px) {
    #filters-page-block-content-right {
        width: calc(100% - 48px - 370px);
    }

    .filters-page-house-card-img-container {
        height: calc(1.2*398px);
    }

    .filters-page-house-card-wrapper {
        width: calc(100%/7 - 14px);
        height: calc(1.2*398px);
    }

    #filters-page-block-content-left {
        max-width: 370px;
    }
} */

@media screen and (min-width: 4451px) and (max-width: 5305px) {
  .filters-page-house-card-wrapper-list {
    width: calc(100% / 8 - 15px);
    height: calc((100vw - 160px) / 8 * 0.666666);
  }

  .filters-page-house-card-wrapper-list .filters-page-house-card-img-container {
    height: calc((100vw - 160px) / 8 * 0.666666);
  }

  .filters-page-house-card-note-content-popup-list {
    height: calc((100vw - 160px) / 8 * 0.666666 - 96px) !important;
  }

  /* both view */
  /* .filters-page-house-card-wrapper {
    width: calc(100% / 7 - 14px);
    height: calc((100vw - 800px - 128px) / 7 * 0.666666);
  }

  .filters-page-house-card-note-content-popup {
    height: calc((100vw - 1800px - 208px) / 7 * 0.666666);
  }

  .filters-page-house-card-wrapper .filters-page-house-card-img-container {
    height: calc((100vw - 800px - 128px) / 7 * 0.666666);
  } */

  /* both view top*/

  /* .sorting-block-header-wrapper-block-1 {
    width: calc(100% * 2 / 7 - 12px) !important;
  }

  .new-bottomHeaderPaginationComponent-wrapper-800
    .new-sorting-block-btn-wrapper-both {
    position: absolute;
    top: 16px;
    width: calc(100% * 6 / 7 - 30px);
    right: 16px;
    margin-top: 0;
    height: 55px;
    align-items: center;
    padding: 0 24px;
    border-radius: 10px;
    border: 1px solid var(--gray);
    gap: 12px;
  }*/
}

/* @media screen and (min-width: 5306px) and (max-width: 6066px) {
    #filters-page-block-content-right {
        width: calc(100% - 48px - 370px);
    }

    .filters-page-house-card-img-container {
        height: calc(1.25*398px);
    }

    .filters-page-house-card-wrapper {
        width: calc(100%/8 - 14px);
        height: calc(1.25*398px);
    }

    #filters-page-block-content-left {
        max-width: 370px;
    }
} */

@media screen and (min-width: 5306px) and (max-width: 6066px) {
  .filters-page-house-card-wrapper-list {
    width: calc(100% / 9 - 15px);
    height: calc((100vw - 176px) / 9 * 0.666666);
  }

  .filters-page-house-card-wrapper-list .filters-page-house-card-img-container {
    height: calc((100vw - 176px) / 9 * 0.666666);
  }

  .filters-page-house-card-note-content-popup-list {
    height: calc((100vw - 176px) / 9 * 0.666666 - 96px) !important;
  }

  /* both view */
  /* .filters-page-house-card-wrapper {
    width: calc(100% / 8 - 14px);
    height: calc((100vw - 800px - 144px) / 8 * 0.666666);
  }

  .filters-page-house-card-note-content-popup {
    height: calc((100vw - 2000px - 208px) / 8 * 0.666666);
  }

  .filters-page-house-card-wrapper .filters-page-house-card-img-container {
    height: calc((100vw - 800px - 144px) / 8 * 0.666666);
  } */

  /* both view top*/

  /* .sorting-block-header-wrapper-block-1 {
    width: calc(100% * 2 / 8 - 14px) !important;
  }

  .new-bottomHeaderPaginationComponent-wrapper-800
    .new-sorting-block-btn-wrapper-both {
    position: absolute;
    top: 16px;
    width: calc(100% * 7 / 8 - 32px);
    right: 16px;
    margin-top: 0;
    height: 55px;
    align-items: center;
    padding: 0 24px;
    border-radius: 10px;
    border: 1px solid var(--gray);
    gap: 12px;
  }*/
}

/* @media screen and (min-width: 6067px) {
    #filters-page-block-content-right {
        width: calc(100% - 48px - 370px);
    }

    .filters-page-house-card-img-container {
        height: calc(1.3*398px);
    }

    .filters-page-house-card-wrapper {
        width: calc(100%/9 - 15px);
        height: calc(1.3*398px);
    }

    #filters-page-block-content-left {
        max-width: 370px;
    }
} */

@media screen and (min-width: 6067px) {
  .filters-page-house-card-wrapper-list {
    width: calc(100% / 10 - 16px);
    height: calc((100vw - 192px) / 10 * 0.666666);
  }

  .filters-page-house-card-wrapper-list .filters-page-house-card-img-container {
    height: calc((100vw - 192px) / 10 * 0.666666);
  }

  .filters-page-house-card-note-content-popup-list {
    height: calc((100vw - 192px) / 10 * 0.666666 - 96px) !important;
  }

  /* both view */
  /* .filters-page-house-card-wrapper {
    width: calc(100% / 9 - 15px);
    height: calc((100vw - 800px - 160px) / 9 * 0.666666);
  }

  .filters-page-house-card-note-content-popup {
    height: calc((100vw - 2200px - 208px) / 9 * 0.666666);
  }

  .filters-page-house-card-wrapper .filters-page-house-card-img-container {
    height: calc((100vw - 800px - 160px) / 9 * 0.666666);
  } */

  /* both view top*/

  /* .sorting-block-header-wrapper-block-1 {
    width: calc(100% * 2 / 9 - 14px) !important;
  }

  .new-bottomHeaderPaginationComponent-wrapper-800
    .new-sorting-block-btn-wrapper-both {
    position: absolute;
    top: 16px;
    width: calc(100% * 8 / 9 - 32px);
    right: 16px;
    margin-top: 0;
    height: 55px;
    align-items: center;
    padding: 0 24px;
    border-radius: 10px;
    border: 1px solid var(--gray);
    gap: 12px;
  }*/
}

@media screen and (min-height: 768px) {
  .filters-page-block-content-right-scroll {
    width: calc(100% - 370px - 16px);
    height: 50svh;
    overflow-y: scroll;
  }

  .filters-page-block-content-right-scroll {
    overflow-y: scroll;
    scrollbar-width: none;
    -ms-overflow-style: none;
  }

  .filters-page-block-content-right-scroll::-webkit-scrollbar {
    display: none;
  }
}

@media screen and (min-width: 1440px) and (max-width: 1919px) {
  .filters-page-house-card-wrapper-list {
    width: calc(100% / 4 - 12px);
    height: calc((100vw - 96px) / 4 * 0.666666);
  }

  .filters-page-house-card-wrapper-list .filters-page-house-card-img-container {
    height: calc((100vw - 96px) / 4 * 0.666666);
  }

  /*  #filters-page-block-content-left {
        max-width: 370px;
    }

    #filters-page-block-content-right {
        width: calc(100% - 370px - 16px);
    }

    .filters-page-house-card-wrapper {
        width: calc(100%/3 - 11px);
        height: calc(0.7*398px);
    } */

  /*.filters-page-house-card-wrapper .filters-page-house-card-img-container {
        height: calc(0.7*398px);
    } */

  /*
    #sorting-block-input-wrapper {
        max-width: 370px;
        margin-right: 16px;
        height: 55px;
    }

    .sorting-block-header-wrapper-sticky,
    .sorting-block-header-wrapper {
        gap: 0;

    }

    .sorting-block-header-wrapper>div {
        width: calc((100% - 370px)/2)
    }

    .sorting-block-header-wrapper-block-displaying-listings {
        border-top-left-radius: 0;
        border-bottom-left-radius: 0;
        border-left: none;
        width: calc((100% - 370px)/2)
    }

    #sorting-block-icons-wrapper {
        border-top-right-radius: 0;
        border-bottom-right-radius: 0;
        border-right: none;
    }*/
}

@media screen and (min-width: 1200px) and (max-width: 1439px) {
  .filters-page-house-card-wrapper-list {
    width: calc(100% / 3 - 12px);
    height: calc((100vw - 64px) / 3 * 0.666666);
  }

  .filters-page-house-card-wrapper-list .filters-page-house-card-img-container {
    height: calc((100vw - 64px) / 3 * 0.666666);
  }

  /* .new-filters-page-block-content-left{
        display: none !important;
    }

    .new-SortingBlock-wrapper {
        width: 100% !important;
    } */
  /* #filters-page-map-wrapper {
        height: 40vh;
    }

    #filters-page-block-content-left {
        max-width: 370px;
    }

    #sorting-block-input-wrapper {
        max-width: 370px;
        margin-right: 16px;
    }

    #filters-page-block-content-right {
        width: calc(100% - 370px - 16px);
    }

    .filters-page-house-card-wrapper {
        width: calc(100%/2 - 11px);
    }

    #sorting-block-icons-wrapper {
        height: 55px;
    }

    .sorting-block-header-wrapper-sticky,
    .sorting-block-header-wrapper {
        gap: 0
    }

    .sorting-block-header-wrapper>div {
        width: calc((100% - 370px)/2)
    }

    .sorting-block-header-wrapper-block-displaying-listings {
        border-top-left-radius: 0;
        border-bottom-left-radius: 0;
        border-left: none;
    }

    #sorting-block-icons-wrapper {
        border-top-right-radius: 0;
        border-bottom-right-radius: 0;
        border-right: none;
    }

    #sorting-block-icon-1,
    #sorting-block-icon-2,
    #sorting-block-icon-1_5 {
        display: flex;
    }

    #sorting-block-icons-wrapper {
        position: relative;
    }

    .sorting-block-icon-map-list {
        margin-left: 0 !important;
        position: absolute;
        right: -75px
    }

    #filters-page-house-cards-block-wrapper-displaying-listings {
        justify-content: end;
    }*/
}

/* @media screen and (min-width: 993px) {

    #sorting-block-icon-1,
    #sorting-block-icon-2,
    #sorting-block-icon-1_5 {
        display: flex !important;
    }
}

@media screen and (min-width: 769) and (max-width: 993px) {
    #sorting-block-icons-wrapper {
        position: relative;
    }

    .sorting-block-icon-map-list {
        margin-left: 0 !important;
        position: absolute;
        left: 0
    }
} */

/* @media screen and (min-width: 993px) and (max-width: 1199px) {
    #sorting-block-icons-wrapper {
        position: relative;
    }

    .sorting-block-icon-map-list {
        margin-left: 0 !important;
        position: absolute;
        bottom: -34px;
        right: 0px;
    }
} */

/* @media screen and (min-width: 769px) and (max-width: 1199px) {
    #filters-block-wrapper-form {
        display: none !important;
    }

    #filters-page-block-content-left {
        width: 100% !important;
    }

    #filters-block-filters-top-buttons-block-wrapper {
        width: 100% !important;
    }

    #filters-page-block-content-right {
        width: 100% !important;
    }

    #sorting-block-icon-1_5,
    #sorting-block-icon-1,
    #sorting-block-icon-2 {
        display: none
    }

    #sorting-block-icon-3 {
        margin-left: auto;
    }

    #filters-page-block-content {
        padding: 0 16px 16px 16px;
        display: flex;
        flex-direction: column !important;
        gap: 0 !important
    }

    .sorting-block-header-wrapper {
        position: fixed !important;
        gap: 16px;
        padding: 16px 16px 48px !important;
        height: 104px;
    }

    .sorting-block-header-wrapper-sticky {
        position: fixed;
        gap: 16px;
        padding: 16px 16px 36px !important;
    }

    #filters-page-block-filter-block-narrow #filters-block-filters-buttons-block-wrapper {
        position: sticky !important;
    }
} */

@media screen and (max-width: 768px) {
  /* .filters-page-house-card-wrapper-list {
        width: 100% !important;
    } */
  /* #filters-page-map-wrapper .gmnoprint div {
    display: none !important;
  } */

  #filters-page-map-wrapper:first-child
    .gmnoprint:first-child
    div:first-child
    div {
    background: red !important;
    display: none !important;
  }

  .filters-page-house-card-wrapper-list {
    width: 100% !important;
    height: calc((100vw - 32px) * 0.666666) !important;
  }

  .filters-page-house-card-wrapper-list .filters-page-house-card-img-container {
    height: calc((100vw - 32px) * 0.666666) !important;
  }

  #map-overlays-btn-form,
  #map-drawOnMap-wrapper,
  .sorting-block-icon-map-list-map-view-filters,
  .sorting-block-icon-map-list-map-view .main-container label,
  .sorting-block-icon-map-list-map-view .main-container {
    height: var(--mobile-main-btn-height) !important;
  }

  .sorting-block-icon-map-list-map-view .main-container .switch {
    height: calc(var(--mobile-main-btn-height) - 6px) !important;
  }

  .map-drawOnMap-wrapper-title-draw,
  .map-overlays-btn-title,
  .sorting-block-icon-map-list-map-view-filters,
  .sorting-block-icon-map-list-map-view .main-container p {
    font-size: 16px !important;
  }

  .map-overlays-btn-form-list-end-icon {
    top: 14px !important;
  }

  #map-overlays-btn-form ul {
    width: 183px !important;
    top: 48px !important;
  }

  .map-drawOnMap-wrapper-list {
    top: 48px !important;
  }

  .map-overlays-btn-list p,
  .map-drawOnMap-wrapper-list-li p {
    line-height: 32px !important;
  }

  #map-overlays-btn-form {
    width: 110px !important;
  }

  #map-drawOnMap-wrapper {
    width: 88px !important;
    left: 130px;
    top: 78px !important;
  }

  .map-overlays-btn-list-input-wrapper input {
    height: 40px !important;
  }

  .map-overlays-btn-form-both ul {
    min-height: 185px !important;
    max-height: 185px !important;
  }

  .map-overlays-btn-form-map ul {
    min-height: 308px !important;
    max-height: 400px !important;
  }

  .filters-page-map-overlays-wrapper {
    left: 16px;
    top: 78px;
  }
}

@media screen and (max-width: 18399px) {
  #filters-page-block-content {
    padding: 0 16px 16px 16px;
    display: flex;
  }

  .sorting-block-header-wrapper {
    position: fixed !important;
    gap: 16px;
    height: 118px;
    width: 100%;
  }

  #filters-block-filters-top-buttons-block-wrapper {
    margin-bottom: 16px !important;
  }

  #sorting-block-box {
    padding-top: 16px !important;
  }

  #filters-page-house-cards-block {
    gap: 16px 16px !important;
  }

  #filters-page-house-cards-block-wrapper {
    gap: 16px !important;
  }

  #filters-page-block-filter-block-narrow
    #filters-block-filters-buttons-block-wrapper {
    position: sticky !important;
  }

  /* .filters-page-map-overlays-wrapper {
        left: 16px;
        top: 78px;
    } */

  /* .map-overlays-btn-list-input-wrapper input {
        height: 40px !important
    }

    .map-overlays-btn-form-both ul {
        min-height: 185px !important;
        max-height: 185px !important;
    }

    .map-overlays-btn-form-map ul {
        min-height: 308px !important;
        max-height: 400px !important;
    } */

  .filters-page-base-input-map-list-wrapper ul {
    max-height: 308px !important;
    top: 33px !important;
    min-width: 92px;
  }

  .filters-page-base-input-map-list-wrapper ul > li > p {
    height: 40px;
    line-height: 32px;
  }

  /* .map-drawOnMap-wrapper-list {
        top: 48px !important;
    }

    .map-overlays-btn-list p,
    .map-drawOnMap-wrapper-list-li p {
        line-height: 32px !important;
    } */

  /* #map-overlays-btn-form ul {
        width: 183px !important;
        top: 48px !important;
    } */

  /* #map-overlays-btn-form,
    #map-drawOnMap-wrapper,
    .sorting-block-icon-map-list-map-view-filters,
    .sorting-block-icon-map-list-map-view .main-container label,
    .sorting-block-icon-map-list-map-view .main-container {
        height: var(--mobile-main-btn-height) !important
    } */

  /* .sorting-block-icon-map-list-map-view .main-container .switch {
        height: calc(var(--mobile-main-btn-height) - 6px) !important;
    } */

  /* #map-overlays-btn-form,
    #map-drawOnMap-wrapper {
        height: var(--mobile-main-btn-height) !important
    }

    .map-overlays-btn-form-list-end-icon {
        top: 14px !important;
    } */

  /* .map-drawOnMap-wrapper-title-draw,
    .map-overlays-btn-title,
    .sorting-block-icon-map-list-map-view-filters,
    .sorting-block-icon-map-list-map-view .main-container p {
        font-size: 16px !important;
    } */

  /* #map-overlays-btn-form {
        width: 110px !important;
    }

    #map-drawOnMap-wrapper {
        width: 88px !important;
        left: 130px;
        top: 78px !important;
    } */

  /* .sorting-block-icon-map-list-map-view .main-container {
        width: 158px !important
    } */

  /* .sorting-block-icon-map-list-map-view .main-container .right-label {
        padding-left: 0px;
    }

    .sorting-block-icon-map-list-map-view .main-container .center-label {
        padding-left: 8px;
    } */
}

@media screen and (min-width: 441px) and (max-width: 768px) {
  .sorting-block-header-wrapper-sticky,
  .sorting-block-header-wrapper {
    padding: 16px 16px 48px !important;
  }
}

@media screen and (max-width: 768px) {
  .sorting-block-icon-2_5-our-listings_black {
    height: 45px;
    width: 122px;
  }
  .sorting-block-icon-2_5-our-listings-content_black {
    line-height: 45px;
    font-size: 16px;
  }

  .sorting-block-icon-2_5-our-listings-realtytexas-icon_black {
    height: 28px;
    width: 28px;
  }
}

@media screen and (max-width: 419px) {
  .sorting-block-icon-2_5-our-listings_black {
    height: 45px;
    width: 45px;
    padding: 0;
  }
  .sorting-block-icon-2_5-our-listings-content_black {
    display: none;
  }
}
