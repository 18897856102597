.sorting-header-pagination-wrapper {
    display: flex;
    align-items: center;
}

.sorting-header-pagination-wrapper .sorting-header-pagination-arrow {
    height: 22px
}

.sorting-header-pagination-wrapper .sorting-header-pagination-arrow-hover {
    cursor: pointer;
}

.sorting-header-pagination-wrapper .sorting-header-pagination-arrow-grey {
    cursor: initial;
    height: 22px
}

.sorting-header-pagination-wrapper .sorting-header-pagination-arrow-grey path {
    fill: var(--gray) !important
}

.sorting-header-pagination-wrapper .sorting-header-pagination-arrow-hover:hover path {
    fill: var(--gray) !important
}

.filters-block-filter-details-input-list-block-input-blue-color {
    color: var(--blue) !important
}

.sorting-header-pagination-wrapper-input-list-block-input-gray-color {
    color: #141313b3;
}

.sorting-header-pagination-wrapper-input-list-block-input-gray-color p {
    opacity: 0.7;
}

.sorting-header-pagination-wrapper input {
    color: var(--main-bg) !important;
    font-size: 14px;
    font-family: Inter;
    height: 20px
}

/* .sorting-header-pagination-wrapper .filters-page-base-input-map-list-wrapper {
  width: 80px
} */

/* .sorting-header-pagination-list-length p {
  font-size: 14px;
  font-family: Inter;
  font-weight: 400;
} */

.sorting-header-pagination-wrapper ul {
    min-width: 80px;
}
