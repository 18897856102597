#filters-block-filter-PropertyType {
    border: 1px solid var(--gray);
    background-color: var(--third-bg);
    padding: 24px;
    display: flex;
    flex-direction: column;
    border-bottom: none;
    gap: 24px;
    /* border-top-left-radius: 10px;
  border-top-right-radius: 10px; */
}

.filters-block-filter-PropertyType-content-wrapper {
    display: flex;
    flex-direction: column;
    gap: 24px;
    padding-top: 2px;
    -moz-transition: opacity 0.8s ease;
    -webkit-transition: opacity 0.8s ease;
    -o-transition: opacity 0.8s ease;
    transition: opacity 0.8s ease;
    opacity: 1;
}

.collapsed-PropertyType-content {
    overflow: hidden !important; 
    opacity:0 !important;
}

#filters-block-filter-PropertyType-title {
    display: flex;
    justify-content: space-between;
    cursor: pointer;
}

.filters-block-filter-PropertyType-title-icon {
    display: flex;
    align-items: center;
}

#filters-block-filter-PropertyType-title h4 {
    color: var(--main-bg);
    font-size: 26px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
}

.filters-block-filter-PropertyType-content {
    display: flex;
    flex-direction: column;
    gap: 12px;
}

.filters-block-filter-PropertyType-content-checkbox {
    display: flex;
    gap: 24px;
    height: 24px;
    align-items: center;
}

.filters-block-filter-PropertyType-content-checkbox p {
    color: var(--second-bg);
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: 0.32px;
}

#filters-block-filter-PropertyType-content-checkbox-1,
#filters-block-filter-PropertyType-content-checkbox-2 {
    height: 28px;
    cursor: pointer;
}