#filters-page-house-cards-block-wrapper {
    display: flex;
    flex-direction: column;
    gap: 16px;
    position: relative;
    /* margin-top: 24px; */
}

#filters-page-house-cards-block {
    display: flex;
    flex-wrap: wrap;
    gap: 16px 16px;
    /* width: 466px; */
    width: 100%;
    margin-left: auto;
    margin-right: 16px;
    margin-top: 4px;
}

.houseCardRectSpinerWrapper {
    display: flex;
    width: 100%;
    justify-content: center;
    align-items: center;
    height: 100px;
    z-index: 107;
    position: fixed;
    -webkit-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    top: calc(50% + 77px);
    left: 50%;
}
