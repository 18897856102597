#filters-block-filters-top-buttons-block-wrapper {
  display: flex;
  gap: 8px;
  margin-bottom: 24px;
}

.filters-block-filters-top-buttons-block {
  border-radius: 10px;
  color: var(--body-text);
  background-color: var(--main-bg);
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: 0.32px;
  height: 55px;
  width: 50%;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
}

.filters-block-filters-top-buttons-block-active {
  border-radius: 10px;
  color: var(--body-text);
  background-color: var(--blue);
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: 0.32px;
  height: 55px;
  width: 50%;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
}