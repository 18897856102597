.HeaderLayerPrice-wrapper {
  /* width: 200px; */
  height: 30px;
  /* position: relative; */
  background-color: var(--third-bg);
  border-radius: 8px;
  /* cursor: pointer; */
}

.HeaderLayerPrice-wrapper-content:hover {
  opacity: 0.7;
}

.HeaderLayerPrice-wrapper-content {
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 12px;
  /* padding: 0 4px; */
  gap: 4px;
  position: relative;
  cursor: pointer;
}

.HeaderLayerPrice-wrapper-content-box {
  position: absolute;
  top: -8px;
  right: -10px;
  font-size: 13px;
  color: var(--white);
  background-color: var(--blue);
  border-radius: 50%;
  border: 1px solid var(--main-bg);
  width: 24px;
  height: 24px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.HeaderLayerPrice-wrapper-content-box-none {
  color: var(--gray);
}

.PricePopup-wrapper {
  /* position: absolute; */
  position: fixed;
  top: 77px;
  left: 16px;
  /* min-height: 300px; */
  /* width: 320px; */
  border: 1px solid var(--gray);
  background-color: var(--third-bg);
  padding: 24px;
  /* border-radius: 10px; */
  z-index: 107;
}

.PricePopup-wrapper-box {
  position: relative;
  width: 100%;
  height: 100%;
  /* min-height: 420px; */
  display: flex;
  flex-direction: column;
  gap: 24px;
}

.PricePopup-wrapper-header-content-block::-webkit-scrollbar {
  display: none; /* Safari and Chrome */
}

.PricePopup-wrapper-header-content-block {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  gap: 24px;
  /*  */
  overflow-y: auto;
  /* max-height: calc(100svh - 178px); */
  /* max-height: 400px; */
  z-index: 107;
  -ms-overflow-style: none; /* Internet Explorer 10+ */
  scrollbar-width: none; /* Firefox */
  /* max-height: calc(100svh - 310px); */
  max-height: calc(100svh - 240px);
  /* min-height: 286px; */
}

.PricePopup-wrapper-header-wrapper {
  color: var(--second-bg);
  font-size: 18px;
}

.PricePopup-wrapper-header-close {
  position: absolute;
  top: -4px;
  right: -4px;
  cursor: pointer;
}

.PricePopup-wrapper-header-close:hover {
  opacity: 0.7;
}

/* .PriceReset-wrapper,
  .PriceApply-wrapper {
    height: 100%;
    width: 100%;
  } */

.PricePopup-wrapper-header-btns-block {
  height: 43px;
  width: 100%;
  display: flex;
  justify-content: space-between;
  gap: 8px;
}

.PriceReset-apply-wrapper,
.PriceApply-apply-wrapper {
  height: 100%;
  width: 100%;
  padding: 8px 16px;
  color: var(--white);
  font-size: 14px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 4px;
}

.PriceApply-apply-wrapper {
  background-color: var(--blue);
}

.PriceReset-apply-wrapper {
  background-color: var(--main-bg);
}

.PriceReset-wrapper,
.PriceApply-wrapper {
  cursor: pointer;
  width: 50%;
}

.PriceReset-wrapper:hover,
.PriceApply-wrapper:hover {
  opacity: 0.7;
}

.HeaderLayerPrice-wrapper-content-title {
  font-size: 14px;
}

.PricePopup-wrapper-header-content-block
  .filters-page-base-input-list-wrapper
  ul {
  background-color: var(--white) !important;
}

.HeaderLayerPrice-wrapper-content-title-icon {
  margin-top: 4px;
  display: none;
  justify-content: center;
  align-items: center;
}
