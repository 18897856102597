.click-for-premium-access-wrapper{
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    -webkit-transform: translate(-50%, -50%) !important;
    border-radius: 10px;
    z-index: 3;
    background-color: var(--blue);
    padding: 10px 20px;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    display: block;
    font-size: 14px;
    z-index: 2147483647;
    text-align: center;
}
