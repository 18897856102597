/* @media screen and (min-width: 1920px) {
    #filters-page-block-content-left {
        width: 489px !important;
    }

    .filters-page-house-card-wrapper {
        width: 491px
    }

    #filters-block-filters-top-buttons-block-wrapper {
        width: 100% !important;
    }
} */


/* TODO HARDCODED HIDE FILTERS BLOCK */
/* @media screen and (min-width: 1440px) and (max-width: 1919px) {
    #filters-block-filters-top-buttons-block-wrapper {
        width: 100% !important;
    }

} */

/* @media screen and (min-width: 1200px) and (max-width: 1439px) {
    #filters-page-block-content {
        display: flex;
    }

    #filters-page-house-cards-block {
        gap: 16px 16px !important;
    }

    .filters-page-house-card-wrapper {
        width: calc(50% - 8px) !important;
    }

    #filters-block-filters-top-buttons-block-wrapper {
        width: 100% !important;
    }

    #filters-page-house-cards-block-wrapper-displaying-listings {
        font-size: 14px;
    }
} */

@media screen and (max-width: 1199px) {
    .new-filters-page-block-content-left{
        display: none !important;
    }

    .new-SortingBlock-wrapper {
        width: 100% !important;
    }
}

@media screen and (min-width: 769px) and (max-width: 1199px) {
    #filters-page-house-cards-block-wrapper {
        gap: 36px;
    }

    #filters-page-house-cards-block {
        gap: 16px 16px;
    }

    #sorting-block-icon-3 {
        display: block;
    }

    /* .filters-page-house-card-wrapper {
        width: calc(50% - 8px);
    } */

    /* .filters-page-house-card-wrapper-list {
        width: calc(50% - 8px);
    } */

    .filters-page-house-card-wrapper-list {
        width: calc(100%/2 - 8px);
        height: calc((100vw - 48px + 8px)/2*0.66);
    }

    .filters-page-house-card-wrapper-list .filters-page-house-card-img-container {
        height: calc((100vw - 48px + 8px)/2*0.66);
    }

    /* #filters-page-house-cards-block-wrapper-displaying-listings {
        color: #2B2B2B;
        font-family: Inter;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
    } */
}

@media screen and (max-width: 18399px) {
    #filters-page-block-content {
        flex-direction: column !important;
        gap: 0 !important
    }

    #filters-page-block-content-left form {
        display: none !important;
    }

    #filters-page-block-content-left {
        width: 100% !important;
    }

    #filters-block-filters-top-buttons-block-wrapper {
        width: 100% !important;
    }

    #filters-page-block-content-right {
        width: 100% !important;
    }

    #sorting-block-icon-1,
    #sorting-block-icon-1_5,
    #sorting-block-icon-2 {
        display: none
    }

    #sorting-block-icon-3 {
        display: flex;
    }

    /* #sorting-block-input-wrapper {
        width: 285px !important;
    } */

    #filters-page-house-cards-block-wrapper-displaying-listings {
        color: #2B2B2B;
        font-family: Inter;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
    }
}