#filters-block-filter-show-only {
    border: 1px solid var(--gray);
    background-color: var(--third-bg);
    padding: 24px;
    display: flex;
    flex-direction: column;
    border-top: none;
    border-bottom: none;
    gap: 24px;
    -moz-transition: height 0.4s ease;
    -webkit-transition: height 0.4s ease;
    -o-transition: height 0.4s ease;
    transition: height 0.4s ease;
    height: 163px;
}

#filters-block-filter-show-only-title {
    display: flex;
    justify-content: space-between;
    cursor: pointer;
}

.filters-block-filter-show-only-title-icon {
    display: flex;
    align-items: center;
}

#filters-block-filter-show-only-title h4 {
    color: var(--main-bg);
    font-size: 26px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
}

.collapsed-filter-show-only{
    height: 80px !important; 
    overflow: hidden; 
  }

  #filters-block-filter-show-only-content {
    -moz-transition: opacity 0.8s ease;
    -webkit-transition: opacity 0.8s ease;
    -o-transition: opacity 0.8s ease;
    transition: opacity 0.8s ease;
  }

  #filters-block-filter-show-only .collapsed-filter-show-only-content {
    overflow: hidden !important; 
    opacity:0 !important;
  }

#filters-block-filter-show-only-content {
    display: flex;
    flex-direction: column;
    gap: 12px;
}

.filters-block-filter-show-only-content-checkbox {
    display: flex;
    gap: 24px;
    height: 24px;
    align-items: center;
}

.filters-block-filter-show-only-content-checkbox p {
    color: var(--second-bg);
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: 0.32px;
}

#filters-block-filter-show-only-content-checkbox-1,
#filters-block-filter-show-only-content-checkbox-2 {
    height: 28px;
    cursor: pointer;
}