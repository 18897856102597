@import "~slick-carousel/slick/slick.css";
@import "~slick-carousel/slick/slick-theme.css";

.filters-page-house-card-wrapper-list,
.filters-page-house-card-wrapper {
  border: 1px solid var(--gray);
  display: flex;
  flex-direction: column;
  border-radius: 10px;
  padding: 0px;
  /* gap: 24px; */
  width: calc(50% - 8px);
  background-color: var(--third-bg);
  position: relative;
}

.filters-page-house-card-wrapper-active-list,
.filters-page-house-card-wrapper-active {
  border: 1px solid var(--gray);
  display: flex;
  flex-direction: row;
  border-radius: 10px;
  padding: 0px;
  /* gap: 40px; */
  width: 100%;
  background-color: var(--third-bg);
  position: relative;
}

.filters-page-house-card-content-overlay {
  left: 15px;
  right: 0;
  bottom: 0px;
  position: absolute;
  height: calc(100% - 16px);
  width: calc(100% - 16px);
  background: linear-gradient(
    to bottom,
    #c6c6c600,
    #c6c6c61c,
    #2f2f2f4a,
    #2f2f2f5c,
    #2f2f2f85,
    #2f2f2fb5
  );
  border-radius: 10px;
  z-index: 1;
}

.filters-page-house-card-img {
  height: 398px;
  border-radius: 10px;
  position: relative;
  width: 100%;
}

.filters-page-house-card-menu-label-2,
.filters-page-house-card-menu-originating-system-name {
  box-shadow: 0 2px 8px 2px rgba(0, 0, 0, 0.15);
}

.filters-page-house-card-img-active {
  height: 398px;
  border-radius: 10px;
  position: relative;
  width: 385px;
}

.filters-page-house-card-menu {
  position: absolute;
  top: 16px;
  left: 16px;
  display: flex;
  justify-content: space-between;
  width: calc(100% - 32px);
  min-height: 30px;
  align-items: center;
}

.filters-page-house-card-menu-originating-system-name,
.filters-page-house-card-menu-labels {
  display: flex;
  gap: 6px;
  align-items: center;
  flex-wrap: wrap;
}

.filters-page-house-card-menu-labels p {
  margin-bottom: 0 !important;
}

.filters-page-house-card-menu-originating-system-name {
  padding: 4px 7px;
  font-weight: 500;
  font-size: 12px;
  line-height: 14.52px;
  border-radius: 4px;
  /* background-color: var(--body-text); */
  color: var(--blue);
}

.filters-page-house-card-menu-originating-system-name p {
  font-size: 10px !important;
}

.filters-page-house-card-menu-label-2 p {
  padding: 6px 7px;
  font-weight: 500;
  font-size: 12px;
  line-height: 14.52px;
  border-radius: 4px;
  background-color: var(--blue);
  color: var(--body-text);
}

.filters-page-house-card-menu-label-1 {
  display: flex;
  /* remove next if you need to change line to default */
  position: absolute;
  top: 36px;
  left: 0px;
  height: 26px;
}

.filters-page-house-card-menu-label-1 p {
  padding: 6px 7px;
  font-weight: 500;
  font-size: 12px;
  line-height: 14.52px;
  border-radius: 4px;
  color: var(--blue);
  background-color: #f2f2f2a1;
}

.filters-page-house-card-menu-label-disabled-1 p {
  /* padding: 5px 7px;
  font-weight: 500;
  font-size: 12px;
  line-height: 14.52px;
  border-radius: 4px;
  background-color: #FFF;
  color: var(--blue); */
  display: none;
}

.featured-listings-house-card-menu-heard-icon {
  display: flex;
}

.featured-listings-house-card-menu-heard-icon:hover svg path {
  fill: var(--red);
  cursor: pointer;
}

.filters-page-house-card-menu-heard-icon {
  cursor: pointer;
  height: 30px;
  width: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  z-index: 2;
}

.filters-page-house-card-content {
  display: flex;
  flex-direction: column;
  gap: 1px;
  position: absolute;
  bottom: 16px;
  left: 16px;
  width: calc(100% - 32px);
  z-index: 1;
  background: #2c353c75;
  border-radius: 5px;
  padding: 4px !important;
  /* min-height: 150px; */
}

.filters-page-house-card-content-active {
  display: flex;
  flex-direction: column;
  gap: 8px;
  margin: auto 0;
  position: absolute;
  top: 16px;
  left: 406px;
  width: calc(100% - 32px);
  z-index: 1;
  /* background: #2c353c75; */
  border-radius: 5px;
  padding: 4px !important;
}

.filters-page-house-card-content-active h4,
.filters-page-house-card-content-active p {
  color: var(--second-bg) !important;
}

.filters-page-house-card-content-price h4 {
  color: var(--body-text);
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  letter-spacing: 0.24px;
}

.filters-page-house-card-content-residence p {
  color: var(--white);
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  letter-spacing: 0.32px;
}

.filters-page-house-card-content-area p,
.filters-page-house-card-content-size span,
.filters-page-house-card-content-bd-ba p,
.filters-page-house-card-content-adress p,
.filters-page-house-card-content-name p {
  color: var(--white);
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  letter-spacing: 0.24px;
}

.filters-page-house-card-content-adress p {
  display: inline-block;
  width: calc(100% - 2px);
  white-space: nowrap;
  overflow: hidden !important;
  text-overflow: ellipsis;
}

.filters-page-house-card-content-residence {
  line-height: 19px;
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
}

.filters-page-house-card-content-residence-active {
}

.filters-page-house-card-content-adress {
  line-height: 15px;
}

.filters-page-house-card-content-size {
  height: 17px;
}

.filters-page-house-card-content-size p {
  line-height: 17px;
}

.filters-page-house-card-content-area {
  display: flex;
}

.filters-page-house-card-content-bd-ba {
  display: flex;
  gap: 16px;
}

.filters-page-house-card-content-area > div,
.filters-page-house-card-content-bd-ba > div {
  display: flex;
  align-items: center;
}

.filters-page-house-card-content-area p,
.filters-page-house-card-content-bd-ba p {
  padding-left: 8px;
}

/* .filters-page-house-card-content-size span:last-child {
  font-size: xx-small;
  vertical-align: super;
} */

.filters-page-house-card-img-active .filters-page-house-card-img-container {
  position: relative;
  height: 398px;
  width: 100%;
  border-radius: 0px;
  border-top-left-radius: 10px !important;
  border-bottom-left-radius: 10px !important;
  overflow: hidden;
  background-color: #959292;
}

.filters-page-house-card-wrapper:hover {
  cursor: pointer;
  box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.12), 0 2px 10px 0 rgba(0, 0, 0, 0.8);
}

.filters-page-house-card-img-container {
  position: relative;
  height: 398px;
  width: 100%;
  border-radius: 10px;
  overflow: hidden;
  background-color: #959292;
}

.filters-page-house-card-img-container img {
  height: 100%;
  width: auto;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  -webkit-transform: translate(-50%, -50%) !important;
  border-top-left-radius: 10px !important;
  border-bottom-left-radius: 10px !important;
  /* filter: brightness(0.6); */
}

.filters-page-house-card-img-container img {
  height: 100% !important;
  width: 100% !important;
  object-fit: cover;
}

.filters-page-house-card-img-active img {
  height: auto;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  -webkit-transform: translate(-50%, -50%) !important;
  width: 100%;
  border-top-left-radius: 10px !important;
  border-bottom-left-radius: 10px !important;
  /* filter: brightness(0.6); */
}

.filters-page-house-card-img-active .slick-slide img,
.filters-page-house-card-img .slick-slide img {
  margin: auto;
}

.filters-page-house-card-img-active .slick-dots,
.filters-page-house-card-img .slick-dots {
  bottom: 16px;
}

.filters-page-house-card-img-active .slick-dotted .slick-slider,
.filters-page-house-card-img .slick-dotted .slick-slider {
  margin-bottom: 0px;
}

.filters-page-house-card-img-active .slick-dots li button::before,
.filters-page-house-card-img .slick-dots li button::before {
  color: #d9d9d9 !important;
  opacity: 1 !important;
  font-size: 5px !important;
  line-height: 20px;
}

.filters-page-house-card-img-active .slick-dots li.slick-active button::before,
.filters-page-house-card-img .slick-dots li.slick-active button::before {
  color: #d9d9d9 !important;
  font-size: 8px !important;
}

.filters-page-house-card-img-active .slick-dots li,
.filters-page-house-card-img .slick-dots li {
  margin: 0 !important;
}

.filters-page-house-card-img-spiner {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  -webkit-transform: translate(-50%, -50%);
}

/* .filters-page-house-card-wrapper .slick-next {
  right: 10px !important;
}

.filters-page-house-card-wrapper .slick-prev {
  left: 10px !important;
  z-index: 2;
} */

.filters-page-house-card-wrapper-list
  .filters-page-house-card-slider-left-arrow,
.filters-page-house-card-wrapper .filters-page-house-card-slider-left-arrow {
  position: absolute;
  right: 5px !important;
  top: 50%;
  transform: translate(-50%, -50%);
  -webkit-transform: translate(-50%, -50%);
  z-index: 2;
  border: none;
  border-radius: 50%;
  height: 22px;
  background-color: rgba(0, 0, 0, 0);
  cursor: pointer;
}

.filters-page-house-card-wrapper-list
  .filters-page-house-card-slider-left-arrow
  svg,
.filters-page-house-card-wrapper
  .filters-page-house-card-slider-left-arrow
  svg {
  margin: auto 0;
  margin-left: 2px;
}

.filters-page-house-card-wrapper-list
  .filters-page-house-card-slider-right-arrow
  svg,
.filters-page-house-card-wrapper
  .filters-page-house-card-slider-right-arrow
  svg {
  margin: auto 0;
  margin-right: 2px;
}

.filters-page-house-card-wrapper-list
  .filters-page-house-card-slider-right-arrow,
.filters-page-house-card-wrapper .filters-page-house-card-slider-right-arrow {
  position: absolute;
  left: 24px !important;
  top: 50%;
  transform: translate(-50%, -50%);
  -webkit-transform: translate(-50%, -50%);
  z-index: 2;
  border: none;
  height: 22px;
  border-radius: 50%;
  background-color: rgba(0, 0, 0, 0);
  cursor: pointer;
}

/* .filters-page-house-card-img .filters-page-house-card-img-slide-counter {
  position: absolute;
  bottom: 24px;
  left: 24px;
  color: white;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: 0.32px;
} */

.filters-page-house-card-img-active
  .filters-page-house-card-slider-right-arrow-active {
  position: absolute;
  right: 70px;
  z-index: 2;
  border: none;
  height: 22px;
  border-radius: 50%;
  bottom: 40px;
  background-color: rgba(0, 0, 0, 0);
  cursor: pointer;
}

.filters-page-house-card-img-active
  .filters-page-house-card-slider-left-arrow-active {
  position: absolute;
  right: 16px;
  z-index: 2;
  border: none;
  height: 22px;
  border-radius: 50%;
  bottom: 40px;
  background-color: rgba(0, 0, 0, 0);
  cursor: pointer;
}

.filters-page-house-card-img .filters-page-house-card-img-slide-counter {
  position: absolute;
  right: 24px;
  bottom: 86px;
  transform: translateY(-50%);
  -webkit-transform: translateY(-50%);
  color: white;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  letter-spacing: 0.24px;
  margin-top: 52px;
  z-index: 2;
  background-color: #2c353c75;
  padding: 2px;
  border-radius: 4px;
}

.filters-page-house-card-note-link {
  color: var(--blue);
  cursor: pointer;
}

.filters-page-house-card-img-active .filters-page-house-card-img-slide-counter {
  width: 76px;
  text-align: center;
}

.filters-page-house-card-img-active .filters-page-house-card-img-slide-counter {
  position: absolute;
  bottom: 16px;
  right: 16px;
  color: white;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  letter-spacing: 0.24px;
  z-index: 2;
}

/* .filters-page-house-card-wrapper:hover .filters-page-house-card-img-container{
  -moz-transform: scale(1.1);
  -webkit-transform: scale(1.1);
  -o-transform: scale(1.1);
  -ms-transform: scale(1.1);
  -webkit-transform: scale(1.1);
  transform: scale(1.1);
  -webkit-transition: transform 0.1s ease-in-out;
  -moz-transition: transform 0.1s ease-in-out;
  -ms-transition: transform 0.1s ease-in-out;
  height: 394px;
  border-radius: 0px;
} */

/* .filters-page-house-card-wrapper:hover .slick-list {
    border-radius: 10px !important;
    height: 398px;
} */

/* .filters-page-house-card-wrapper:hover .slick-active { */

.filters-page-house-card-wrapper-active-list:hover,
.filters-page-house-card-wrapper-active:hover,
.filters-page-house-card-wrapper-list:hover,
.filters-page-house-card-wrapper:hover {
  -moz-transform: scale(1.01);
  -webkit-transform: scale(1.01);
  -o-transform: scale(1.01);
  -ms-transform: scale(1.01);
  -webkit-transform: scale(1.01);
  transform: scale(1.01);
  -webkit-transition: transform 0.1s ease-in-out;
  -moz-transition: transform 0.1s ease-in-out;
  -ms-transition: transform 0.1s ease-in-out;
}

.filters-page-house-card-wrapper:hover
  .filters-page-house-card-content-overlay {
  border-radius: 0px !important;
}

.filters-page-house-card-wrapper:hover {
  cursor: pointer;
  box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.12), 0 2px 10px 0 rgba(0, 0, 0, 0.8);
}

.filters-page-house-card-menu-open-house-icon {
  z-index: 2;
  position: absolute;
  bottom: 38px;
  right: 26px;
}

.filters-page-house-card-menu-open-house-icon img {
  height: 24px;
  width: auto;
}

.filters-page-house-card-menu-open-house-icon-active {
  z-index: 2;
  position: absolute;
  bottom: 32px;
  left: 32px;
}

.filters-page-house-card-menu-open-house-icon-active img {
  height: 32px;
  width: auto;
}

.filters-page-house-card-menu-realtytexas-icon {
  height: 26px;
  width: 26px;
  background-image: url("https://cd.realtytexas.com/assets/images/ico-round.png");
  background-size: cover;
  z-index: 3;
  position: relative;
  cursor: pointer;
}

.filters-page-house-card-menu-labels-right {
  display: flex;
  gap: 6px;
  align-items: center;
  justify-content: center;
  background-color: #2c253c75;
  border-radius: 8px;
  padding: 4px;
  height: 30px;
}

.filters-page-house-card-menu-label-disabled-1 {
  display: none;
}

.filters-page-house-card-menu-note-content-icon {
  margin-left: auto;
  cursor: pointer;
  display: flex;
  align-items: center;
}

.filters-page-house-card-menu-note-content-icon-disabled {
  display: none;
}

.filters-page-house-card--blur-wrapper img {
  -webkit-filter: blur(5px);
  -moz-filter: blur(5px);
  -ms-filter: blur(5px);
  -o-filter: blur(5px);
  filter: blur(5px);
}

.filters-page-house-card-img-container {
  position: relative;
  height: 398px;
  width: 100%;
  border-radius: 10px;
  overflow: hidden;
  background-color: #000000c8;
}

.filters-page-house-card-img-container img {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  -webkit-transform: translate(-50%, -50%) !important;
  border-top-left-radius: 10px !important;
  border-bottom-left-radius: 10px !important;
}

.house-card-realty-icon-tooltip p {
  font-size: 12px;
  color: var(--white);
}

.house-card-realty-icon-tooltip {
  position: absolute;
  top: 36px;
  left: 0px;
  width: 145px;
  background-color: var(--main-bg);
  border: 1px solid var(--gray);
  border-radius: 8px;
  height: 32px;
  z-index: 50;
  text-align: center;
}

.house-card-realty-icon-tooltip-triangle {
  position: absolute;
  top: 31px;
  left: calc(50% - 4px);
  width: 10px;
  height: 10px;
  transform: rotate(45deg);
  -webkit-transform: rotate(45deg);
  background: var(--main-bg);
  z-index: 50;
  border-top: 1px solid var(--gray);
  border-left: 1px solid var(--gray);
}

.house-card-realty-icon-tooltip p {
  line-height: 18px;
  padding: 6px;
}
