.new-SortingBlock-wrapper {
  width: 100%;
}

#filters-page-block-wrapper {
  display: flex;
  flex-direction: column;
}

.filters-page-block-wrapper-map-wrapper {
  width: calc(100% - 500px);
}

#filters-page-block-content {
  /* padding: 70px 99px 150px 99px; */
  padding: 0 16px 16px 16px;
  display: flex;
  gap: 16px;
}

#filters-page-block-content-left {
  width: calc(100% / 3 - 12px);
}

#filters-page-block-content-right {
  width: calc(100% / 3 + 100% / 3 - 4px);
}

.sorting-block-icon-map-list {
  position: relative;
}

#filters-page-block-filter-block-narrow {
  /* position: absolute; */
  background-color: #e9e9e9;
  /* top: 77px; */
  top: 0;
  left: 0;
  z-index: 51 !important;
  width: 100vw !important;
}

#filters-page-block-filter-block-narrow-top-buttons-block {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 22px;
  background: #e9e9e9;
  z-index: 10;
  /* position: fixed; */
  width: 100%;
  border: 1px solid var(--gray);
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  height: 59px !important;
}

#filters-page-block-filter-block-narrow-top-buttons-block div {
  cursor: pointer;
}

#filters-page-block-filter-block-narrow-top-buttons-block p {
  color: var(--blue);
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.new-filters-page-house-cards-block-wrapper-displaying-listings-wrapper-loading {
  color: var(--second-bg);
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

/* .sorting-block-header-wrapper {
    display: flex;
    gap: 16px;
    padding: 16px 26px 0 26px;
} */

.sorting-block-header-wrapper {
  display: flex;
  gap: 16px;
  padding: 16px 26px 0 26px;
  padding-bottom: 16px !important;
  position: fixed;
  top: 77px;
  left: 0;
  height: 87px;
  width: 100%;
  z-index: 50;
  background-color: var(--white);
}

.sorting-block-header-wrapper-430 {
  position: initial !important;
  display: flex;
  gap: 16px;
  padding: 16px;
  margin-bottom: 22px;
  height: 87px;
  width: 100%;
  z-index: 50;
  background-color: var(--white);
}

.sorting-block-header-wrapper-sticky {
  padding: 16px 26px 0 26px;
  position: fixed;
  /* position: sticky; */
  /* position: -webkit-sticky; */
  right: 0;
  width: 500px;
  /* top: calc(77px + 40vh); */
  top: 77px;
  z-index: 50;
  background-color: var(--white);
  padding-bottom: 16px !important;
}

.sorting-block-header-wrapper-sticky
  .sorting-block-header-wrapper-block-displaying-listings,
.sorting-block-header-wrapper-sticky .sorting-block-header-wrapper-block {
  width: calc(50% - 185px);
}

.sorting-block-header-wrapper-sticky
  .sorting-block-header-wrapper-block-displaying-listings {
  justify-content: flex-end;
}

.sorting-block-header-wrapper > div {
  width: 33%;
}

.sorting-block-header-wrapper-block-displaying-listings {
  border-radius: 10px;
  border: 1px solid var(--gray);
  padding: 0px 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: calc((100% - 370px) / 2);
}

.sorting-block-header-wrapper-block {
  width: calc((100% - 370px) / 2);
}

.sorting-block-header-wrapper-sticky .sorting-block-mapList-wrapper,
.sorting-block-header-wrapper .sorting-block-mapList-wrapper {
  position: absolute;
  display: flex;
  flex-direction: column;
  padding: 8px;
  border-radius: 10px;
  background-color: #f9f9f9;
  box-shadow: 4px 0 30px 0 rgba(0, 0, 0, 0.25);
  gap: 16px;
  right: -59px;
  top: 70px;
  z-index: 3;
  cursor: initial;
  width: 102px;
}

.sorting-block-header-wrapper-sticky .sorting-block-mapList-context,
.sorting-block-header-wrapper .sorting-block-mapList-context {
  font-size: 14px;
  padding: 10px;
}

.sorting-block-header-wrapper-sticky .sorting-block-mapList-context-current,
.sorting-block-header-wrapper .sorting-block-mapList-context-current {
  font-size: 14px !important;
  padding: 10px !important;
}

.sorting-block-header-wrapper-sticky .sorting-block-mapList-context:hover,
.sorting-block-header-wrapper .sorting-block-mapList-context:hover {
  background-color: #e0e0e0;
  border-radius: 10px;
  cursor: pointer;
}

.sorting-block-icon-1-tooltip {
  position: absolute;
  /* left: 3px; */
  left: 32px;
  top: -25px;
  display: none;
}

.sorting-block-icon-1_5-tooltip {
  position: absolute;
  /* left: 45px; */
  left: 6px;
  top: -25px;
  display: none;
}

.sorting-block-icon-2-tooltip {
  position: absolute;
  /* left: 121px; */
  left: 90px;
  top: -25px;
  display: none;
}

.sorting-block-icon-2_5-tooltip-all,
.sorting-block-icon-2_5-tooltip {
  position: absolute;
  /* left: -30px; */
  bottom: 36px;
  width: 110px;
  display: none;
}

.sorting-block-icon-2_5-tooltip-all .tooltip-message-wrapper,
.sorting-block-icon-2_5-tooltip .tooltip-message-wrapper {
  padding: 0px 4px;
}

.sorting-block-icon-2_5-tooltip-all .tooltip-message-wrapper span,
.sorting-block-icon-2_5-tooltip .tooltip-message-wrapper span {
  font-size: 12px;
}

.filters-page-block-filter-block-narrow-propertyTypes {
  -moz-transition: max-height 0.6s ease;
  -webkit-transition: max-height 0.6s ease;
  -o-transition: max-height 0.6s ease;
  transition: max-height 0.6s ease;
  max-height: 2000px;
}

.collapsed-filter-propertyTypes-subTypes {
  max-height: 80px !important;
  -moz-transition: max-height 0.6s ease;
  -webkit-transition: max-height 0.6s ease;
  -o-transition: max-height 0.6s ease;
  transition: max-height 0.6s ease;
  overflow: hidden;
}

.sorting-block-header-wrapper-block-displaying-listings-showing {
  height: 22px;
  display: flex;
  align-items: center;
}

.sorting-block-icon-3-filters {
  height: 30px;
  /* padding: 0 16px; */
  padding: 0 12px;
  font-size: 14px;
  background-color: var(--red);
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 8px;
  color: var(--white);
  line-height: 30px;
}

#sorting-block-icon-2_5 {
  margin: 0;
  height: 30px;
  width: calc(100% - 210px - 73px - 16px);
  max-width: 78px;
  position: relative;
}

.new-sorting-block-btn-wrapper-list #sorting-block-icon-2_5 {
  margin: 0;
  height: 30px;
  width: calc(100% - 210px - 73px - 24px);
}

/* new-sorting-block-btn-wrapper-list" */
.new-sorting-block-btn-wrapper-both #sorting-block-icon-2_5 {
  margin-left: auto;
}

.sorting-block-icon-2_5-our-listings_black {
  height: 30px;
  padding: 0 4px;
  font-size: 12px;
  background-color: var(--main-bg);
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 8px;
  color: var(--white);
  cursor: pointer;
  gap: 4px;
}

.sorting-block-icon-2_5-our-listings {
  height: 30px;
  padding: 0 4px;
  font-size: 12px;
  background-color: var(--blue);
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 8px;
  color: var(--white);
  cursor: pointer;
  gap: 4px;
}

.sorting-block-icon-2_5-our-listings-content {
  overflow: hidden;
  display: inline-block;
  text-overflow: ellipsis;
  white-space: nowrap;
  /* max-width: 40px; */
  line-height: 30px;
}

.sorting-block-icon-2_5-our-listings-realtytexas-icon {
  height: 26px;
  width: 26px;
  background-image: url("https://cd.realtytexas.com/assets/images/ico-round.png");
  background-size: cover;
  z-index: 3;
  position: relative;
  cursor: pointer;
}

.sorting-block-header-wrapper-block-both-440 {
  width: calc(100% - 32px) !important;
  justify-content: space-between !important;
}

#new-wrapper-header {
  height: 77px;
  width: 100%;
  background-color: chocolate;
  position: fixed;
  top: 0;
  z-index: 1;
}

#new-wrapper {
  display: flex;
  /* background-color: blue; */
  min-height: 40svh;
  position: relative !important;
  /* margin-top: 77px; */
}

#new-wrapper-left {
  display: flex;
  /* background-color: rgb(255, 0, 255); */
  height: calc(100svh - 178px);
  /* width: calc(100% - 500px); */
  position: sticky;
  top: 178px;
}

.new-wrapper-left {
  width: calc(100% - 500px) !important;
}

.new-wrapper-left-map {
  width: 100% !important;
}

#new-wrapper-right {
  height: auto;
  width: 500px;
  /* background-color: rgb(0, 213, 255); */
  padding: 0 16px;
  margin-bottom: 16px;
}

.new-wrapper-right-both,
.new-wrapper-right {
  display: block;
}

.new-wrapper-right-map {
  display: none !important;
}

#new-wrapper-top {
  height: 126px;
  background-color: var(--white);
  position: sticky;
  top: 77px;
  z-index: 50;
  padding: 16px;
  width: calc(100% + 32px) !important;
  margin-left: -16px !important;
}

#new-wrapper-top #sorting-block-input-wrapper ul {
  border: 1px solid #908f8f;
  width: inherit;
  border-radius: 8px;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
  /* min-height: 390px; */
}

.new-wrapper-main-both .filters-page-house-card-img-active {
  height: 298px;
  width: 285px;
}

.new-wrapper-main-both
  .filters-page-house-card-img-active
  .filters-page-house-card-img-container {
  height: 298px;
}

.new-wrapper-main-both .filters-page-house-card-content-active {
  left: 300px;
  width: calc(100% - 32px - 285px);
}

.new-wrapper-main-both
  .filters-page-house-card-content-active-main-content-wrapper
  h4 {
  font-size: 16px !important;
}

.new-wrapper-main-both
  .filters-page-house-card-content-active-main-content-wrapper
  p {
  font-size: 14px !important;
}

#new-wrapper-main {
  min-height: 20px;
  width: 100%;
  /* background-color: rgb(112, 165, 42); */
  /* margin-top: 77px; */
  z-index: 50;
  display: flex;
  gap: 16px;
}

.new-FiltersBlockNarrow-map ul,
#new-wrapper-right #filters-block-wrapper-form-narrow ul,
#new-wrapper-main form ul {
  position: absolute;
  list-style-type: none;
  top: 54px;
  padding: 0;
  margin: 0;
  z-index: 50;
  width: 550px;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
  border: 1px solid var(--gray);
  background-color: var(--white) !important;
  /* display: flex; */
  flex-direction: column;
  gap: 4px;
  box-sizing: border-box;
  max-height: 375px;
  overflow-y: auto;
  display: none;
  width: 100%;
}

#new-wrapper-right #filters-page-block-filter-block-narrow-top-buttons-block {
  position: fixed !important;
  width: 468px;
}

/* #new-wrapper-right #filters-block-filter-quick-search {
    padding-top: 66px !important;
} */

.new-FiltersBlockNarrow-map {
  overflow-y: auto;
  max-height: calc(100svh - 77px);
  width: 468px;
  position: absolute;
  top: 0;
  z-index: 107;
  left: calc(100vw / 2 - 234px);
}

.new-FiltersBlockNarrow-map #filters-block-filters-buttons-block-wrapper,
.new-FiltersBlockNarrow-map
  #filters-page-block-filter-block-narrow-top-buttons-block {
  border-radius: 0 !important;
}

.new-FiltersBlockNarrow-map {
  -ms-overflow-style: none;
  /* Internet Explorer 10+ */
  scrollbar-width: none;
  /* Firefox */
}

.new-FiltersBlockNarrow-map::-webkit-scrollbar {
  display: none;
  /* Safari and Chrome */
}

.new-FiltersBlockNarrow-map
  #filters-page-block-filter-block-narrow-top-buttons-block {
  position: fixed;
  width: 468px;
}

.new-wrapper-right-both #filters-block-filter-quick-search {
  height: 205px;
}

.new-wrapper-right-both .collapsed-quick-search {
  height: 122px !important;
}

.new-FiltersBlockNarrow-map #filters-block-filter-quick-search {
  margin-top: 58px !important;
  border-radius: 0 !important;
}

.new-bottomHeaderPaginationComponent-wrapper {
  position: relative;
  display: flex;

  /* z-index: -1; */
}

.new-sorting-block-btn-wrapper {
  display: flex;
  gap: 8px;
  margin-left: auto;
  height: 30px;
  margin-top: 12px;
}

.new-filters-page-house-cards-block-wrapper-displaying-listings-wrapper {
  display: flex;
}

.new-filters-page-block-content-left {
  width: 370px;
  margin-top: 5px;
}

#sorting-block-input-wrapper {
  display: flex;
  /* gap: 16px; */
}

#new-wrapper-top .sorting-block-header-wrapper-block-2 {
  border: 1px solid var(--gray);
  border-right: none !important;
  border-bottom-left-radius: 10px !important;
  border-top-left-radius: 10px !important;
  gap: 12px;
  /* padding: 0 24px; */
  padding: 0 12px;
  align-items: center;
  position: relative;
}

.new-bottomHeaderPaginationComponent-wrapper-list {
  display: flex;
}

.new-bottomHeaderPaginationComponent-wrapper-list
  #filters-page-house-cards-block-wrapper-displaying-listings {
  margin-top: 8px;
}

.sorting-block-header-wrapper-block-1 {
  width: 100%;
}

/* //////////////////////////////// */
/* //////////////////////////////// */

.new-bottomHeaderPaginationComponent-wrapper {
  flex-direction: column-reverse;
  display: flex;
}

#new-wrapper-right {
  /* width: 500px; */
  width: calc(60% + 16px);
}

#new-wrapper-left {
  /* width: calc(100% - 500px); */
  width: calc(40% - 16px);
}

.new-sorting-block-btn-wrapper {
  margin-left: 0;
  justify-content: space-between;
}

#new-wrapper-top {
  height: 160px;
}

.new-wrapper-top-list {
  height: 126px !important;
}

.new-filters-page-house-cards-block-wrapper-displaying-listings-wrapper {
  justify-content: center;
}

.new-sorting-block-btn-wrapper-list {
  justify-content: left;
  gap: 12px;
}

#new-wrapper-left-800 {
  display: none;
}

.new-wrapper-left-800 .filters-page-map-box {
  display: none;
}

.filters-page-house-cards-block-wrapper-displaying-listings-showing-page {
  display: flex !important;
  justify-content: center;
  align-items: center;
}

@media screen and (min-width: 769px) and (max-width: 925px) {
  .sorting-block-header-wrapper-block-displaying-listings span:last-child {
    display: none;
  }
}

@media screen and (min-width: 769px) and (max-width: 1079px) {
  .new-wrapper-top-list
    #filters-page-house-cards-block-wrapper-displaying-listings {
    padding-left: 71px !important;
  }
}

@media screen and (min-width: 769px) {
  .sorting-block-header-wrapper-block-1-list input {
    border-left: none !important;
    border-bottom-left-radius: 0px !important;
    border-top-left-radius: 0px !important;
  }
}

@media screen and (max-width: 768px) {
  .sorting-block-header-wrapper-block-2 {
    display: none !important;
  }

  /* .sorting-block-header-wrapper-block-1-list {
    width: 100%;
  } */

  .new-bottomHeaderPaginationComponent-wrapper-list {
    /* justify-content: space-between; */
    width: 100%;
  }

  /* .filters-page-house-card-note-content-popup-list {
        height: calc((100vw - 208px)*0.666666)
    } */

  .new-sorting-block-btn-wrapper-list #sorting-block-icon-2_5 {
    width: 108px;
    max-width: 108px;
    margin-left: auto;
  }
  .new-sorting-block-btn-wrapper-list #sorting-block-icon-3 {
    margin-left: 0 !important;
  }

  .new-wrapper-top-list {
    height: 102px !important;
    display: flex;
    flex-direction: row-reverse;
    justify-content: space-between;
  }

  .new-wrapper-top-list #sorting-block-input-wrapper {
    justify-content: end;
    width: 50px;
  }

  /* .new-wrapper-top-list .sorting-block-header-wrapper-block {
    width: 20px !important;
  } */
  .new-wrapper-top-list .sorting-block-header-wrapper-block input {
    width: 20px !important;
    border: none;
  }

  .new-wrapper-top-list .sorting-block-header-wrapper-block ul {
    width: 200px !important;
    right: 0px;
    /* top: 89px !important; */
  }

  .new-wrapper-top-list .filters-page-base-input-list-end-icon-wrapper {
    right: 14px;
  }

  .new-wrapper-top-list
    .new-filters-page-house-cards-block-wrapper-displaying-listings-wrapper {
    position: absolute;
    left: 16px;
    bottom: 8px;
  }

  .new-wrapper-top-list .new-sorting-block-btn-wrapper-list {
    width: 100%;
    margin-bottom: 12px;
  }

  #new-wrapper-top {
    padding-bottom: 0px;
  }

  .new-wrapper-top-list .filters-page-base-input-list-wrapper {
    border-radius: 10px;
    border: 1px solid var(--gray);
    border-bottom-left-radius: 0;
    border-top-left-radius: 0;
    border-left: none;
  }

  .new-wrapper-top-list .new-sorting-block-btn-wrapper-list {
    margin: 0 !important;
    padding: 12px 0 12px 12px;
    height: 55px;
    border-radius: 10px;
    border: 1px solid var(--gray);
    border-bottom-right-radius: 0;
    border-top-right-radius: 0;
    border-right: none;
  }

  .new-wrapper-top-list .filters-page-base-input-list-wrapper {
    border: none;
  }
  .new-wrapper-top-list .filters-page-base-input-list-wrapper input {
    border: 1px solid var(--gray);
    border-top-left-radius: 0px;
    border-bottom-left-radius: 0px !important;
    height: 55px;
    border-left: none;
  }
}

@media screen and (min-width: 769px) {
  #new-wrapper-top .sorting-block-header-wrapper-block-1-list {
    width: 200px !important;
  }

  #new-wrapper-top .sorting-block-header-wrapper-block-2 {
    width: calc(100% - 200px) !important;
  }
}

/* @media screen and (min-width: 769px) and (max-width: 900px) {
  .new-bottomHeaderPaginationComponent-wrapper-list {
    justify-content: space-between;
  }

  .new-bottomHeaderPaginationComponent-wrapper-list #sorting-block-icon-3 {
    display: none;
  }

  #new-wrapper-top .sorting-block-header-wrapper-block-2 #sorting-block-icon-2,
  #new-wrapper-top
    .sorting-block-header-wrapper-block-2
    #sorting-block-icon-1_5,
  #new-wrapper-top .sorting-block-header-wrapper-block-2 #sorting-block-icon-1 {
    display: flex !important;
  } */

/* #new-wrapper-top .sorting-block-header-wrapper-block-2 .sorting-block-icon-map-list, */
/* #new-wrapper-top
    .sorting-block-header-wrapper-block-2
    #filters-page-house-cards-block-wrapper-displaying-listings {
    display: none;
  }

  .new-bottomHeaderPaginationComponent-wrapper-list
    .new-sorting-block-btn-wrapper-list {
    display: none;
  }

  #new-wrapper-top
    .sorting-block-header-wrapper-block-2
    .new-sorting-block-btn-wrapper {
    margin-left: 0;
    margin-top: 0;
    width: 100%;
  }

  #new-wrapper-top
    .sorting-block-header-wrapper-block-2
    .new-sorting-block-btn-wrapper
    #sorting-block-icon-3 {
    margin-left: auto;
  }
} */

/* both view */
.filters-page-house-card-wrapper {
  width: calc(50% - 8px);
  /* height: calc(0.85*398px); */
  height: calc((50vw - 32px) * 0.666666);
}

/* .filters-page-house-card-note-content-popup {
  height: calc((50vw - 208px) * 0.666666);
} */

.new-wrapper-right-both {
  width: calc(60% + 20px) !important;
}

.new-wrapper-left-both {
  /* width: 40% !important; */
  left: 16px;
}

@media screen and (min-width: 769px) and (max-width: 1599px) {
  .new-wrapper-main-both .filters-page-house-card-wrapper {
    width: calc(50% - 8px) !important;
    height: calc((60vw - 32px) * 0.32) !important;
  }

  .new-wrapper-main-both .filters-page-house-card-note-content-popup {
    height: calc((60vw - 32px) * 0.32 - 88px) !important;
  }

  .new-wrapper-main-both
    .filters-page-house-card-wrapper
    .filters-page-house-card-img-container {
    height: calc((60vw - 32px) * 0.32) !important;
  }
}

@media screen and (min-width: 1600px) and (max-width: 2399px) {
  .new-wrapper-right-both #sorting-block-input-wrapper {
    width: calc(33% - 8px);
  }
  .filters-page-house-card-wrapper {
    width: calc(33% - 8px) !important;
    height: calc((60vw - 64px) / 3 * 0.666666) !important;
  }

  .new-wrapper-main-both .filters-page-house-card-note-content-popup {
    height: calc((60vw - 64px) / 3 * 0.666666 - 92px) !important;
  }

  .filters-page-house-card-wrapper .filters-page-house-card-img-container {
    height: calc((60vw - 64px) / 3 * 0.666666) !important;
  }
}

@media screen and (min-width: 2400px) and (max-width: 3175px) {
  .filters-page-house-card-wrapper {
    width: calc(25% - 12px) !important;
    height: calc((60vw - 80px) / 4 * 0.666666) !important;
  }

  .new-wrapper-main-both .filters-page-house-card-note-content-popup {
    height: calc((60vw - 80px) / 4 * 0.666666 - 92px) !important;
  }

  .filters-page-house-card-wrapper .filters-page-house-card-img-container {
    height: calc((60vw - 80px) / 4 * 0.666666) !important;
  }
}

@media screen and (min-width: 3176px) and (max-width: 3969px) {
  .new-wrapper-right-both #sorting-block-input-wrapper {
    width: calc(20% - 14px);
  }

  /* .new-bottomHeaderPaginationComponent-wrapper-800
    .new-sorting-block-btn-wrapper-both {
    position: absolute;
    top: 16px;
    width: calc(80% - 36px);
    left: calc(20% + 12px);
    margin-top: 0;
    height: 55px;
    align-items: center;
    padding: 0 12px;
    border-radius: 10px;
    border: 1px solid var(--gray);
    gap: 12px;
  } */

  .filters-page-house-card-wrapper {
    width: calc(20% - 14px) !important;
    height: calc((60vw - 96px) / 5 * 0.666666) !important;
  }

  .new-wrapper-main-both .filters-page-house-card-note-content-popup {
    height: calc((60vw - 96px) / 5 * 0.666666 - 92px) !important;
  }

  .filters-page-house-card-wrapper .filters-page-house-card-img-container {
    height: calc((60vw - 96px) / 5 * 0.666666) !important;
  }
}

@media screen and (min-width: 3970px) and (max-width: 4759px) {
  .new-wrapper-right-both #sorting-block-input-wrapper {
    width: calc(16.6% - 12px);
  }

  /* .new-bottomHeaderPaginationComponent-wrapper-800
    .new-sorting-block-btn-wrapper-both {
    position: absolute;
    top: 16px;
    width: calc(83.3% - 32px);
    left: calc(16.6% + 14px);
    margin-top: 0;
    height: 55px;
    align-items: center;
    padding: 0 12px;
    border-radius: 10px;
    border: 1px solid var(--gray);
    gap: 12px;
  } */

  .filters-page-house-card-wrapper {
    width: calc(16.66% - 14px) !important;
    height: calc((60vw - 112px) / 6 * 0.666666) !important;
  }

  .new-wrapper-main-both .filters-page-house-card-note-content-popup {
    height: calc((60vw - 112px) / 6 * 0.666666 - 92px) !important;
  }

  .filters-page-house-card-wrapper .filters-page-house-card-img-container {
    height: calc((60vw - 112px) / 6 * 0.666666) !important;
  }
}

@media screen and (min-width: 4760px) {
  .new-wrapper-right-both #sorting-block-input-wrapper {
    width: calc(14.28% - 14px);
  }

  /* .new-bottomHeaderPaginationComponent-wrapper-800
    .new-sorting-block-btn-wrapper-both {
    position: absolute;
    top: 16px;
    width: calc(85.62% - 32px);
    left: calc(14.28% + 16px);
    margin-top: 0;
    height: 55px;
    align-items: center;
    padding: 0 12px;
    border-radius: 10px;
    border: 1px solid var(--gray);
    gap: 12px;
  } */

  .filters-page-house-card-wrapper {
    width: calc(14.28% - 14px) !important;
    height: calc((60vw - 128px) / 7 * 0.666666) !important;
  }

  .new-wrapper-main-both .filters-page-house-card-note-content-popup {
    height: calc((60vw - 128px) / 7 * 0.666666 - 92px) !important;
  }

  .filters-page-house-card-wrapper .filters-page-house-card-img-container {
    height: calc((60vw - 118px) / 7 * 0.666666) !important;
  }
}

.filters-page-house-card-note-content-popup
  .filters-page-house-card-note-content-popup-content-wrapper {
  height: 100% !important;
}

@media screen and (min-width: 801px) and (max-width: 1199px) {
  .filters-page-house-card-note-content-popup-list {
    height: calc((100vw - 360px) / 2 * 0.666666);
  }
}

@media screen and (min-width: 1200px) and (max-width: 1439px) {
  .filters-page-house-card-note-content-popup-list {
    height: calc((100vw - 518px) / 3 * 0.666666) !important;
  }
}

@media screen and (min-width: 1440px) and (max-width: 1919px) {
  .filters-page-house-card-note-content-popup-list {
    height: calc((100vw - 662px) / 4 * 0.666666) !important;
  }
}

.filters-page-house-card-wrapper .filters-page-house-card-img-container {
  /* height: calc(0.85*398px); */
  height: calc((50% - 8px) * 0.666666);
}

/* .filters-page-house-card-wrapper {
    width: 100%;
} */

/* .new-bottomHeaderPaginationComponent-wrapper-800 {
    display: none;
} */

/* .new-wrapper-left-both {
  max-width: 800px;
} */

@media screen and (min-width: 1080px) {
  .new-wrapper-right-both #sorting-block-input-wrapper {
    /* width: calc(50% - 8px); */
    width: 200px;
    margin-left: auto;
  }

  .new-wrapper-right-both #sorting-block-input-wrapper input {
    /* width: calc(50% - 8px); */
    border-left: none;
    border-bottom-left-radius: 0px !important;
    border-top-left-radius: 0px !important;
  }

  .new-bottomHeaderPaginationComponent-wrapper-800
    .new-sorting-block-btn-wrapper-both {
    position: absolute;
    top: 16px;
    width: calc(100% - 200px - 32px);
    /* left: calc(50% + 6px); */
    left: 16px;
    margin-top: 0;
    height: 55px;
    align-items: center;
    padding: 0 12px;
    border-radius: 10px;
    border: 1px solid var(--gray);
    gap: 8px;
    border-right: none;
    border-bottom-right-radius: 0px !important;
    border-top-right-radius: 0px !important;
  }
}

/* @media screen and (min-width: 1600px) and (max-width: 2399px) {
  .new-bottomHeaderPaginationComponent-wrapper-800
    .new-sorting-block-btn-wrapper-both {
    position: absolute;
    top: 16px;
    width: calc(67% - 32px);
    left: calc(33% + 14px);
    margin-top: 0;
    height: 55px;
    align-items: center;
    padding: 0 12px;
    border-radius: 10px;
    border: 1px solid var(--gray);
    gap: 12px;
  }
} */

@media screen and (min-width: 2400px) and (max-width: 3175px) {
  /* .new-wrapper-right-both #sorting-block-input-wrapper {
    width: calc(25% - 12px);
  }
  .new-bottomHeaderPaginationComponent-wrapper-800
    .new-sorting-block-btn-wrapper-both {
    position: absolute;
    top: 16px;
    width: calc(75% - 32px);
    left: calc(25% + 14px);
    margin-top: 0;
    height: 55px;
    align-items: center;
    padding: 0 12px;
    border-radius: 10px;
    border: 1px solid var(--gray);
    gap: 12px;
  } */
}

/* @media screen and (min-width: 1617px) and (max-width: 1919px) { */
/* both view */
/* .filters-page-house-card-wrapper {
    width: calc(100% / 2 - 15px);
    height: calc((100vw - 800px - 48px) / 2 * 0.666666);
  }

  .filters-page-house-card-note-content-popup {
    height: calc((800px / 2 + 8px - 208px) * 0.666666);
  }

  .filters-page-house-card-note-content-popup {
    height: calc((100vw - 1000px - 208px) / 2 * 0.666666);
  }

  .filters-page-house-card-wrapper .filters-page-house-card-img-container {
    height: calc((100vw - 800px - 48px) / 2 * 0.666666);
  }
} */

/* @media screen and (min-width: 1617px) {
  .new-wrapper-right-both {
    width: calc(100% - 800px) !important;
  }

  .new-wrapper-left-both {
    width: 800px !important;
  }
} */

@media screen and (min-width: 1080px) {
  /* //////////////////////////////// */

  .filters-page-house-card-wrapper {
    width: calc(100% / 2 - 8px);
  }

  #new-wrapper-right #filters-page-block-filter-block-narrow-top-buttons-block {
    position: fixed !important;
    width: 768px;
  }

  /* .new-wrapper-right-both #sorting-block-input-wrapper {
    width: calc(50% - 8px);
  } */

  /* .new-bottomHeaderPaginationComponent-wrapper-800 {
        display: flex;
        /* position: relative;
    }  */

  .new-bottomHeaderPaginationComponent-wrapper-800
    #filters-page-house-cards-block-wrapper-displaying-listings {
    display: none;
  }

  /* .new-bottomHeaderPaginationComponent-wrapper-800 .new-sorting-block-btn-wrapper-both {
            position: absolute;
            top: 16px;
            width: calc(50% - 24px);
            left: calc(50% + 8px);
            margin-top: 0;
            height: 55px;
            align-items: center;
            padding: 0 24px;
            border-radius: 10px;
            border: 1px solid var(--gray);
            gap: 12px
        } */

  .new-bottomHeaderPaginationComponent-wrapper-800 #sorting-block-icon-1,
  .new-bottomHeaderPaginationComponent-wrapper-800 #sorting-block-icon-1_5,
  .new-bottomHeaderPaginationComponent-wrapper-800 #sorting-block-icon-2 {
    display: flex;
  }

  .new-bottomHeaderPaginationComponent-wrapper-800 #sorting-block-icon-3 {
    margin-left: auto;
  }

  /* .new-bottomHeaderPaginationComponent-wrapper-800 .sorting-block-icon-map-list {
            display: none;
        } */
  .new-wrapper-top {
    height: 126px !important;
  }

  .new-bottomHeaderPaginationComponent-wrapper {
    flex-direction: row;
    width: 100%;
    justify-content: space-between;
  }

  .new-bottomHeaderPaginationComponent-wrapper #sorting-block-icon-3 {
    display: none;
  }

  .new-bottomHeaderPaginationComponent-wrapper
    .new-sorting-block-btn-wrapper-both {
    display: none;
  }

  .new-bottomHeaderPaginationComponent-wrapper {
    justify-content: space-between;
  }
}

#sorting-block-input-wrapper {
  width: calc(100%);
}

/* @media screen and (min-width: 1600px) { */
/* .new-wrapper-right-both #sorting-block-input-wrapper {
    width: calc(33% - 8px);
  } */
/* .new-FiltersBlockNarrow-map-both {
    left: 0px !important;
    width: 800px;
    padding-left: 16px;
    background-color: var(--white);
  } */

/* .new-FiltersBlockNarrow-map-both
    #filters-page-block-filter-block-narrow-top-buttons-block {
    width: 784px;
  } */
/* } */

@media screen and (min-width: 769px) {
  .new-FiltersBlockNarrow-map-both {
    left: 0px;
    width: calc(40%);
    padding-left: 16px;
    background-color: var(--white);
  }

  .MainHeaderLayer-MainHeader-filters-wrapper-both .LocationPopup-wrapper,
  .MainHeaderLayer-MainHeader-filters-wrapper-both .TypePopup-wrapper,
  .MainHeaderLayer-MainHeader-filters-wrapper-both .SpecsPopup-wrapper {
    width: calc(40% - 16px) !important;
  }

  .new-FiltersBlockNarrow-map-list {
    left: 0px;
    width: calc(50% - 8px);
    padding-left: 16px;
    background-color: var(--white);
  }

  .LocationPopup-wrapper,
  .TypePopup-wrapper,
  .PricePopup-wrapper,
  .SpecsPopup-wrapper {
    width: calc(50% - 24px);
  }

  .new-FiltersBlockNarrow-map-list
    #filters-page-block-filter-block-narrow-top-buttons-block {
    width: calc(50% - 24px);
  }

  .new-FiltersBlockNarrow-map-both
    #filters-page-block-filter-block-narrow-top-buttons-block {
    width: calc(40% - 16px) !important;
  }
}

/* @media screen and (min-width: 901px) and (max-width: 1199px) {
  .new-FiltersBlockNarrow-map-both {
    left: 0px !important;
    width: calc(100% - 500px) !important;
    padding-left: 16px;
    background-color: var(--white);
  }

  .new-FiltersBlockNarrow-map-both
    #filters-page-block-filter-block-narrow-top-buttons-block {
    width: calc(100% - 516px) !important;
  }
} */

@media screen and (min-width: 1200px) and (max-width: 1439px) {
  #new-wrapper-top .sorting-block-header-wrapper-block-1-list {
    width: calc(33% - 8px);
  }

  #new-wrapper-top .sorting-block-header-wrapper-block-2 {
    width: calc(67% - 12px);
  }

  .new-FiltersBlockNarrow-map-list {
    left: 0px;
    width: 33%;
    padding-left: 16px;
    background-color: var(--white);
  }

  .LocationPopup-wrapper,
  .TypePopup-wrapper,
  .PricePopup-wrapper,
  .SpecsPopup-wrapper {
    width: calc(33% - 20px);
  }

  .new-FiltersBlockNarrow-map-list
    #filters-page-block-filter-block-narrow-top-buttons-block {
    width: calc(33% - 16px);
  }
}

@media screen and (min-width: 769px) {
  .new-FiltersBlockNarrow-map-map {
    left: 50%;
    transform: translateX(-50%);
    -webkit-transform: translateX(-50%) !important;
    width: 370px;
    /* padding-left: 16px; */
    background-color: var(--white);
  }

  .new-FiltersBlockNarrow-map-map
    #filters-page-block-filter-block-narrow-top-buttons-block {
    width: 370px;
  }
}

@media screen and (min-width: 1440px) and (max-width: 2061px) {
  #new-wrapper-top .sorting-block-header-wrapper-block-1-list {
    width: calc(25% - 12px);
  }

  #new-wrapper-top .sorting-block-header-wrapper-block-2 {
    width: calc(75% - 6px);
  }

  .new-FiltersBlockNarrow-map-list {
    left: 0px;
    width: 25%;
    padding-left: 16px;
    background-color: var(--white);
  }

  .LocationPopup-wrapper,
  .TypePopup-wrapper,
  .PricePopup-wrapper,
  .SpecsPopup-wrapper {
    width: calc(25% - 20px);
  }

  .new-FiltersBlockNarrow-map-list
    #filters-page-block-filter-block-narrow-top-buttons-block {
    width: calc(25% - 16px);
  }
}

@media screen and (min-width: 2062px) and (max-width: 3029px) {
  #new-wrapper-top .sorting-block-header-wrapper-block-1-list {
    width: calc(20% - 12px);
  }

  #new-wrapper-top .sorting-block-header-wrapper-block-2 {
    width: calc(80% - 8px);
  }

  .new-FiltersBlockNarrow-map-list {
    left: 0px;
    width: 20%;
    padding-left: 16px;
    background-color: var(--white);
  }

  .LocationPopup-wrapper,
  .TypePopup-wrapper,
  .PricePopup-wrapper,
  .SpecsPopup-wrapper {
    width: calc(20% - 20px);
  }

  .new-FiltersBlockNarrow-map-list
    #filters-page-block-filter-block-narrow-top-buttons-block {
    width: calc(20% - 16px);
  }
}

@media screen and (min-width: 3030px) and (max-width: 3790px) {
  #new-wrapper-top .sorting-block-header-wrapper-block-1-list {
    width: calc(16.67% - 12px);
  }

  #new-wrapper-top .sorting-block-header-wrapper-block-2 {
    width: calc(83.33% - 8px);
  }

  .new-FiltersBlockNarrow-map-list {
    left: 0px;
    width: 16.67%;
    padding-left: 16px;
    background-color: var(--white);
  }

  .LocationPopup-wrapper,
  .TypePopup-wrapper,
  .PricePopup-wrapper,
  .SpecsPopup-wrapper {
    width: calc(16.67% - 20px);
  }

  .new-FiltersBlockNarrow-map-list
    #filters-page-block-filter-block-narrow-top-buttons-block {
    width: calc(16.67% - 16px);
  }
}

@media screen and (min-width: 3791px) and (max-width: 4450px) {
  #new-wrapper-top .sorting-block-header-wrapper-block-1-list {
    width: calc(14.3% - 16px);
  }

  #new-wrapper-top .sorting-block-header-wrapper-block-2 {
    width: calc(85.7% - 8px);
  }

  .new-FiltersBlockNarrow-map-list {
    left: 0px;
    width: 14.3%;
    padding-left: 16px;
    background-color: var(--white);
  }

  .LocationPopup-wrapper,
  .TypePopup-wrapper,
  .PricePopup-wrapper,
  .SpecsPopup-wrapper {
    width: calc(14.3% - 20px);
  }

  .new-FiltersBlockNarrow-map-list
    #filters-page-block-filter-block-narrow-top-buttons-block {
    width: calc(14.3% - 16px);
  }
}

@media screen and (min-width: 4451px) and (max-width: 5305px) {
  #new-wrapper-top .sorting-block-header-wrapper-block-1-list {
    width: calc(12.5% - 12px);
  }

  #new-wrapper-top .sorting-block-header-wrapper-block-2 {
    width: calc(87.5% - 12px);
  }

  .new-FiltersBlockNarrow-map-list {
    left: 0px;
    width: 12.5%;
    padding-left: 16px;
    background-color: var(--white);
  }

  .LocationPopup-wrapper,
  .TypePopup-wrapper,
  .PricePopup-wrapper,
  .SpecsPopup-wrapper {
    width: calc(12.5% - 20px);
  }

  .new-FiltersBlockNarrow-map-list
    #filters-page-block-filter-block-narrow-top-buttons-block {
    width: calc(12.5% - 16px);
  }
}

@media screen and (min-width: 5306px) and (max-width: 6066px) {
  #new-wrapper-top .sorting-block-header-wrapper-block-1-list {
    width: calc(11.1% - 12px);
  }

  #new-wrapper-top .sorting-block-header-wrapper-block-2 {
    width: calc(88.9% - 8px);
  }

  .new-FiltersBlockNarrow-map-list {
    left: 0px;
    width: 11.1%;
    padding-left: 16px;
    background-color: var(--white);
  }

  .LocationPopup-wrapper,
  .TypePopup-wrapper,
  .PricePopup-wrapper,
  .SpecsPopup-wrapper {
    width: calc(11.1% - 20px);
  }

  .new-FiltersBlockNarrow-map-list
    #filters-page-block-filter-block-narrow-top-buttons-block {
    width: calc(11.1% - 16px);
  }
}

@media screen and (min-width: 6067px) {
  #new-wrapper-top .sorting-block-header-wrapper-block-1-list {
    width: calc(10% - 16px);
  }

  #new-wrapper-top .sorting-block-header-wrapper-block-2 {
    width: calc(90% - 16px);
  }

  .new-FiltersBlockNarrow-map-list {
    left: 0px;
    width: 10%;
    padding-left: 16px;
    background-color: var(--white);
  }

  .LocationPopup-wrapper,
  .TypePopup-wrapper,
  .PricePopup-wrapper,
  .SpecsPopup-wrapper {
    width: calc(10% - 20px);
  }

  .new-FiltersBlockNarrow-map-list
    #filters-page-block-filter-block-narrow-top-buttons-block {
    width: calc(10% - 16px);
  }
}

@media screen and (min-width: 769px) {
  .new-bottomHeaderPaginationComponent-wrapper-list {
    display: none;
  }

  .new-wrapper-top-list {
    height: 88px !important;
  }

  #new-wrapper-top .sorting-block-header-wrapper-block-2 #sorting-block-icon-2,
  #new-wrapper-top
    .sorting-block-header-wrapper-block-2
    #sorting-block-icon-1_5,
  #new-wrapper-top .sorting-block-header-wrapper-block-2 #sorting-block-icon-1 {
    display: flex !important;
  }

  /* #new-wrapper-top .sorting-block-header-wrapper-block-2 #sorting-block-icon-3 {
    display: none;
  } */

  #new-wrapper-top
    .sorting-block-header-wrapper-block-2
    .sorting-block-icon-map-list {
    margin-left: 0 !important;
    position: absolute;
    /* left: calc(50% - 105px); */
    left: 16px;
  }

  #new-wrapper-top .sorting-block-header-wrapper-block-2 {
    /* flex-direction: row-reverse; */
    /* width: calc(67% - 12px); */
    justify-content: flex-end;
    position: relative;
  }

  #new-wrapper-top
    .sorting-block-header-wrapper-block-2
    .new-sorting-block-btn-wrapper {
    margin: 0;
    /* width: 50%; */
  }

  #new-wrapper-top
    .sorting-block-header-wrapper-block-2
    #filters-page-house-cards-block-wrapper-displaying-listings {
    margin: 0;
    /* position: absolute;
    top: 18px;
    left: 16px; */
  }

  /* .new-wrapper-top-list .sorting-block-icon-map-list {
    position: relative !important;
    left: 0 !important;
  } */
}

.new-wrapper-right-both {
  width: calc(60% + 16px) !important;
}

/* .new-wrapper-left-both {
  width: 40% !important;
} */

/* @media screen and (min-width: 769px) and (max-width: 1279px) {

} */

@media screen and (max-width: 768px) {
  .new-wrapper-left-both {
    width: 100% !important;
  }

  .new-wrapper-right {
    width: 100% !important;
  }

  /* #new-wrapper-left {
        display: none !important;
    } */

  .new-wrapper-left-both {
    display: flex !important;
    width: 100%;
    position: fixed;
    z-index: 50;
    height: 40svh !important;
  }

  #new-wrapper {
    flex-direction: column;
  }

  .new-wrapper-top {
    top: calc(40svh + 77px) !important;
    /* height: 126px !important; */
    position: relative;
  }

  .new-wrapper-left-800-map .filters-page-map-box {
    height: calc(100svh - 178px) !important;
  }

  .new-wrapper-left-both .filters-page-map-box {
    height: 40svh !important;
    top: 0px;
    display: block;
  }

  .new-FiltersBlockNarrow-map {
    width: 100vw !important;
    left: 0 !important;
  }

  .new-FiltersBlockNarrow-map
    #filters-page-block-filter-block-narrow-top-buttons-block {
    width: 100vw !important;
  }

  /* #new-wrapper-main {
        margin-top: calc(40svh + 32px);
    } */

  .new-wrapper-left-both {
    margin-top: 0 !important;
  }

  /* #new-wrapper-main #filters-page-house-cards-block {
        display: none;
    } */

  /* both view */
  .filters-page-house-card-wrapper {
    width: 100% !important;
    /* height: calc(0.85*398px); */
    height: calc((100vw - 32px) * 0.666666) !important;
  }

  .filters-page-house-card-note-content-popup {
    height: calc((100vw - 208px) * 0.666666) !important;
  }

  .filters-page-house-card-wrapper .filters-page-house-card-img-container {
    /* height: calc(0.85*398px); */
    height: calc((100vw - 32px) * 0.666666) !important;
  }

  .new-wrapper-right-both {
    width: 100% !important;
  }

  .MainHeaderLayer-MainHeader-content-TripleSwitcherSliderButton {
    display: none !important;
  }
}

/* .filters-page-house-card-note-content-popup-list {
    height: calc((100vw - 398px)/2*0.666666)!important;;
} */

@media screen and (min-width: 769px) and (max-width: 800px) {
  .filters-page-house-card-note-content-popup-list {
    height: calc(35.6666vw - 138.66653px) !important;
  }
  .new-sorting-block-btn-wrapper-list #sorting-block-icon-3 {
    margin-left: 0 !important;
  }
}

/* @media screen and (min-width: 769px) and (max-width: 799px) { */
@media screen and (min-width: 300px) {
  .sorting-block-icon-2_5-our-listings-content {
    display: none;
  }

  .new-sorting-block-btn-wrapper-list #sorting-block-icon-2_5 {
    margin-left: auto;
    width: 38px;
  }

  .new-sorting-block-btn-wrapper-list .sorting-block-icon-2_5-our-listings {
    font-size: 14px;
    width: 38px;
    padding: 0 0px;
    text-align: center;
    margin-left: auto;
  }

  .new-sorting-block-btn-wrapper-list
    #sorting-block-icon-2_5:hover
    .sorting-block-icon-2_5-tooltip {
    display: flex;
    left: -20px;
  }

  .new-sorting-block-btn-wrapper-list
    #sorting-block-icon-2_5:hover
    .sorting-block-icon-2_5-tooltip-all {
    display: flex;
    left: -17px;
  }
}

@media screen and (min-width: 800px) and (max-width: 1199px) {
  .new-sorting-block-btn-wrapper-list #sorting-block-icon-2_5 {
    width: 38px;
    max-width: 38px;
    margin-left: auto;
  }
  .new-sorting-block-btn-wrapper-list #sorting-block-icon-3 {
    margin-left: 0 !important;
  }
  .new-sorting-block-btn-wrapper-list .sorting-block-icon-2_5-our-listings {
    font-size: 14px;
    width: 38px;
    /* padding: 0 6px 0 0; */
    text-align: center;
  }
}

@media screen and (min-width: 769px) {
  /* .new-sorting-block-btn-wrapper-list #sorting-block-icon-2_5 {
    width: 108px;
    max-width: 108px;
  }

  .new-sorting-block-btn-wrapper-list .sorting-block-icon-2_5-our-listings {
    font-size: 14px;
    width: 108px;
    padding: 0 6px 0 0;
    text-align: center;
  } */

  .new-wrapper-top-list
    #filters-page-house-cards-block-wrapper-displaying-listings {
    margin: 0 auto !important;
    padding-left: 105px;
  }

  #sorting-block-input-wrapper {
    position: relative;
  }
}

/* @media screen and (max-width: 426px) {
  .new-sorting-block-btn-wrapper-list
    #sorting-block-icon-2_5:hover
    .sorting-block-icon-2_5-tooltip {
    display: flex;
    left: -15px;
  }
}

@media screen and (min-width: 481px) and (max-width: 864px) {
  .new-sorting-block-btn-wrapper-list
    #sorting-block-icon-2_5:hover
    .sorting-block-icon-2_5-tooltip {
    display: flex;
    left: -15px;
  }
} */

@media screen and (max-width: 425px) {
  .new-sorting-block-btn-wrapper-both
    #sorting-block-icon-2_5:hover
    .sorting-block-icon-2_5-tooltip {
    display: flex;
    left: -20px;
  }

  .new-sorting-block-btn-wrapper-both
    #sorting-block-icon-2_5:hover
    .sorting-block-icon-2_5-tooltip-all {
    display: flex;
    left: -17px;
  }
}

@media screen and (min-width: 1080px) and (max-width: 1700px) {
  .new-sorting-block-btn-wrapper-both
    #sorting-block-icon-2_5:hover
    .sorting-block-icon-2_5-tooltip {
    display: flex;
    left: -20px;
  }
  .new-sorting-block-btn-wrapper-both
    #sorting-block-icon-2_5:hover
    .sorting-block-icon-2_5-tooltip-all {
    display: flex;
    left: -17px;
  }
}

@media screen and (max-width: 434px) {
  .new-sorting-block-btn-wrapper-both
    .sorting-block-icon-2_5-our-listings-content {
    display: none;
  }

  .new-sorting-block-btn-wrapper-both .sorting-block-icon-2_5-our-listings {
    font-size: 14px;
    width: 38px;
    padding: 0 0px;
    text-align: center;
    margin-left: auto;
  }

  .new-sorting-block-btn-wrapper-both #sorting-block-icon-2_5 {
    margin-left: auto;
    width: 38px;
  }
}

@media screen and (min-width: 435px) and (max-width: 1279px) {
  .new-sorting-block-btn-wrapper-both #sorting-block-icon-2_5 {
    width: 104px;
    max-width: 104px;
  }

  .new-sorting-block-btn-wrapper-both .sorting-block-icon-2_5-our-listings {
    font-size: 14px;
    width: 104px;
    padding: 0 6px 0 0;
    text-align: center;
  }

  .new-sorting-block-btn-wrapper-both
    .sorting-block-icon-2_5-our-listings-content {
    display: flex;
  }
}

@media screen and (min-width: 1080px) and (max-width: 1509px) {
  .new-sorting-block-btn-wrapper-both .sorting-block-icon-2_5-our-listings {
    font-size: 14px;
    width: 38px;
    padding: 0 0px;
    text-align: center;
    margin-left: auto;
  }

  .new-sorting-block-btn-wrapper-both
    .sorting-block-icon-2_5-our-listings-content {
    display: none;
  }
  .new-sorting-block-btn-wrapper-both #sorting-block-icon-3 {
    margin-left: 0 !important;
  }

  .new-sorting-block-btn-wrapper-both #sorting-block-icon-2_5 {
    margin-left: auto;
    width: 38px;
  }
}

@media screen and (min-width: 1510px) {
  .new-sorting-block-btn-wrapper-both #sorting-block-icon-2_5 {
    width: 38px;
    max-width: 38px;
  }

  /* .new-sorting-block-btn-wrapper-both .sorting-block-icon-2_5-our-listings {
    font-size: 14px;
    width: 108px;
    padding: 0 6px 0 0;
    text-align: center;
  } */
  .new-sorting-block-btn-wrapper-both #sorting-block-icon-3 {
    margin-left: 0 !important;
  }
}

@media screen and (max-width: 1199px) {
  .new-filters-page-house-cards-block-wrapper-displaying-listings-span {
    display: none;
  }
}
