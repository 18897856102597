.rt-share-container {
    position: relative;
    display: inline-block;
  }

  .rt-share-button {
    border-radius: 4px;
    /* background-color: var(var(--third-bg)); */
    background-color: transparent;
    color: #fff;
    border: none;
    cursor: pointer;
    padding: 0px 2px;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .rt-share-dropdown {
    position: absolute;
    background-color: #f9f9f9;
    border-radius: 10px;
    box-shadow: 4px 0 30px 0 rgba(0,0,0,.25);
    flex-direction: column;
    gap: 16px;
    padding: 8px 8px;
    top: 36px;
    right: -4px;
    z-index: 3;
    cursor: initial;
  }

  .rt-share-dropdown button {
    display: block;
    width: 100%;
    padding: 10px;
    border: none;
    background: none;
    text-align: left;
  }

  .rt-share-dropdown button:hover {
    background-color: #e0e0e0;
    border-radius: 10px;
    cursor: pointer
  }
