#filters-block-filter-home-features-wrapper {
  display: flex;
  flex-direction: column;
  gap: 24px;
  -moz-transition: height 0.6s ease;
  -webkit-transition: height 0.6s ease;
  -o-transition: height 0.6s ease;
  transition: height 0.6s ease;
  height: 1079px;
}

#filters-block-filter-home-features-wrapper
  .filters-page-base-input-list-wrapper
  ul {
  background-color: var(--white) !important;
}

/* CHECK_BOX BLOCK STYLE */

#filters-block-filter-home-features {
  border: 1px solid var(--gray);
  background-color: var(--third-bg);
  padding: 24px;
  display: flex;
  flex-direction: column;
  border-bottom: none;
}

.collapsed-home-features {
  height: 80px !important;
  overflow: hidden;
}

#filters-block-filter-home-features-title {
  display: flex;
  justify-content: space-between;
  cursor: pointer;
}

.filters-block-filter-home-features-title-icon {
  display: flex;
  align-items: center;
}

#filters-block-filter-home-features .collapsed-home-features-content {
  overflow: hidden !important;
  opacity: 0 !important;
}

.filters-block-filter-home-features-content-wrapper {
  -moz-transition: opacity 0.6s ease;
  -webkit-transition: opacity 0.6s ease;
  -o-transition: opacity 0.6s ease;
  transition: opacity 0.6s ease;
  opacity: 1;
}

#filters-block-filter-home-features-title h4 {
  color: var(--main-bg);
  font-size: 26px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

#filters-block-filter-home-features-content {
  display: flex;
  flex-direction: column;
  gap: 12px;
}

.filters-block-filter-home-features-content-checkbox {
  display: flex;
  gap: 24px;
  height: 24px;
  align-items: center;
}

.filters-block-filter-bath-levels-beds-input-list-block {
  display: flex;
  flex-direction: column;
  gap: 12px;
}

.filters-block-filter-bath-levels-beds-input-list-block-div-row {
  display: flex;
  height: 50px;
  gap: 12px;
}

.filters-block-filter-bath-levels-beds-input-list-block-div {
  width: 50%;
}

.filters-block-filter-bath-levels-beds-input-list-block-icon-block {
  display: flex;
  gap: 12px;
  align-items: center;
}

.filters-block-filter-details-input-list-block-title {
  width: 50%;
}

.filters-block-filter-min-max-Price-list-block-content div {
  margin: auto 0;
}

.filters-block-filter-min-max-Price-input-list-block p,
.filters-block-filter-details-input-list-block-title p,
.filters-block-filter-bath-levels-beds-input-list-block p,
.filters-block-filter-home-features-content-checkbox p {
  color: var(--second-bg);
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: 0.32px;
}

.filters-block-filter-details-input-list-block-price-values {
  color: var(--second-bg);
  opacity: 0.7;
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: 0.28px;
}

#filters-block-filter-home-features-content-checkbox-3,
#filters-block-filter-home-features-content-checkbox-1,
#filters-block-filter-home-features-content-checkbox-2 {
  height: 28px;
  cursor: pointer;
}

/* DETAIL BLOCK STYLE */
#filters-block-filter-details {
  display: flex;
  flex-direction: column;
  gap: 12px;
}

#filters-block-filter-details-2,
#filters-block-filter-details-1 {
  display: flex;
  flex-direction: column;
  gap: 12px;
}

.filters-block-filter-details-input-list-block {
  display: flex;
  flex-direction: column;
  gap: 12px;
}

.filters-block-filter-min-max-Price-input-list-block {
  display: flex;
  gap: 12px;
}

.filters-block-filter-min-max-Price-addedMarginTop65 {
  margin-top: 65px;
}

.filters-block-filter-min-max-Price-list-block-content {
  display: flex;
  gap: 12px;
  width: 100%;
}

.filters-block-filter-min-max-Price-input-list-block
  .filters-block-filter-details-input-list-block-input {
  width: 100%;
}

.filters-block-filter-min-max-Price-list-block-content ul {
  width: 100%;
}

.filters-block-filter-details-input-list-block p {
  color: var(--second-bg);
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: 0.32px;
}

.filters-block-filter-min-max-Price-input-list-block-content,
.filters-block-filter-details-input-list-block-content {
  display: flex;
  gap: 12px;
  align-items: center;
}

/* input value text */
/* .filters-block-filter-min-max-Price-input-list-block .filters-page-base-input-list-wrapper:last-child input {
  color: var(--second-bg);
  opacity: 0.7;
} */

.filters-block-filter-details-input-list-block-input-blue-color {
  color: var(--blue) !important;
}

.filters-block-filter-details-input-list-block-input-gray-color {
  color: #141313b3;
}

.filters-block-filter-details-input-list-block-input-gray-color p {
  opacity: 0.7;
}

.filters-block-filter-details-input-list-block-input {
  width: 50%;
  height: 50px;
}

.filters-block-filter-details-input-list-block-content ul {
  width: 100%;
}

/* Scrollbar styles */

/* .filters-block-filter-filter-location-content ul {
  scrollbar-width: thin;
  scrollbar-color: #4d7fff #ddd;
} */

.filters-block-filter-filter-location-content ul::-webkit-scrollbar {
  /* width: 10px;
  height: 10px; */
  /* border-bottom-right-radius: 10px; */
}

.filters-block-filter-filter-location-content ul::-webkit-scrollbar-thumb {
  /* background: linear-gradient(to bottom right, #4d7fff 0%, #1a56ff 100%); */
  /* border-radius: 10px; */
}

.filters-block-filter-filter-location-content ul::-webkit-scrollbar-track {
  /* background-color: #ddd; */
  /* border: 1px solid #ccc;
  border-radius: 10px; */
}

/* .filters-block-filter-filter-location-content ul::-webkit-scrollbar-button {
  background-color: #4d7fff;
  border-radius: 10px;
} */

/* HORISONTAL SLIDER STYLE */

.horizontal-slider {
  width: 100%;
  margin-bottom: 36px;
  /* max-width: 368px; */
  max-width: 100%;
}

.example-thumb {
  cursor: pointer;
  position: absolute;
  z-index: 100;
  background: #ffffff;
  border: 8px solid var(--blue);
  border-radius: 100%;
  display: block;
}

.example-thumb.active {
  background-color: grey;
}

.example-track {
  position: relative;
  background: #ddd;
}

.example-track.example-track-2,
.example-track.example-track-0 {
  background: rgba(144, 143, 143, 0.4);
}

.example-track.example-track-1 {
  background: var(--blue);
}

.example-track.example-track-0 {
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
}

.example-track.example-track-2 {
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;
}

.horizontal-slider .example-track {
  top: 20px;
  height: 4px;
}

.horizontal-slider .example-thumb {
  top: 14px;
  width: 10px;
  outline: none;
  height: 10px;
  line-height: 38px;
}

.filters-block-filter-home-features-content-info-icon-box {
  margin-left: -20px;
  position: relative;
}

.filters-block-filter-home-features-content-info-icon {
  cursor: pointer;
}

.filters-block-filter-home-features-content-info-icon-tooltip {
  right: -71px;
  position: absolute;
  bottom: 28px;
  width: 160px;
}
