#filters-block-filter-timeframe {
  border: 1px solid var(--gray);
  /* border-top-left-radius: 10px;
  border-top-right-radius: 10px; */
  background-color: var(--third-bg);
  padding: 24px;
  display: flex;
  flex-direction: column;
  gap: 24px;
  -moz-transition: height 0.4s ease;
  -webkit-transition: height 0.4s ease;
  -o-transition: height 0.4s ease;
  transition: height 0.4s ease;
  height: 201px;
}

#filters-block-filter-timeframe-title {
  display: flex;
  justify-content: space-between;
  cursor: pointer;
}

.filters-block-filter-timeframe-title-icon {
  display: flex;
  align-items: center;
}

#filters-block-filter-timeframe-title h4{
  color: var(--main-bg);
  font-size: 26px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

.collapsed-filter-timeframe{
  height: 80px !important; 
  overflow: hidden; 
}

#filters-block-filter-timeframe-content {
  -moz-transition: opacity 0.8s ease;
  -webkit-transition: opacity 0.8s ease;
  -o-transition: opacity 0.8s ease;
  transition: opacity 0.8s ease;
}

#filters-block-filter-timeframe .collapsed-filter-timeframe-content {
  overflow: hidden !important; 
  opacity:0 !important;
}

#filters-block-filter-timeframe-content {
  display: flex;
  flex-direction: column;
  gap: 12px;
}

.filters-block-filter-timeframe-content-checkbox {
  display: flex;
  gap: 24px;
  height: 24px;
  align-items: center;
}

.filters-block-filter-timeframe-content-checkbox p {
  color: var(--second-bg);
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: 0.32px;
}

#filters-block-filter-timeframe-content-checkbox-1,
#filters-block-filter-timeframe-content-checkbox-2,
#filters-block-filter-timeframe-content-checkbox-3,
#filters-block-filter-timeframe-content-checkbox-4 {
  height: 28px;
  cursor: pointer;
}

