.main-container input[type="radio"] {
  display: none;
}

.main-container label {
  color: var(--main-bg);
  /* z-index: 10; */
  cursor: pointer;
}

.main-container p {
  margin: 0;
  /* font-weight: 500; */
  font-size: 14px;
}

.main-container .left-label {
  padding-left: 13px;
}

.main-container .center-label {
  padding-left: 20px;
}

.main-container .right-label {
  padding-left: 18px;
}

.black-font {
  color: var(--main-bg);
}

.main-container-wrapper {
  position: relative;
  width: 210px;
}

.main-container {
  display: inline-block;
  vertical-align: middle;
  width: 210px;
  height: 30px;
  background-color: #ffffff;
  /* position: absolute; */
  /* top: 0;
    right: 0; */
  /* box-shadow: 1px 1px 10px 1px rgba(0, 0, 0, 0.5); */
  background-color: var(--third-bg);
  border-radius: 8px;
}

.switch {
  height: 24px;
  width: 68px;
  background-color: var(--white);
  border: 1px solid var(--main-bg);
  border-radius: 25px;
  position: absolute;
  left: 3px;
  top: 3px;
  transition: ease-in-out;
}

/*---------------- LEFT ------------------- */
.switch.left-to-center {
  animation-name: leftToCenter;
  animation-duration: 0.5s;
}

.switch.left-to-right {
  animation-name: leftToRight;
  animation-duration: 0.5s;
}

.switch.left-position {
  left: 3px;
}

.left-label {
  position: absolute;
}

#left + label {
  display: flex;
  align-items: center;
  /* justify-content: left; */
  height: 30px;
  width: 68px;
  border-radius: 100px;
}

#left:checked + label {
  display: flex;
  align-items: center;
  /* justify-content: left; */
  height: 30px;
  width: 68px;
  border-radius: 100px;
}

@keyframes leftToCenter {
  from {
    left: 3px;
  }

  to {
    left: 71px;
  }
}

@keyframes leftToRight {
  from {
    left: 3px;
  }

  to {
    left: 140px;
  }
}

/* -------------------- center ------------------ */
.switch.center-to-left {
  animation-name: centerToLeft;
  animation-duration: 0.5s;
}

.switch.center-to-right {
  animation-name: centerToRight;
  animation-duration: 0.5s;
}

.switch.center-position {
  left: 71px;
}

.center-label {
  position: absolute;
  left: 71px;
}

#center + label {
  display: flex;
  align-items: center;
  /* justify-content: center; */
  height: 30px;
  width: 68px;
  border-radius: 100px;
}

#center:checked + label {
  display: flex;
  align-items: center;
  /* justify-content: center; */
  height: 30px;
  width: 68px;
  border-radius: 100px;
}

@media screen and (max-width: 1079px) {
  .main-container {
    width: 142px;
  }

  .sorting-block-icon-map-list-map-view .main-container {
    width: 142px;
  }

  .main-container-wrapper {
    position: relative;
    width: 142px;
  }

  .main-container .center-label {
    padding-left: 22px;
  }
}

@keyframes centerToLeft {
  from {
    left: 71px;
  }

  to {
    left: 3px;
  }
}

@keyframes centerToRight {
  from {
    left: 71px;
  }

  to {
    right: 1px;
  }
}

/* ------------------- RIGHT ------------------- */
.switch.right-to-left {
  animation-name: rightToLeft;
  animation-duration: 0.5s;
}

.switch.right-to-center {
  animation-name: rightToCenter;
  animation-duration: 0.5s;
}

.switch.right-position {
  left: 140px;
}

.right-label {
  position: absolute;
  right: 2px;
}
.right-label-disable {
  cursor: initial !important;
}
.right-label-disable p {
  color: var(--gray);
}

#right + label {
  display: flex;
  align-items: center;
  /* justify-content: right; */
  height: 30px;
  width: 68px;
  border-radius: 100px;
}

#right:checked + label {
  display: flex;
  align-items: center;
  /* justify-content: right; */
  height: 30px;
  width: 68px;
  border-radius: 100px;
}

@keyframes rightToLeft {
  from {
    left: 140px;
  }

  to {
    left: 3px;
  }
}

@keyframes rightToCenter {
  from {
    left: 140px;
  }

  to {
    right: 71px;
  }
}
