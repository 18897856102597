.filters-page-base-active-input-list-wrapper {
  height: 100%;
  width: 100%;
  position: relative;
}

.filters-page-base-active-input-list-end-icon-wrapper {
  height: 22px;
  position: absolute;
  right: 24px;
  top: 50%;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
}

.filters-page-base-active-input-list-wrapper input {
  border-radius: 10px;
  border: 1px solid var(--gray);
  cursor: pointer;
  height: 100%;
  color: var(--blue);
}

.filters-page-base-active-input-list-wrapper
  input:is(:focus, :active, :visited, :focus-visible) {
  border-color: var(--gray) !important;
  outline: none !important;
  box-shadow: none;
}

.filters-page-base-active-input-list-wrapper input:read-only {
  background-color: var(--white);
}

.filters-page-base-active-input-list-wrapper input::-webkit-input-placeholder {
  color: var(--main-bg);
}

.filters-page-base-active-input-list-wrapper input:-ms-input-placeholder {
  color: var(--main-bg);
}

.filters-page-base-active-input-list-wrapper input::placeholder {
  color: var(--main-bg);
}

/* LIST STyle */

.filters-page-base-active-input-list-wrapper ul {
  position: absolute !important;
  list-style-type: none;
  top: 54px;
  padding: 0;
  margin: 0;
  z-index: 5;
  width: 550px;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
  border: 1px solid var(--gray);
  background-color: var(--white);
  /* display: flex; */
  flex-direction: column;
  gap: 4px;
  box-sizing: border-box;
  max-height: 375px;
  overflow-y: auto;
  display: none;
  max-height: 258px;
}

.filters-page-base-active-input-list-wrapper svg {
  cursor: pointer;
}

.filters-page-base-active-input-list-wrapper ul li:last-child p {
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
}

.filters-page-base-active-input-list-wrapper ul > li > p {
  background-color: var(--white);
  padding: 12px;
  /* Add some padding */
  text-decoration: none;
  /* Remove default text underline */
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  font-family: inter;
  color: var(--main-bg);
  margin-bottom: 0;
}

.filters-page-base-active-input-list-wrapper ul li p:hover {
  background-color: var(--blue);
  color: var(--body-text);
  cursor: pointer;
}

.filters-page-base-active-input-list-wrapper ul .loader {
  height: 30px;
  width: 30px;
}

.filters-page-base-active-input-list-wrapper
  .filters-page-base-active-input-value-close-icon {
  height: 22px;
  position: absolute;
  right: 8px;
  top: calc(50% + 1px);
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
  cursor: pointer;
}

.filters-page-base-active-input-list-wrapper
  .filters-page-base-active-input-value-close-icon:hover {
  opacity: 0.7;
}
