/* //BA  ---> */

.house-card-BA-component-tooltip-wrapper {
    position: relative;
}

.house-card-BA-component {
    /* position: absolute;
  top: 52px;
  left: 16px; */
    display: flex;
    width: calc(100% - 32px);
    /* height: 30px; */
    align-items: center;
    z-index: 5;
    cursor: pointer;
}

.house-card-BA-component-content {
    background: var(--blue);
    padding: 6px;
    border-radius: 4px;
    display: flex;
    align-items: center;
    height: 26px;
}

.house-card-BA-component-inner {
    position: relative;
}

.house-card-BA-component-tooltip p {
    font-size: 12px;
    color: var(--white)
}

.house-card-BA-component-tooltip {
    position: absolute;
    top: 36px;
    left: 0px;
    width: 200px;
    background-color: var(--main-bg);
    border: 1px solid var(--gray);
    border-radius: 8px;
    height: 48px;
    z-index: 50;
}

.house-card-BA-component-tooltip-triangle {
    position: absolute;
    top: 31px;
    left: calc(50% - 4px);
    width: 10px;
    height: 10px;
    transform: rotate(45deg);
    -webkit-transform: rotate(45deg);
    background: var(--main-bg);
    z-index: 50;
    border-top: 1px solid var(--gray);
    border-left: 1px solid var(--gray);
}

.house-card-BA-component-tooltip p {
    line-height: 18px;
    padding: 6px;
}

.house-card-BA-component p {
    font-size: 12px;
    color: var(--white)
}

/* //BA <----- */
