.map-view-open #filters-page-map-wrapper {
  position: absolute;
  top: 0;
  bottom: 0;
  height: calc(100svh - 178px);
  width: 100svw;
  z-index: 103;
}

.map-view-open {
  position: fixed !important;
}

.filters-page-map-spiner-wrapper {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  -webkit-transform: translate(-50%, -50%);
  z-index: 1051;
}

#filters-page-map-wrapper {
  /* height: 488px; */
  /* display: none; */
}

.filters-page-map-box {
  height: calc(100svh - 178px);
  width: 100%;
  position: relative;
}

.filters-page-map-overlays-alarm-wrapper {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  -webkit-transform: translate(-50%, -50%);
  background-color: var(--main-bg);
  border-radius: 10px;
  padding: 10px 15px;
  z-index: 1055;
  border: 1px solid var(--gray);
  width: 206px;
  color: var(--third-bg);
  font-weight: 400;
  font-size: 14px;
  line-height: 16.94px;
  flex-direction: column;
  gap: 12px;
}

.filters-page-map-overlays-alarm-wrapper-title {
  font-weight: 600;
  font-size: 16px;
  text-align: center;
  margin-top: 10px;
  position: relative;
}

.filters-page-map-overlays-alarm-wrapper-close {
  position: absolute;
  right: -4px;
  top: -8px;
  cursor: pointer;
}

.filters-page-map-overlays-alarm-wrapper-close:hover {
  opacity: 0.7;
}

.filters-page-map-overlays-alarm-wrapper-yes-no {
  display: flex;
  justify-content: space-between;
}

.filters-page-map-overlays-alarm-yes {
  border: 1px solid var(--gray);
  padding: 4px 16px;
  border-radius: 10px;
  cursor: pointer;
}

.filters-page-map-overlays-alarm-yes:hover {
  color: var(--blue);
  border: 1px solid var(--blue);
}

.filters-page-map-wrapper-close-Icon {
  position: absolute;
  top: 16px;
  right: 16px;
  z-index: 1;
  cursor: pointer;
  background: var(--main-bg);
  width: 24px;
  height: 25px;
  border-radius: 6px;
}

.sorting-block-icon-map-list-map-view-filters {
  height: 30px;
  width: 60px;
  padding: 0 16px;
  background-color: var(--main-bg);
  border-radius: 10px;
  color: var(--white);
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 14px;
  cursor: pointer;
}

.sorting-block-icon-map-list-map-view {
  position: absolute;
  bottom: 16px;
  right: 50%;
  transform: translateX(50%);
  -webkit-transform: translateX(50%);
  z-index: 51;
  display: flex;
  gap: 8px;
  /* border: 1px solid var(--main-bg);
    border-radius: 8px; */
}

.sorting-block-icon-map-list-map-view .main-container {
  background-color: var(--main-bg);
  border-radius: 10px;
  width: 210px;
}

.sorting-block-icon-map-list-map-view .main-container label {
  color: var(--white);
}

.sorting-block-icon-map-list-map-view .black-font p {
  color: var(--main-bg) !important;
}

.filters-page-map-wrapper-alert-map-message {
  position: absolute;
  top: 16px;
  left: 16px;
  z-index: 1;
}

.filters-page-map-wrapper-alert-map-message-list-pagination {
  position: absolute;
  bottom: 12px;
  left: 160px;
  z-index: 2000;
  height: 20px;
}

.filters-page-map-wrapper-alert-map-message-list-pagination input {
  color: var(--white);
  background-color: var(--main-bg) !important;
  height: 20px;
}

.filters-page-map-wrapper-alert-map-message-list-pagination li {
  text-align: center;
}

.filters-page-base-input-map-list-wrapper input {
  border: none;
  box-shadow: none;
  color: var(--body-text) !important;
  padding: 2px 8px !important;
}

.filters-page-map-marker-wrapper {
  display: flex;
  flex-direction: column;
  position: relative;
  width: 2px !important;
  height: 6px !important;
}

.filters-page-map-marker-overlay {
  left: 0px;
  bottom: 0px;
  position: absolute;
  height: 200px;
  width: 250px;
  background: linear-gradient(
    to bottom,
    #c6c6c600,
    #c6c6c61c,
    #2f2f2f4a,
    #2f2f2f5c,
    #2f2f2f85,
    #2f2f2fb5
  );
  z-index: 1;
  cursor: pointer;
}

.filters-page-map-marker-label-box {
  position: relative;
  margin: 20px 18px 18px 18px;
}

.filters-page-map-rounded-marker-label-box {
  position: relative;
  margin: 4px 4px 4px 4px;
}

.filters-page-map-rounded-marker-label-close {
  position: absolute;
  top: -206px;
  right: -128px;
  cursor: pointer;
  z-index: 10;
}

.filters-page-map-marker-label-close {
  position: absolute;
  top: 0px;
  right: 0px;
  z-index: 2;
  cursor: pointer;
}

.filters-page-map-rounded-marker-label-box,
.filters-page-map-marker-label-box {
  height: 200px;
  width: 250px;
  overflow: hidden;
  position: relative;
}

.filters-page-map-rounded-marker-label-box a,
.filters-page-map-marker-label-box a {
  text-decoration: none;
}

.filters-page-map-marker-label-image img {
  height: 200px;
  /* border-radius: 10px; */
  position: absolute;
  width: auto;
  left: 50%;
  transform: translate(-50%, 0);
  -webkit-transform: translate(-50%, 0) !important;
}

.filters-page-map-rounded-marker-label-box span,
/* .filters-page-map-rounded-marker-label-box h4, */
.filters-page-map-rounded-marker-label-box p,
.filters-page-map-marker-label-box span,
.filters-page-map-marker-label-box h4,
.filters-page-map-marker-label-box p {
  color: #fff;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 18px;
  letter-spacing: 0.28px;
}

.filters-page-map-rounded-marker-label-box h4 {
  color: #fff;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px;
  letter-spacing: 0.28px;
}

.filters-page-map-marker-label-content-box {
  position: absolute;
  bottom: 2px;
  left: 6px;
  width: calc(100% - 12px);
  z-index: 1;
  background: #2c353c75;
  padding: 4px;
  border-radius: 4px;
}

.filters-page-map-marker-label-bottom-arrow {
  position: absolute;
  bottom: 46px;
  left: calc(50% - 10px);
  transform: rotate(45deg);
  -webkit-transform: rotate(45deg);
  width: 20px;
  height: 20px;
  border-bottom: 1px solid var(--gray);
  border-right: 1px solid var(--gray);
  background: var(--third-bg);
  z-index: 5;
}

.filters-page-map-marker-label-close:hover {
  opacity: 0.7;
}

.filters-page-map-marker-label-wrapper {
  border: 1px solid var(--gray);
  display: flex;
  flex-direction: column;
  position: absolute;
  top: -290px;
  left: 50%;
  -webkit-transform: translateX(-50%);
  transform: translateX(-50%);
  border-radius: 7.498px;
  background: var(--third-bg);
  gap: 7.498px;
  justify-content: center;
  align-items: center;
  z-index: 5;
}

.filters-page-map-rounded-marker-label-wrapper {
  border: 1px solid var(--gray);
  display: flex;
  flex-direction: column;
  position: absolute;
  top: -215px;
  left: 50%;
  -webkit-transform: translateX(-50%);
  transform: translateX(-50%);
  border-radius: 7.498px;
  background: var(--third-bg);
  gap: 7.498px;
  justify-content: center;
  align-items: center;
  z-index: 5;
}

.filters-page-map-marker-label-adress p {
  color: #fff;
  text-align: center;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px;
  letter-spacing: 0.32px;
}

.filters-page-map-marker-label-post-index {
  color: #fff;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;
  letter-spacing: 0.24px;
}

.filters-page-map-marker-label-price {
  color: #fff;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px;
  letter-spacing: 0.28px;
  position: absolute;
  top: 2px;
  left: 6px;
  display: flex;
  width: calc(100% - 12px);
  background: #2c353c75;
  border-radius: 4px;
  padding: 0 4px;
}

.filters-page-map-marker-label-size {
  position: absolute;
  bottom: 4px;
  right: 8px;
  z-index: 2;
}

.filters-page-map-marker-label-size2 {
  font-size: 10px !important;
  position: absolute;
  top: 0;
  right: -6px;
}

.filters-page-map-marker-label-size-wrapper {
  position: relative;
}

.filters-page-map-marker-label-size h4 {
  line-height: 24px !important;
}

.filters-page-map-marker-icon-wrapper {
  height: 6px;
  width: 2px;
  background-color: var(--blue);
}

.filters-page-map-marker-icon-content {
  position: absolute;
  top: -30px;
  left: 50%;
  -webkit-transform: translateX(-50%);
  transform: translateX(-50%);
  border: 3px solid var(--blue);
  border-radius: 4px;
  width: 60px;
  height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: var(--body-text);
  color: var(--blue);
  font-size: 13px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px;
  letter-spacing: 0.28px;
  z-index: 1;
}

/* .filters-page-map-marker-icon-content:before {
  border: 1px solid #fff;
  bottom: -2px;
  content: "";
  left: -2px;
  position: absolute;
  right: -2px;
  top: -2px;
} */

/* googe map api styles */

.gm-style-iw-a {
  display: none !important;
}

#filters-page-map-wrapper #gmap_canvas2 img {
  max-width: none !important;
  background: none !important;
}

.gm-bundled-control-on-bottom {
  left: 8px !important;
}

/* .gmnoprint {
  right: none;
  left: 4px !important;
  bottom: 90px !important;
} */

/* /// zoom btns*/
a[href^="http://maps.google.com/maps"]
{
  display: none !important;
}

#filters-page-map-wrapper .gm-control-active img {
  filter: brightness(0) invert(1);
}

/* #filters-page-map-wrapper .gmnoprint button:hover img {
  filter: brightness(0.9) invert(1);
}

#filters-page-map-wrapper .gmnoprint a,
.gmnoprint span {
  display: none;
} */

#filters-page-map-wrapper:first-child
  .gmnoprint:first-child
  div:first-child
  div {
  /* background: red !important; */
  /* display: none !important; */
}

/* /// */

#filters-page-map-wrapper .gmnoprint div {
  background-color: rgb(255 255 255 / 0%) !important;
}

#filters-page-map-wrapper .gmnoprint button {
  background: var(--main-bg) !important;
  border-radius: 6px !important;
  margin-bottom: 2px !important;
}

#filters-page-map-wrapper .gm-fullscreen-control {
  display: none;
}

#filters-page-map-wrapper .gm-style-iw-t {
  top: -60px;
}

#map-block-label-content {
  text-align: center;
}

.map-block-label-bodyContent-p-3,
.map-block-label-bodyContent-p-2 {
  color: var(--white);
  font-family: inter;
  font-weight: 500 !important;
  font-size: 12px !important;
  line-height: 18px !important;
  text-align: center;
  margin: 0;
}

.map-block-label-bodyContent-p-1 {
  color: var(--white);
  font-family: inter;
  font-weight: 600 !important;
  font-size: 16px !important;
  line-height: 24px !important;
  text-align: center;
  margin: 0;
}

#filters-page-map-wrapper .gm-style .gm-style-iw-d::-webkit-scrollbar-track,
#filters-page-map-wrapper
  .gm-style
  .gm-style-iw-d::-webkit-scrollbar-track-piece,
#filters-page-map-wrapper .gm-style .gm-style-iw-c,
#filters-page-map-wrapper .gm-style .gm-style-iw-t::after {
  background-color: var(--main-bg) !important;
}

#filters-page-map-wrapper .gm-style-iw-tc::after {
  background-color: var(--main-bg) !important;
}

/* #filters-page-map-wrapper .gm-style-iw-t,
#filters-page-map-wrapper .gm-style-iw-tc {
  display: none;
} */

#filters-page-map-wrapper .gm-style > div > div > a > div > img {
  display: none;
}

#filters-page-map-wrapper .gm-style-cc {
  margin-bottom: 16px;
  margin-right: 6px;
}

#filters-page-map-wrapper .gm-style-cc:last-child {
  display: none !important;
}

/* a[title="Report errors in the road map or imagery to Google"] {
    display: none !important;
} */

/* google label??? */
#filters-page-map-wrapper .gm-style-iw-tc,
#filters-page-map-wrapper .gm-style-iw-c {
  display: none !important;
}

.filters-page-map-cluster-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  cursor: pointer;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  -webkit-transform: translate(-50%, -50%);
  -moz-transform: translate(-50%, -50%);
  -o-transform: translate(-50%, -50%);
  z-index: 3;
}

.filters-page-map-cluster-wrapper-no-display {
  display: none !important;
}

.filters-page-map-cluster-counter-wrapper {
  font-size: 16px;
}

/* //rounded marker */
.filters-page-map-rounded-marker-wrapper {
  position: relative;
  height: 12px;
  width: 12px;
  border-radius: 50%;
  border: 2px solid var(--white);
  background-color: var(--blue);
}

.filters-page-map-rounded-marker-wrapper-hovered:hover {
  border: 2px solid var(--red) !important;
  /* background-color: var(--white) !important;  */
  /* cursor: pointer; */
}

.filters-page-map-rounded-marker-wrapper:hover
  .filters-page-map-rounded-marker-icon-content-disabled,
.filters-page-map-rounded-marker-price-hovered {
  display: flex !important;
  z-index: 1 !important;
}

/* .filters-page-map-rounded-marker-wrapper-hovered {
    background-color: var(--red) !important;
} */

.filters-page-map-rounded-marker-icon-content-enabled {
  display: flex;
}

.filters-page-map-rounded-marker-icon-content-disabled-isCrossed {
  display: none;
}

.filters-page-map-rounded-marker-icon-content-disabled {
  display: none;
}

.filters-page-map-rounded-marker-icon-content {
  position: absolute;
  top: -35px;
  right: -25px;
  border-radius: 4px;
  width: 60px;
  height: 30px;
  justify-content: center;
  align-items: center;
  background-color: var(--body-text);
  color: var(--blue);
  border: 3px solid var(--blue);
  font-size: 13px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px;
  letter-spacing: 0.28px;
  z-index: 0;
}

.rounded-marker-price-active {
  background-color: var(--body-text) !important;
  border: 3px solid var(--blue) !important;
  z-index: 1 !important;
}

.rounded-marker-price-closed {
  background-color: var(--body-text) !important;
  border: 3px solid var(--main-bg) !important;
  z-index: 1 !important;
}

.rounded-marker-price-pending {
  background-color: var(--body-text) !important;
  border: 3px solid var(--red) !important;
  z-index: 1 !important;
}

.filters-page-map-wrapper-satellite-wrapper {
  position: absolute;
  top: 16px;
  right: 16px;
  z-index: 1;
  height: 30px;
  width: 70px;
  padding: 0 16px;
  background-color: var(--main-bg);
  border-radius: 10px;
  color: var(--white);
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 14px !important;
  cursor: pointer;
}

.filters-page-map-wrapper-satellite-wrapper-both {
  position: absolute;
  top: 80px;
  left: 200px;
  z-index: 1;
  height: 30px;
  width: 70px;
  padding: 0 16px;
  background-color: var(--main-bg);
  border-radius: 10px;
  color: var(--white);
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 14px !important;
  cursor: pointer;
}

.filters-page-map-wrapper-satellite-mobile-wrapper {
  position: absolute;
  top: 16px;
  right: 16px;
  z-index: 1;
  height: 60px;
  width: 60px;
  background-color: var(--main-bg);
  border-radius: 10px;
  -moz-transition: opacity 0.3s ease-out;
  -webkit-transition: opacity 0.3s ease-out;
  -o-transition: opacity 0.3s ease-out;
  transition: opacity 0.3s ease-out;
  opacity: 1;
  cursor: pointer;
  border: 6px solid var(--main-bg);
  border-bottom: 24px solid var(--main-bg);
}

.filters-page-map-wrapper-satellite-mobile {
  background-image: url("https://maps.gstatic.com/tactile/layerswitcher/ic_satellite-1x.png");
}

.filters-page-map-wrapper-terrain-mobile {
  background-image: url("https://maps.gstatic.com/tactile/layerswitcher/ic_default_colors2-1x.png");
}

.filters-page-map-wrapper-satellite-mobile-wrapper:active {
  opacity: 0 !important;
  overflow: hidden;
}

.filters-page-map-wrapper-satellite-mobile-text {
  font-size: 12px;
  position: absolute;
  bottom: -19px;
  left: 50%;
  color: var(--white);
  transform: translate(-50%, 0);
  -webkit-transform: translate(-50%, 0);
}

.filters-page-map-overlays-wrapper {
  position: absolute;
  top: 16px;
  right: 92px;
}

.filters-page-map-overlays-wrapper-both {
  position: absolute;
  top: 80px;
  left: 98px;
}

.filters-page-map-box-satellite .gm-tilt {
  display: none !important;
}

/* .filters-page-map-box-satellite-12Less .gmnoprint {
  bottom: 162px !important;
  position: absolute !important;
}

.filters-page-map-box-satellite-12More .gmnoprint {
  bottom: 90px !important;
  position: absolute !important;
} */

.sorting-block-icon-2_5-our-listings_black {
  height: 30px;
  width: 108px;
  padding: 0 2px 0 0;
  font-size: 14px;
  background-color: var(--main-bg);
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 8px;
  color: var(--white);
  cursor: pointer;
  gap: 4px;
}

.sorting-block-icon-2_5-our-listings-realtytexas-icon_black {
  height: 24px;
  width: 24px;
  background-image: url("https://web.realtytexas.io/assets/logos/realty-texas-logo-black5-star.png");
  background-size: cover;
  z-index: 3;
  position: relative;
  cursor: pointer;
  background-color: var(--main-bg);
}

.sorting-block-icon-2_5-our-listings-content_black {
  overflow: hidden;
  display: inline-block;
  text-overflow: ellipsis;
  white-space: nowrap;
  /* max-width: 40px; */
  line-height: 30px;
}
