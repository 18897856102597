#filters-block-wrapper {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  position: relative;
}

.filters-page-block-filter-block-propertyTypes-subTypes {
  -moz-transition: max-height 0.6s ease;
  -webkit-transition: max-height 0.6s ease;
  -o-transition: max-height 0.6s ease;
  transition: max-height 0.6s ease;
  max-height: 2000px;
}

/* #filters-block-wrapper  */

.collapsed-filter-propertyTypes-subTypes{
  max-height: 80px !important; 
  -moz-transition: max-height 0.6s ease;
  -webkit-transition: max-height 0.6s ease;
  -o-transition: max-height 0.6s ease;
  transition: max-height 0.6s ease;
  overflow: hidden; 
}