.filters-page-house-card-alert-note {
  position: absolute;
  /* height: 140px; */
  display: flex;
  justify-content: center;
  align-items: center;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  -webkit-transform: translate(-50%, -50%);
  background-color: white;
  z-index: 2;
  border-radius: 10px;
  cursor: initial;
  border: 1px solid var(--gray);
}

.filters-page-house-card-alert-note-content-paragraph {
  color: transparent;
}

.filters-page-house-card-alert-note-disabled {
  display: none;
}

.filters-page-house-card-alert-note .filters-page-house-card-alert-note-wrapper {
  position: relative;
  padding: 16px;
  height: 100%;
}

.filters-page-house-card-alert-note .filters-page-house-card-alert-note-close {
  position: absolute;
  right: 4px;
  top: 4px;
  cursor: pointer;
}
.filters-page-house-card-alert-note-content{
  text-align: left;
  display: flex;
  justify-content: center;
}

.filters-page-house-card-alert-note-content div {
  color: var(--main-bg);
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin: 10px 0;
}