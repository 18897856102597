.base-input-container {
  height: 100%;
  width: 100%;
  position: relative;
}

.base-input-block {
  height: 100%;
  width: 100%;
}

.base-input-container input {
  border-radius: 10px;
  border: 1px solid var(--gray);
  padding: 19px 24px;
}


.base-input-container input:is(:focus, :active, :visited, :focus-visible) {
  border-color: var(--gray) !important;
  outline: none!important;
  box-shadow: none;
}

.base-input-container input:read-only {
  background-color: var(--white);
}

.base-input-container input::-webkit-input-placeholder { 
  color: var(--main-bg);
}

.base-input-container input:-ms-input-placeholder {
  color: var(--main-bg);
}

.base-input-container input::placeholder {
  color: var(--main-bg);
}