#map-overlays-btn-form {
  position: relative;
  justify-content: center;
  align-items: center;
  z-index: 1;
  height: 30px;
  width: 100px;
  background-color: var(--main-bg);
  border-radius: 10px;
  color: var(--white);
  display: flex;
  font-size: 14px !important;
  cursor: pointer;
  padding-right: 20px;
}

.map-overlays-btn-title {
  line-height: 30px;
  position: relative;
}

#map-overlays-btn-form ul {
  position: absolute;
  top: 35px;
  left: 0px;
  list-style-type: none;
  z-index: 5;
  border-bottom-left-radius: 6px;
  border-bottom-right-radius: 6px;
  border: 1px solid var(--gray);
  background-color: var(--white);
  flex-direction: column;
  gap: 4px;
  box-sizing: border-box;
  max-height: 175px;
  min-height: 107px;
  overflow-y: auto;
  display: flex;
  width: 175px;
  /* display: none; */
}

.map-overlays-btn-list-li-checked p {
  color: var(--blue) !important;
}

.map-overlays-btn-list-li-checked p:hover {
  background-color: var(--light-gray) !important;
}

.map-overlays-btn-list-li-close-icon svg {
  /* fill: var(--main-bg) !important; */
  transform: rotate(45deg);
  -moz-transform: rotate(45deg);
  -webkit-transform: rotate(45deg);
  -o-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  -webkit-transform: rotate(45deg);
}

.map-overlays-btn-list-li-checked:hover svg path {
  fill: var(--blue) !important;
}

.map-overlays-btn-list-li {
  position: relative;
}

.map-overlays-btn-list-li-close-icon {
  display: none;
}

.map-overlays-btn-list-li-checked .map-overlays-btn-list-li-close-icon {
  display: block;
  position: absolute;
  top: 5px;
  right: 4px;
}

#map-overlays-btn-form ul li:last-child p {
  border-bottom-left-radius: 6px;
  /* border-bottom-right-radius: 6px; */
}

#map-overlays-btn-form ul > li > p {
  background-color: var(--white);
  padding: 4px;
  /* Add some padding */
  text-decoration: none;
  /* Remove default text underline */
  font-weight: 400;
  font-size: 14px !important;
  line-height: 19px;
  font-family: inter;
  /* color: var(--white); */
  margin-bottom: 0;
  color: var(--main-bg);
}

#map-overlays-btn-form ul li p:hover {
  background-color: var(--blue);
  color: var(--body-text);
  cursor: pointer;
  opacity: 1;
}

#map-overlays-btn-form ul::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background-color: #f2f2f2;
  border-radius: 10px;
}

#map-overlays-btn-form ul::-webkit-scrollbar {
  border-radius: 10px;
  width: 6px;
  background-color: #f2f2f2;
}

#map-overlays-btn-form ul::-webkit-scrollbar-thumb {
  background-color: var(--gray) !important;
  border-radius: 10px;
}

.map-overlays-btn-form-list-end-icon {
  height: 22px;
  position: absolute;
  right: 8px;
  top: 6px;
  cursor: pointer;
}

.map-overlays-btn-form-list-end-icon-back {
  right: -12px !important;
  top: 3px !important;
}

.map-overlays-btn-list-input-wrapper {
  width: 100%;
  padding: 2px;
}

.map-overlays-btn-list-li-0 {
  border-bottom: 1px solid var(--gray) !important;
}

.map-overlays-btn-list-sub-li-0 {
  display: flex;
  width: 100%;
  position: relative;
  border-bottom: 1px solid var(--gray) !important;
}

.map-overlays-btn-list-sub-li-0-icon {
  position: absolute;
  top: 6px;
  right: 8px;
}

.map-overlays-btn-list-sub-li-0 p {
  background-color: var(--white);
  padding: 4px;
  text-decoration: none;
  font-weight: 400;
  font-size: 14px !important;
  line-height: 19px;
  font-family: inter;
  margin-bottom: 0;
  color: var(--main-bg);
  width: 100%;
}

.map-overlays-btn-list-sub-li-0:hover path {
  fill: var(--white) !important;
}

.map-overlays-btn-list-sub-li-0 p:hover {
  background-color: var(--blue);
  color: var(--body-text);
  cursor: pointer;
  opacity: 1;
}

.map-overlays-btn-list-input-wrapper input {
  width: 100%;
  height: 25px;
  padding: 2px;
  font-size: 14px;
  font-family: Inter;
  /* border-radius: 4px; */
  border: 0px solid var(--gray) !important;
  border-bottom: 1px solid var(--gray) !important;
}

.map-overlays-btn-list-input-wrapper
  input:is(:focus, :active, :visited, :focus-visible) {
  /* border-color: var(--gray) !important; */
  outline: none !important;
  box-shadow: none;
}

.map-overlays-btn-list-input-wrapper input:read-only {
  background-color: var(--white);
}

.map-overlays-btn-list-input-wrapper input::-webkit-input-placeholder {
  color: var(--main-bg);
}

.map-overlays-btn-list-input-wrapper input:-ms-input-placeholder {
  color: var(--main-bg);
}

.map-overlays-btn-list-input-wrapper input::placeholder {
  color: var(--main-bg);
}

.map-overlays-btn-list-no-resault {
  color: var(--main-bg);
  padding: 2px;
  font-weight: 400;
  font-size: 14px !important;
  line-height: 19px;
  font-family: inter;
}

#map-overlays-btn-form .loader {
  height: 30px;
  width: 30px;
}

/* .filters-page-base-input-map-list-blue-wrapper {
    color: var(--blue) !important;
  }

  .filters-page-base-input-map-list-blue-wrapper:hover {
    background-color: var(--blue);
    color: var(--body-text)!important;;
    cursor: pointer;
    opacity: 1;
  } */
