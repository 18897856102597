@import "~slick-carousel/slick/slick.css";
@import "~slick-carousel/slick/slick-theme.css";

.filters-page-house-card-narrow-menu-label-2 {}

.filters-page-house-card-narrow-wrapper-both,
.filters-page-house-card-narrow-wrapper {
    border: 1px solid var(--gray);
    display: flex;
    flex-direction: column;
    border-radius: 10px;
    padding: 0px;
    width: calc(50% - 8px);
    background-color: var(--third-bg);
    position: relative;
}

.filters-page-house-card-narrow-wrapper-active {
    border: 1px solid var(--gray);
    display: flex;
    flex-direction: row;
    border-radius: 10px;
    padding: 0px;
    gap: 40px;
    width: 100%;
    background-color: var(--third-bg);
    position: relative;
}

.filters-page-house-card-narrow-content-overlay {
    left: 15px;
    right: 0;
    bottom: 0px;
    position: absolute;
    height: calc(100% - 16px);
    width: calc(100% - 16px);
    background: linear-gradient(to bottom, #c6c6c600, #c6c6c61c, #2f2f2f4a, #2f2f2f5c, #2f2f2f85, #2f2f2fb5);
    border-radius: 10px;
    z-index: 1;
}

.filters-page-house-card-narrow-img {
    height: 249px;
    ;
    border-radius: 10px;
    position: relative;
    width: 100%;
}

.filters-page-house-card-narrow-img-active {
    height: 249px;
    ;
    border-radius: 10px;
    position: relative;
    width: 100%;
}

.filters-page-house-card-narrow-menu {
    position: absolute;
    top: 4px;
    left: 4px;
    display: flex;
    justify-content: space-between;
    width: calc(100% - 8px);
    height: 40px;
    align-items: center;
}

.filters-page-house-card-narrow-menu-originating-system-name,
.filters-page-house-card-narrow-menu-labels {
    display: flex;
    gap: 16px;
    align-items: center;
}

.filters-page-house-card-narrow-menu-originating-system-name {
    padding: 5px 7px;
    font-weight: 500;
    font-size: 12px;
    line-height: 14.52px;
    border-radius: 4px;
    background-color: var(--body-text);
    color: var(--blue);
}

.filters-page-house-card-narrow-menu-labels-right {
    display: flex;
    gap: 16px;
    align-items: center;
    justify-content: center;
    background-color: #2c253c75;
    border-radius: 8px;
    padding: 4px;
}

.filters-page-house-card-narrow-menu-labels-right-note {
    display: flex;
    gap: 6px;
    align-items: center;
    justify-content: center;
    background-color: #2c253c75;
    border-radius: 8px;
    padding: 5px;
}

.filters-page-house-card-narrow-menu-labels-right-note .filters-page-house-card-narrow-menu-note-content-icon {
    margin-left: 0;
    margin-right: 0;
}

.filters-page-house-card-narrow-menu-label-2 p {
    padding: 5px 7px;
    font-weight: 500;
    font-size: 12px;
    line-height: 14.52px;
    border-radius: 4px;
    background-color: var(--blue);
    color: var(--body-text);
}

.filters-page-house-card-narrow-menu-label-1 {
    display: none;
}

.filters-page-house-card-narrow-menu-label-1 p {
    padding: 5px 7px;
    font-weight: 500;
    font-size: 12px;
    line-height: 14.52px;
    border-radius: 4px;
    color: var(--blue);
    background-color: var(--body-text);
}

.filters-page-house-card-narrow-menu-label-disabled-1 p {
    /* padding: 5px 7px;
  font-weight: 500;
  font-size: 12px;
  line-height: 14.52px;
  border-radius: 4px;
  background-color: #FFF;
  color: var(--blue); */
    display: none;
}

.filters-page-house-card-narrow-menu-heard-icon {
    cursor: pointer;
    height: 30px;
    width: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    z-index: 2;
}

.filters-page-house-card-narrow-content {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    gap: 8px;
    position: absolute;
    bottom: 4px;
    left: 4px;
    width: calc(100% - 8px);
    z-index: 1;
    background: #2c353c75;
    border-radius: 5px;
    padding: 4px !important;
    min-height: 85px;
}

.filters-page-house-card-narrow-content-active {
    display: flex;
    flex-direction: column;
    gap: 8px;
    margin: auto 0;
    position: absolute;
    bottom: 16px;
    left: 16px;
    width: calc(100% - 32px);
    z-index: 1;
    background: #2c353c75;
    border-radius: 5px;
    padding: 4px !important
}

.filters-page-house-card-narrow-content-price h4 {
    color: var(--body-text);
    font-size: 20px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    letter-spacing: 0.24px;
}

.filters-page-house-card-narrow-content-residence p {
    color: var(--white);
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    letter-spacing: 0.32px;
}

.filters-page-house-card-narrow-content-size span,
.filters-page-house-card-narrow-content-bd-ba p,
.filters-page-house-card-narrow-content-adress p,
.filters-page-house-card-narrow-content-name p {
    color: var(--white);
    font-family: Inter;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    letter-spacing: 0.24px;
}

.filters-page-house-card-narrow-content-residence {
    line-height: 19px;
}

.filters-page-house-card-narrow-content-adress {
    line-height: 15px;
}

.filters-page-house-card-narrow-content-size {
    height: 17px;
}

.filters-page-house-card-narrow-content-size p {
    line-height: 17px
}

.filters-page-house-card-narrow-content-bd-ba {
    display: flex;
    gap: 16px;
}

.filters-page-house-card-narrow-content-bd-ba>div {
    display: flex;
    align-items: center;
}

.filters-page-house-card-narrow-content-bd-ba p {
    padding-left: 8px;
}

.filters-page-house-card-narrow-content-size span:last-child {
    font-size: xx-small;
    vertical-align: super;
}

.filters-page-house-card-narrow-img-active .filters-page-house-card-narrow-img-container {
    position: relative;
    height: 249px;
    ;
    width: 100%;
    border-radius: 10px;
    overflow: hidden;
    background-color: #959292;
}

.filters-page-house-card-narrow-wrapper:hover {
    cursor: pointer;
    box-shadow: 0 2px 5px 0 rgba(0, 0, 0, .12), 0 2px 10px 0 rgba(0, 0, 0, .8);
}

.filters-page-house-card-narrow-img-container {
    position: relative;
    height: 249px;
    ;
    width: 100%;
    border-radius: 10px;
    overflow: hidden;
    background-color: #959292;
}

.filters-page-house-card-narrow-img-container img {
    height: 100%;
    width: auto;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    -webkit-transform: translate(-50%, -50%) !important;
    border-radius: 10px;
    /* filter: brightness(0.6); */
}

.filters-page-house-card-narrow-img-container img {
    height: 100% !important;
    width: 100% !important;
    object-fit: cover;
}

.filters-page-house-card-narrow-img-active img {
    height: auto;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    -webkit-transform: translate(-50%, -50%) !important;
    width: 100%;
    border-radius: 10px;
    /* filter: brightness(0.6); */
}

.filters-page-house-card-narrow-img-active .slick-slide img,
.filters-page-house-card-narrow-img .slick-slide img {
    margin: auto;
}

.filters-page-house-card-narrow-img-active .slick-dots,
.filters-page-house-card-narrow-img .slick-dots {
    bottom: 16px;
}

.filters-page-house-card-narrow-img-active .slick-dotted .slick-slider,
.filters-page-house-card-narrow-img .slick-dotted .slick-slider {
    margin-bottom: 0px;
}

.filters-page-house-card-narrow-img-active .slick-dots li button::before,
.filters-page-house-card-narrow-img .slick-dots li button::before {
    color: #D9D9D9 !important;
    opacity: 1 !important;
    font-size: 5px !important;
    ;
    line-height: 20px;
}

.filters-page-house-card-narrow-img-active .slick-dots li.slick-active button::before,
.filters-page-house-card-narrow-img .slick-dots li.slick-active button::before {
    color: #D9D9D9 !important;
    font-size: 8px !important;
}

.filters-page-house-card-narrow-img-active .slick-dots li,
.filters-page-house-card-narrow-img .slick-dots li {
    margin: 0 !important;
}

.filters-page-house-card-narrow-img-spiner {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    -webkit-transform: translate(-50%, -50%);
}

/* .filters-page-house-card-narrow-wrapper .slick-next {
  right: 10px !important;
}

.filters-page-house-card-narrow-wrapper .slick-prev {
  left: 10px !important;
  z-index: 2;
} */

.filters-page-house-card-narrow-wrapper-both .filters-page-house-card-narrow-slider-left-arrow,
.filters-page-house-card-narrow-wrapper .filters-page-house-card-narrow-slider-left-arrow {
    position: absolute;
    right: -12px !important;
    top: 50%;
    transform: translate(-50%, -50%);
    -webkit-transform: translate(-50%, -50%);
    z-index: 2;
    border: none;
    border-radius: 50%;
    height: 22px;
    background-color: rgba(0, 0, 0, 0.0);
    cursor: pointer;
}

.filters-page-house-card-narrow-wrapper-both .filters-page-house-card-narrow-slider-left-arrow svg,
.filters-page-house-card-narrow-wrapper .filters-page-house-card-narrow-slider-left-arrow svg {
    margin: auto 0;
    margin-left: 2px;
}

.filters-page-house-card-narrow-wrapper-both .filters-page-house-card-narrow-slider-right-arrow svg,
.filters-page-house-card-narrow-wrapper .filters-page-house-card-narrow-slider-right-arrow svg {
    margin: auto 0;
    margin-right: 2px;
}

.filters-page-house-card-narrow-wrapper-both .filters-page-house-card-narrow-slider-right-arrow,
.filters-page-house-card-narrow-wrapper .filters-page-house-card-narrow-slider-right-arrow {
    position: absolute;
    left: 12px !important;
    top: 50%;
    transform: translate(-50%, -50%);
    -webkit-transform: translate(-50%, -50%);
    z-index: 2;
    border: none;
    height: 22px;
    border-radius: 50%;
    background-color: rgba(0, 0, 0, 0.0);
    cursor: pointer;
}

/* .filters-page-house-card-narrow-img .filters-page-house-card-narrow-img-slide-counter {
  position: absolute;
  bottom: 24px;
  left: 24px;
  color: white;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: 0.32px;
} */

.filters-page-house-card-narrow-img-active .filters-page-house-card-narrow-slider-right-arrow-active {
    position: absolute;
    right: 70px;
    z-index: 2;
    border: none;
    height: 22px;
    border-radius: 50%;
    bottom: 40px;
    background-color: rgba(0, 0, 0, 0.0);
    cursor: pointer;
}

.filters-page-house-card-narrow-img-active .filters-page-house-card-narrow-slider-left-arrow-active {
    position: absolute;
    right: 16px;
    z-index: 2;
    border: none;
    height: 22px;
    border-radius: 50%;
    bottom: 40px;
    background-color: rgba(0, 0, 0, 0.0);
    cursor: pointer;
}

.filters-page-house-card-narrow-img .filters-page-house-card-narrow-img-slide-counter {
    position: absolute;
    right: 8px;
    bottom: 58px;
    transform: translateY(-50%);
    -webkit-transform: translateY(-50%);
    color: white;
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    letter-spacing: 0.24px;
    margin-top: 52px;
    z-index: 2;
    background-color: #2c353c75;
    padding: 2px;
    border-radius: 4px;
}

.filters-page-house-card-narrow-note-link {
    cursor: pointer;
    color: var(--blue);
}

.filters-page-house-card-narrow-img-active .filters-page-house-card-narrow-img-slide-counter {
    width: 76px;
    text-align: center;
}

.filters-page-house-card-narrow-img-active .filters-page-house-card-narrow-img-slide-counter {
    position: absolute;
    bottom: 16px;
    right: 16px;
    color: white;
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    letter-spacing: 0.24px;
    z-index: 2;
}

/* .filters-page-house-card-narrow-wrapper:hover .filters-page-house-card-narrow-img-container{
  -moz-transform: scale(1.1);
  -webkit-transform: scale(1.1);
  -o-transform: scale(1.1);
  -ms-transform: scale(1.1);
  -webkit-transform: scale(1.1);
  transform: scale(1.1);
  -webkit-transition: transform 0.1s ease-in-out;
  -moz-transition: transform 0.1s ease-in-out;
  -ms-transition: transform 0.1s ease-in-out;
  height: 394px;
  border-radius: 0px;
} */

.filters-page-house-card-narrow-wrapper:hover .slick-list {
    border-radius: 10px !important;
    height: 249px;
    ;
}

/* .filters-page-house-card-narrow-wrapper:hover .slick-active { */
.filters-page-house-card-narrow-wrapper-active:hover,
.filters-page-house-card-narrow-wrapper:hover {
    -moz-transform: scale(1.01);
    -webkit-transform: scale(1.01);
    -o-transform: scale(1.01);
    -ms-transform: scale(1.01);
    -webkit-transform: scale(1.01);
    transform: scale(1.01);
    -webkit-transition: transform 0.1s ease-in-out;
    -moz-transition: transform 0.1s ease-in-out;
    -ms-transition: transform 0.1s ease-in-out;
}

.filters-page-house-card-narrow-wrapper:hover .filters-page-house-card-narrow-content-overlay {
    border-radius: 0px !important;
}

.filters-page-house-card-narrow-wrapper:hover {
    cursor: pointer;
    box-shadow: 0 2px 5px 0 rgba(0, 0, 0, .12), 0 2px 10px 0 rgba(0, 0, 0, .8);
}

.filters-page-house-card-narrow-menu-open-house-icon {
    z-index: 2;
    position: absolute;
    bottom: 36px;
    right: 8px;
}

.filters-page-house-card-narrow-menu-open-house-icon img {
    height: 24px;
    width: auto;
}

.filters-page-house-card-narrow-menu-realtytexas-icon {
    height: 24px;
    width: 24px;
    background-image: url('https://cd.realtytexas.com/assets/images/ico-round.png');
    background-size: cover;
    z-index: 3;
}

.filters-page-house-card-narrow-right-icons-box {
    display: flex;
    gap: 12px
}

.filters-page-house-card-narrow-menu-label-disabled-1 {
    display: none;
}

.filters-page-house-card-narrow-menu-note-content-icon {
    margin-left: auto;
    margin-right: 16px;
    cursor: pointer
}

.filters-page-house-card-narrow-menu-note-content-icon-disabled {
    display: none;
}


.filters-page-house-card-narrow-img .filters-page-house-card-heard-list-wrapper-active,
.filters-page-house-card-narrow-img .filters-page-house-card-heard-list-wrapper {
    right: 4px !important;
    top: 48px !important;
}