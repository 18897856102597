.MainHeaderLayer-MainHeader-wrapper {
  width: 100%;
  height: 100%;
  background-color: var(--white);
  position: relative;
}

.MainHeaderLayer-MainHeader-content-wrapper {
  padding: 4px 16px 0 16px;
  background-color: var(--white);
  position: sticky;
  top: 77px;
  /* top: 8px; */
  z-index: 103;
  /* height: 71px; */
  height: 101px;
  display: flex;
  flex-direction: column;
  width: 100%;
  /* align-items: center; */
}

.MainHeaderLayer-MainHeader-bottom {
  width: 100%;
  height: 40px;
  display: flex;
  align-items: center;
}

.MainHeaderLayer-MainHeader-content {
  background-color: var(--white);
  width: 100%;
  height: 55px;
  border: 1px solid var(--gray);
  border-radius: 10px;
  display: flex;
  align-items: center;
  padding: 0 0 0 16px;
  gap: 12px;
}

.MainHeaderLayer-MainHeader-filters-wrapper {
  display: flex;
  align-items: center;
  gap: 12px;
}

.MainHeaderLayer-MainHeader-content #sorting-block-icon-2_5 {
  width: 38px;
}

.MainHeaderLayer-MainHeader-content-filters {
  display: flex;
  gap: 12px;
  margin-left: auto;
}
.MainHeaderLayer-MainHeader-content-SortingInputBlock-wrapper {
  width: 200px !important;
  margin-left: auto;
}

.MainHeaderLayer-MainHeader-content-SortingInputBlock-wrapper input {
  border: none !important;
  padding: 4px 24px !important;
}

.MainHeaderLayer-ListingsInfo-wrapper {
  /* position: absolute;
  left: calc(50% - 45px);
  transform: translate(-50%, 0);
  -webkit-transform: translate(-50%, 0) !important; */
}

.MainHeaderLayer-ListingsInfo-wrapper span {
  color: #2b2b2b;
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.MainHeaderLayer-MainHeader-content-filters:hover
  .sorting-block-icon-2_5-tooltip-all {
  display: block;
  left: -37px;
  bottom: -34px !important;
}

.MainHeaderLayer-MainHeader-content .main-container-wrapper {
  margin-right: 16px;
}

.MainHeaderLayer-FilterBlocksWrapper-wrapper {
  display: flex;
  align-items: center;
  gap: 12px;
}

/* search btn block */

.HeaderMultipleInput-CityInputBlock-wrapper
  .HeaderMultipleInput-active-input-list-outside-search-wrapper {
  top: 12px !important;
  background-color: var(--main-bg);
  display: flex;
  justify-content: center;
  align-items: center;
}

.HeaderMultipleInput-CityInputBlock-wrapper
  .HeaderMultipleInput-active-input-list-outside-search-wrapper
  svg {
  display: block;
}

.HeaderMultipleInput-CityInputBlock-wrapper
  .HeaderMultipleInput-active-input-list-outside-search-wrapper:hover {
  background-color: var(--gray);
}

.MainHeaderLayer-MainHeader-content-SortingInputBlock-wrapper ul {
  max-height: 450px !important;
  top: 33px !important;
  list-style-type: none;
}

.MainHeaderLayer-MainHeader-wrapper ul::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3) !important;
  background-color: #f2f2f2 !important;
  border-radius: 10px !important;
}

.MainHeaderLayer-MainHeader-wrapper ul::-webkit-scrollbar {
  border-radius: 10px !important;
  width: 6px !important;
  background-color: #f2f2f2 !important;
}

.MainHeaderLayer-MainHeader-wrapper ul::-webkit-scrollbar-thumb {
  background-color: var(--gray) !important;
  border-radius: 10px !important;
}

.MainHeaderLayer-MainHeader-content-SortingInputBlock-wrapper
  input::placeholder {
  color: var(--blue) !important;
}

.MainHeaderLayer-MainHeader-content-TripleSwitcherSliderButton-mapOnly {
  display: flex;
  justify-content: center;
  align-items: center;
  /* padding: 0 16px; */
  padding: 0 12px;
  font-size: 14px;
  background-color: var(--third-bg);
  border-radius: 8px;
  cursor: pointer;
  height: 30px;
  margin-right: 16px;
}

.MainHeaderLayer-MainHeader-content-Popular-Filters {
  background-color: var(--third-bg);
  border-radius: 8px;
  cursor: pointer;
  height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 14px;
}

.MainHeaderLayer-MainHeader-content-Popular-Filters-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 8px;
  gap: 8px;
}

.MainHeaderLayer-MainHeader-content-Popular-Filters-icon {
  margin-top: 4px;
}

.sorting-block-icon-3-filters-icons {
  display: none;
}

.MainHeaderLayer-MainHeader-content-TripleSwitcherSliderButton-mapOnly-icon {
  display: none;
}
