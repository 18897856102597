@media screen and (max-width: 18399px) {
    /* .filters-page-house-card-menu-originating-system-name {
    display: none !important;
  } */
}

/* @media screen and (min-width: 2241px) and (max-width: 3041px) {
  .filters-page-house-card-wrapper-active {
    width: calc(100%/2 - 8px)
  }
}

@media screen and (min-width: 3042px) and (max-width: 4242px) {
  .filters-page-house-card-wrapper-active {
    width: calc(100%/3 - 12px)
  }
}

@media screen and (min-width: 4243px) and (max-width: 5543px) {
  .filters-page-house-card-wrapper-active {
    width: calc(100%/4 - 12px)
  }
}

@media screen and (min-width: 5544px) {
  .filters-page-house-card-wrapper-active {
    width: calc(100%/5 - 13px)
  }
} */

@media screen and (min-width: 2241px) and (max-width: 3041px) {
    .filters-page-house-card-wrapper-active-list {
        width: calc(100%/2 - 8px)
    }
}

@media screen and (min-width: 3042px) and (max-width: 4242px) {
    .filters-page-house-card-wrapper-active-list {
        width: calc(100%/3 - 12px)
    }
}

@media screen and (min-width: 4243px) and (max-width: 5543px) {
    .filters-page-house-card-wrapper-active-list {
        width: calc(100%/4 - 12px)
    }
}

@media screen and (min-width: 5544px) {
    .filters-page-house-card-wrapper-active-list {
        width: calc(100%/5 - 13px)
    }
}

/* just listed block */

@media screen and (min-width: 500px) and (max-width: 768px) {

  .filters-page-house-card-wrapper-list .filters-page-house-card-menu-label-1,
  .filters-page-house-card-wrapper .filters-page-house-card-menu-label-1 {
    position: initial;
  }
}

@media screen and (min-width: 1000px) and (max-width: 1199px) {
  .filters-page-house-card-wrapper-list .filters-page-house-card-menu-label-1 {
  /* .filters-page-house-card-wrapper .filters-page-house-card-menu-label-1 { */
    position: initial;
  }
}

@media screen and (min-width: 1350px) and (max-width: 1439px) {
  .filters-page-house-card-wrapper-list .filters-page-house-card-menu-label-1 {
  /* .filters-page-house-card-wrapper .filters-page-house-card-menu-label-1 { */
    position: initial;
  }
}

@media screen and (min-width: 1800px) {
  .filters-page-house-card-wrapper-list .filters-page-house-card-menu-label-1 {
  /* .filters-page-house-card-wrapper .filters-page-house-card-menu-label-1 { */
    position: initial;
  }
}

@media screen and (min-width: 500px) and (max-width: 800px) {

  /* .filters-page-house-card-wrapper-list .filters-page-house-card-menu-label-1, */
  .filters-page-house-card-wrapper .filters-page-house-card-menu-label-1 {
    position: initial;
  }
}

@media screen and (min-width: 901px) and (max-width: 1279px) {

  /* .filters-page-house-card-wrapper-list .filters-page-house-card-menu-label-1, */
  .filters-page-house-card-wrapper .filters-page-house-card-menu-label-1 {
    position: initial;
  }
}

@media screen and (min-width: 1700px) {

  /* .filters-page-house-card-wrapper-list .filters-page-house-card-menu-label-1, */
  .filters-page-house-card-wrapper .filters-page-house-card-menu-label-1 {
    position: initial;
  }
}
