#map-drawOnMap-wrapper {
    position: absolute;
    top: 16px;
    right: 198px;
    z-index: 1;
    height: 30px;
    width: 80px;
    padding-left: 12px;
    padding-right: 6px;
    border-radius: 10px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 14px !important;
    cursor: pointer;
}

#map-drawOnMap-wrapper-both {
    position: absolute;
    top: 80px;
    left: 16px;
    z-index: 1;
    height: 30px;
    width: 80px;
    padding-left: 12px;
    padding-right: 6px;
    border-radius: 10px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 14px !important;
    cursor: pointer;
}

.map-drawOnMap-wrapper-notActive {
    background-color: var(--main-bg);
    color: var(--white);
}

.map-drawOnMap-wrapper-active {
    border: 1px solid var(--main-bg);
    background-color: var(--white);
    color: var(--main-bg);
}

.map-drawOnMap-wrapper-closeIcon-wrapper {
    margin-top: 5px;
    margin-left: 9px;
    height: 14px;
}

.map-drawOnMap-wrapper-closeIcon-wrapper-cursor-Icon {
    margin-left: 6px;
}

.map-drawOnMap-wrapper-list-disable {
    display: none
}

.map-drawOnMap-wrapper-list-li-separator {
    height: 1px;
    border-top: 1px solid var(--gray);
}

.map-drawOnMap-wrapper-list {
    display: flex;
    flex-direction: column;
    background-color: var(--main-bg);
    position: absolute;
    top: 35px;
    width: 185px;
    left: 0;
    z-index: 5;
    border-bottom-left-radius: 6px;
    border-bottom-right-radius: 6px;
    border: 1px solid var(--gray);
    background-color: var(--white);
    /* gap: 4px; */
    box-sizing: border-box;
}

.map-drawOnMap-wrapper-title {
    position: relative;
    display: flex;
}

.map-drawOnMap-wrapper-list-li p {
    background-color: var(--white);
    padding: 4px;
    text-decoration: none;
    font-weight: 400;
    font-size: 14px !important;
    line-height: 19px;
    font-family: inter;
    /* color: var(--white); */
    margin-bottom: 0;
    color: var(--main-bg);
}

.map-drawOnMap-wrapper-list-li:last-child p {
    border-bottom-left-radius: 6px;
    border-bottom-right-radius: 6px;
}

.map-drawOnMap-wrapper-list-li p:hover {
    background-color: var(--blue);
    color: var(--body-text);
    cursor: pointer;
    opacity: 1;
}

.map-drawOnMap-wrapper-title-draw {
    line-height: 23px;
}
