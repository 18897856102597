#filters-page-sorting-input-block {
  height: 100%;
  width: 100%;
}

#filters-page-sorting-input-block ul {
  list-style-type: none;
}

#filters-page-block-filter-block-narrow ul {
  list-style-type: none;
  opacity: initial;
}

#filters-page-block-filter-block-narrow ul p,
#filters-page-sorting-input-block ul p {
  list-style-type: none;
  opacity: 1;
}

