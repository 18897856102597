/* @media screen and (min-width: 1920px) {
    .sorting-block-header-wrapper-430 {
        gap: 0 !important;
        margin-bottom: 0 !important;
    }
} */

/* @media screen and (min-width: 1440px) {
    .sorting-block-icon-map-list {
        order: 4 !important;
        margin-left: auto !important;
    }

    .sorting-block-mapList-wrapper {
        right: -22px !important;
    }

    #sorting-block-input-wrapper {
        max-width: 370px;
        margin-right: 16px;
        height: 55px;
    }

    .sorting-block-header-wrapper>div {
        justify-content: flex-end;
    }

    #sorting-block-icons-wrapper {
        position: relative;
        justify-content: flex-start;
    }

    .sorting-block-icon-map-list {
        position: absolute;
        right: -75px
    }

    .sorting-block-header-wrapper-sticky,
    .sorting-block-header-wrapper {
        gap: 0;
        padding: 16px 16px 0 16px;
    }

    .sorting-block-header-wrapper>div {
        width: calc((100% - 370px)/2)
    }

    .sorting-block-header-wrapper-block-displaying-listings {
        border-top-left-radius: 0;
        border-bottom-left-radius: 0;
        border-left: none;
        justify-content: right;
    }

    #sorting-block-icons-wrapper {
        border-top-right-radius: 0;
        border-bottom-right-radius: 0;
        border-right: none;
    }

} */

/* @media screen and (min-width: 1200px) and (max-width: 1919px) {
    .sorting-block-icon-map-list {
        order: 4 !important;
        margin-left: auto !important;
    }

    .sorting-block-mapList-wrapper {
        right: -22px !important;
    }

    .sorting-block-header-wrapper-sticky,
    .sorting-block-header-wrapper {
        display: flex;
        gap: 16px;
        padding: 16px 16px 0 16px;
    }

    .sorting-block-header-wrapper-430 {
        gap: 0 !important;
        margin-bottom: 0 !important;
    }
} */

/* @media screen and (min-width: 769px) and (max-width: 1199px) {
    .displaying-listings-loading {
        display: none !important;
    }

    #filters-block-wrapper-form-narrow {
        border-top: 1px solid var(--gray);
    }

    #filters-block-filter-home-features-wrapper {
        height: 660px;
    }

    #filters-block-filter-PropertyType {
        position: relative;
    }

    .filters-block-filter-PropertyType-title-icon {
        position: absolute;
        right: calc(-100% + 24px);
        top: 31px
    }

    #filters-page-block-filter-block-narrow #filters-block-filter-home-features {
        border: none;
        padding: 24px;
        margin-bottom: 0px;
    }

    #filters-block-filter-quick-search {
        border: none !important;
        width: 100% !important;
    }

    #filters-page-block-filter-block-narrow #filters-block-filter-filter-location {
        border: none;
        border-radius: 0;
        display: flex;
        width: 100%;
        padding: 24px;
        margin-bottom: 0px;
    }

    #filters-page-block-filter-block-narrow .filters-block-filter-filter-location-content-wrapper {
        display: flex;
        flex-direction: row;
        gap: 24px;
        width: 100%;
        flex-wrap: wrap;
    }

    #filters-page-block-filter-block-narrow .filters-block-filter-filter-location-content {
        width: calc(50% - 12px);
    }

    #filters-block-filter-details {
        flex-direction: row;
        gap: 48px;
    }

    #filters-block-filter-details-2,
    #filters-block-filter-details-1 {
        display: flex;
        flex-direction: column;
        gap: 12px;
        width: 50%
    }

    #filters-block-wrapper-form-narrow-bottom-checkboxes {
        display: flex;
        flex-wrap: wrap;
        flex-direction: row;
    }

    #filters-block-wrapper-form-narrow-bottom-checkboxes #filters-block-filter-status {
        width: 50%;
        border: none;
    }

    #filters-block-wrapper-form-narrow-bottom-checkboxes #filters-block-filter-show-only {
        width: 100%;
        border: none;
    }

    #filters-block-wrapper-form-narrow-bottom-checkboxes #filters-block-filter-timeframe {
        border: none;
        width: 50%;
    }

    #filters-page-block-filter-block-narrow #filters-block-filters-buttons-block-wrapper {
        width: 100%;
        border-radius: 0px;
        border: none;
        border-top: 1px solid var(--gray) !important;
        padding: 24px 27px 24px 27px;
        position: fixed;
        z-index: 10;
        bottom: 0;
        left: 0;
    }

    #filters-page-block-filter-block-narrow #filters-block-filter-show-only {
        margin-bottom: 92px;
    }

    #filters-page-block-filter-block-narrow #filters-block-filter-details-2,
    #filters-page-block-filter-block-narrow #filters-block-filter-details-1 {
        gap: 24px;
    }

    #filters-page-block-filter-block-narrow .filters-block-filter-min-max-Price-input-list-block,
    #filters-page-block-filter-block-narrow .filters-block-filter-bath-levels-beds-input-list-block {
        gap: 24px
    }

    .filters-page-block-filter-block-narrow-propertyTypes {
        display: flex;
        flex-direction: row;
    }

    .sorting-block-header-wrapper {
        flex-wrap: wrap;
        position: relative;
        padding: 16px 16px 32px !important;
        padding-bottom: 32px !important;
    }

    #sorting-block-input-wrapper {
        width: calc(50% - 8px) !important;
    }

    #sorting-block-icons-wrapper {
        width: calc(50% - 8px);
        justify-content: flex-end;
    }

    #filters-page-house-cards-block-wrapper-displaying-listings {
        border: none;
        display: block;
        padding: 0;
        position: absolute;
        top: 78px;
        width: auto;
    }
} */

@media screen and (max-width: 18399px) {
  .displaying-listings-loading {
    display: none !important;
  }

  #filters-page-block-filter-block-narrow #filters-block-filter-show-only,
  #filters-page-block-filter-block-narrow
    #filters-block-filters-buttons-block-wrapper,
  #filters-page-block-filter-block-narrow #filters-block-filter-timeframe,
  #filters-page-block-filter-block-narrow #filters-block-filter-status,
  #filters-page-block-filter-block-narrow #filters-block-filter-home-features,
  #filters-page-block-filter-block-narrow
    #filters-block-filter-filter-location {
    border-radius: 0;
    border: none;
  }

  #filters-block-filter-quick-search {
    /* border: none !important; */
    /* border-top: none; */
    width: 100% !important;
    /* height: 100%; */
  }

  #filters-page-block-filter-block-narrow
    #filters-block-filters-buttons-block-wrapper {
    border-radius: 0;
    border-top: 1px solid var(--gray);
    position: fixed;
    z-index: 10;
    width: 100%;
    bottom: 0;
  }

  #filters-page-block-filter-block-narrow #filters-block-filter-show-only {
    margin-bottom: 92px;
  }

  #filters-page-block-filter-block-narrow
    #filters-page-block-filter-block-narrow-top-buttons-block {
    border: 1px solid var(--gray) !important;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
  }

  #filters-block-wrapper-form-narrow {
    padding-top: 50px;
    padding-top: 0px;
  }

  .sorting-block-header-wrapper {
    flex-wrap: wrap;
    position: relative;
    padding: 22px 16px;
    padding-bottom: 38px !important;
  }

  .sorting-block-header-wrapper-sticky {
    padding: 16px 16px 38px !important;
  }

  #sorting-block-icons-wrapper {
    border: none;
    padding: 0;
    position: absolute;
    top: 81px;
    right: 16px;
    width: auto;
    gap: 6px;
  }

  #filters-page-house-cards-block-wrapper-displaying-listings {
    border: none;
    display: block;
    padding: 0;
    /* position: absolute;
        top: 88px; */
    width: auto;
    /* margin-top: 14px; */
    margin-top: 12px;
  }

  .sorting-block-header-wrapper-sticky .sorting-block-mapList-wrapper,
  .sorting-block-header-wrapper .sorting-block-mapList-wrapper {
    right: -34px !important;
    top: 36px !important;
    width: 102px !important;
  }

  #filters-page-block-content-right {
    width: calc(100vw - 32px) !important;
  }
}

/* @media screen and (min-width: 491px) and (max-width: 700px) {
  .sorting-block-header-wrapper-block-displaying-listings span:last-child {
    display: none;
  }

  .sorting-block-header-wrapper-block-displaying-listings-showing {
    display: none !important;
  }
} */

/* @media screen and (min-width: 1200px) and (max-width: 1400px) {
  .sorting-block-header-wrapper-block-displaying-listings span:last-child {
    display: none;
  }
} */

/* @media screen and (max-width: 490px) {
  .new-bottomHeaderPaginationComponent-wrapper-list {
    flex-direction: column;
  }
  .new-sorting-block-btn-wrapper {
    justify-content: space-between;
  }

  .new-bottomHeaderPaginationComponent-wrapper-list
    .new-filters-page-house-cards-block-wrapper-displaying-listings-wrapper {
    justify-content: left;
  }

  .new-wrapper-top-list {
    height: 156px !important;
  }
} */

@media screen and (max-width: 440px) {
  .sorting-block-header-wrapper-sticky,
  .sorting-block-header-wrapper {
    padding: 16px 16px 96px !important;
  }

  .filters-page-house-cards-block-wrapper-displaying-listings399 {
    /* top:128px !important; */
    /* left: 50% !important;
        transform: translateX(-50%);
        -webkit-transform: translateX(-50%); */
  }
}

/* @media screen and (min-width: 1200px) and (max-width: 1499px) {
    .sorting-block-header-wrapper-block-displaying-listings-showing {
        display: none !important;
    }
} */

/* @media screen and (min-width: 1200px) and (max-width: 1439px) {
    #filters-page-block-filter-block-narrow .filters-block-filter-PropertyType-content {
        width: calc(33% - 14px);
    }

    #filters-page-block-filter-block-narrow .filters-block-filter-PropertyType-content-wrapper {
        flex-direction: row;
        flex-wrap: wrap;
    }

    #filters-page-block-filter-block-narrow #filters-block-filter-PropertyType {
        width: 70%;
        border: none;
        position: relative;
    }

    #filters-page-block-filter-block-narrow #filters-block-filter-PropertySubType {
        width: 30%;
        border: none;
    }

    #filters-page-block-filter-block-narrow .filters-page-block-filter-block-narrow-propertyTypes {
        display: flex;
    }

    #filters-page-block-filter-block-narrow .filters-block-filter-PropertyType-title-icon {
        position: absolute;
        right: calc(-43% + 24px);
        top: 31px;
    }

    #filters-page-block-filter-block-narrow #filters-block-filter-show-only,
    #filters-page-block-filter-block-narrow #filters-block-filter-timeframe,
    #filters-page-block-filter-block-narrow #filters-block-filter-status,
    #filters-page-block-filter-block-narrow #filters-block-filter-home-features,
    #filters-page-block-filter-block-narrow #filters-block-filter-filter-location {
        border: none;
    }

    #filters-page-block-filter-block-narrow #filters-block-filter-filter-location,
    #filters-page-block-filter-block-narrow #filters-block-filter-home-features-wrapper {
        width: 50%;
    }

    #filters-page-block-filter-block-narrow .filters-page-block-filter-block-narrow-FL-HF-wrapper {
        display: flex;
    }

    #filters-page-block-filter-block-narrow #filters-block-filter-show-only,
    #filters-page-block-filter-block-narrow #filters-block-filter-timeframe,
    #filters-page-block-filter-block-narrow #filters-block-filter-status {
        width: 33.333%;
    }

    #filters-page-block-filter-block-narrow #filters-block-wrapper-form-narrow-bottom-checkboxes {
        display: flex;
        flex-direction: row;
    }

    #filters-page-block-filter-block-narrow #filters-block-filter-quick-search {
        border: none;
    }
} */

/* @media screen and (min-width: 1440px) and (max-width: 1919px) {
    #filters-page-block-filter-block-narrow .filters-block-filter-PropertyType-content {
        width: calc(25% - 18px);
    }

    #filters-page-block-filter-block-narrow .filters-block-filter-PropertyType-content-wrapper {
        flex-direction: row;
        flex-wrap: wrap;
    }

    #filters-page-block-filter-block-narrow #filters-block-filter-PropertyType {
        width: 70%;
        border: none;
        position: relative;
    }

    #filters-page-block-filter-block-narrow #filters-block-filter-PropertySubType {
        width: 30%;
        border: none;
    }

    #filters-page-block-filter-block-narrow .filters-page-block-filter-block-narrow-propertyTypes {
        display: flex;
    }

    #filters-page-block-filter-block-narrow .filters-block-filter-PropertyType-title-icon {
        position: absolute;
        right: calc(-41% + 5px);
        top: 31px;
    }

    #filters-page-block-filter-block-narrow #filters-block-filter-show-only,
    #filters-page-block-filter-block-narrow #filters-block-filter-timeframe,
    #filters-page-block-filter-block-narrow #filters-block-filter-status,
    #filters-page-block-filter-block-narrow #filters-block-filter-home-features,
    #filters-page-block-filter-block-narrow #filters-block-filter-filter-location {
        border: none;
    }

    #filters-page-block-filter-block-narrow #filters-block-filter-filter-location,
    #filters-page-block-filter-block-narrow #filters-block-filter-home-features-wrapper {
        width: 50%;
    }

    #filters-page-block-filter-block-narrow .filters-page-block-filter-block-narrow-FL-HF-wrapper {
        display: flex;
    }

    #filters-page-block-filter-block-narrow #filters-block-filter-show-only,
    #filters-page-block-filter-block-narrow #filters-block-filter-timeframe,
    #filters-page-block-filter-block-narrow #filters-block-filter-status {
        width: 33.333%;
    }

    #filters-page-block-filter-block-narrow #filters-block-wrapper-form-narrow-bottom-checkboxes {
        display: flex;
        flex-direction: row;
    }

    #filters-page-block-filter-block-narrow #filters-block-filter-quick-search {
        border: none;
    }

    #filters-page-block-filter-block-narrow #filters-block-filters-buttons-block-wrapper {
        border-bottom-left-radius: 0px;
        border-bottom-right-radius: 0px;
    }
} */

/* @media screen and (min-width: 1920px) {
    #filters-page-block-filter-block-narrow .filters-block-filter-PropertyType-content {
        width: calc(25% - 18px);
    }

    #filters-page-block-filter-block-narrow .filters-block-filter-PropertyType-content-wrapper {
        flex-direction: row;
        flex-wrap: wrap;
    }

    #filters-page-block-filter-block-narrow #filters-block-filter-PropertyType {
        width: 70%;
        border: none;
        position: relative;
    }

    #filters-page-block-filter-block-narrow #filters-block-filter-PropertySubType {
        width: 30%;
        border: none;
    }

    #filters-page-block-filter-block-narrow .filters-page-block-filter-block-narrow-propertyTypes {
        display: flex;
    }

    #filters-page-block-filter-block-narrow .filters-block-filter-PropertyType-title-icon {
        position: absolute;
        right: calc(-100% + 24px);
        top: 31px;
    }

    #filters-page-block-filter-block-narrow #filters-block-filter-show-only,
    #filters-page-block-filter-block-narrow #filters-block-filter-timeframe,
    #filters-page-block-filter-block-narrow #filters-block-filter-status,
    #filters-page-block-filter-block-narrow #filters-block-filter-home-features,
    #filters-page-block-filter-block-narrow #filters-block-filter-filter-location {
        border: none;
    }

    #filters-page-block-filter-block-narrow #filters-block-filter-filter-location,
    #filters-page-block-filter-block-narrow #filters-block-filter-home-features-wrapper {
        width: 50%;
    }

    #filters-page-block-filter-block-narrow .filters-page-block-filter-block-narrow-FL-HF-wrapper {
        display: flex;
    }

    #filters-page-block-filter-block-narrow #filters-block-filter-show-only,
    #filters-page-block-filter-block-narrow #filters-block-filter-timeframe,
    #filters-page-block-filter-block-narrow #filters-block-filter-status {
        width: 33.333%;
    }

    #filters-page-block-filter-block-narrow #filters-block-wrapper-form-narrow-bottom-checkboxes {
        display: flex;
        flex-direction: row;
    }

    #filters-page-block-filter-block-narrow #filters-block-filter-quick-search {
        border: none;
    }

    #filters-page-block-filter-block-narrow #filters-block-filters-buttons-block-wrapper {
        border-bottom-left-radius: 0px;
        border-bottom-right-radius: 0px;
    }

    #filters-page-block-filter-block-narrow {
        width: 1520px !important;
        left: calc(50% - 770px)
    }

    #filters-page-block-filter-block-narrow #filters-block-filters-buttons-block-wrapper {
        border-left: none;
        border-right: none;
        border-bottom: none;
    }
} */
