.filters-page-house-card-heard-list-wrapper-active,
.filters-page-house-card-heard-list-wrapper {
    position: absolute;
    right: 16px;
    top: 55px;
    /* padding: 16px 40px 16px 24px; */
    flex-direction: column;
    align-items: flex-start;
    /* gap: 16px; */
    border-radius: 10px;
    /* background: var(--body-text); */
    background: var(--white);
    box-shadow: 4px 0px 30px 0px rgba(0, 0, 0, 0.25);
    z-index: 2;
}

.filters-page-house-card-heard-list-wrapper {
    display: none;
}

.filters-page-house-card-heard-list-wrapper-active {
    display: flex;
    padding: 8px;
    flex-direction: row;
}

.filters-page-house-card-heard-list-line {
    width: calc(100% + 64px);
    height: 1px;
    background: rgba(144, 143, 143, 0.40);
    margin-left: -24px;
}

.filters-page-house-card-heard-list-wrapper-active p,
.filters-page-house-card-heard-list-wrapper p {
    padding-left: 12px;
    color: var(--gray);
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
}

.filters-page-house-card-heard-list-ul {
    display: flex;
    align-items: center;
}

/* .filters-page-house-card-heard-list-ul div:first-child {
  width: 30px;
} */

.filters-page-house-card-heard-list-ul div:first-child svg {
    display: block;
    margin: 0 auto;
}

.filters-page-house-card-heard-list-opened :hover {
    background-color: #e0e0e0;
    border-radius: 8px;
    cursor: pointer;
}

.filters-page-house-card-heard-list-opened svg {
    margin: 10px !important;
}

.filters-page-house-card-heard-list-possibility svg {
    margin: 10px 12px !important;
}