#filters-block-filter-status {
    border: 1px solid var(--gray);
    background-color: var(--third-bg);
    padding: 24px;
    display: flex;
    flex-direction: column;
    border-bottom: none;
    gap: 24px;
    -moz-transition: height 0.4s ease;
    -webkit-transition: height 0.4s ease;
    -o-transition: height 0.4s ease;
    transition: height 0.4s ease;
    /* height: 272px; */
    height: 352px;
}

.filters-block-filter-status-content-checkbox-closed-DatePicker-wrapper {
    display: flex;
    gap: 12px;
    width: 100%;
}

.filters-block-filter-status-content-checkbox-closed-DatePicker-block {
    width: 50%;
    font-size: 14px;
    font-family: Inter;
    position: relative;
}

 
.DatePicker-block-close-from-wrapper:has(.filters-block-filter-status-content-checkbox-closed-DatePicker-block-close:hover),
.DatePicker-block-close-to-wrapper:has(.filters-block-filter-status-content-checkbox-closed-DatePicker-block-close:hover) {
    opacity: 0.7;
}

.filters-block-filter-status-content-checkbox-closed-DatePicker-block-close {
    position: absolute;
    right: 8px;
    top: 18px;
    cursor: pointer;
}

.filters-block-filter-status-content-checkbox-closed-DatePicker-wrapper input {
    width: 100%;
    height: 50px;
    border-radius: 10px;
    border: 1px solid var(--gray);
    cursor: pointer;
    padding: 19px 18px;
    color: var(--blue);
}

.filters-block-filter-status-content-checkbox-closed-DatePicker-wrapper input:is(:focus, :active, :visited, :focus-visible) {
    outline: none!important;
    box-shadow: none;
  }

.filters-block-filter-status-content-checkbox-closed-DatePicker-wrapper input:-ms-input-placeholder {
    color: var(--gray);
  }
  
  .filters-block-filter-status-content-checkbox-closed-DatePicker-wrapper input::placeholder {
    color: var(--gray);
  }

.filters-block-filter-status-content-checkbox-closed-DatePicker-title p {
    font-size: 14px;
    position: absolute;
    top: -20px;
    left: 19px;
}

#filters-block-filter-status-title {
    display: flex;
    justify-content: space-between;
    cursor: pointer;
}

.filters-block-filter-status-title-icon {
    display: flex;
    align-items: center;
}

#filters-block-filter-status-title h4 {
    color: var(--main-bg);
    font-size: 26px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
}

#filters-block-filter-status-content {
    display: flex;
    flex-direction: column;
    gap: 12px;
    -moz-transition: opacity 0.6s ease;
    -webkit-transition: opacity 0.6s ease;
    -o-transition: opacity 0.6s ease;
    transition: opacity 0.6s ease;
}

.collapsed-filter-status {
    height: 80px !important;
    overflow: hidden;
}

#filters-block-filter-status .collapsed-filter-status-content {
    overflow: hidden !important;
    opacity: 0 !important;
}

.filters-block-filter-status-content-checkbox {
    display: flex;
    gap: 24px;
    height: 24px;
    align-items: center;
}

.filters-block-filter-status-content-checkbox-closed {
    display: flex;
    flex-direction: column;
    gap: 30px
}

.filters-block-filter-status-content-checkbox p {
    color: var(--second-bg);
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: 0.32px;
}

#filters-block-filter-status-content-checkbox-1,
#filters-block-filter-status-content-checkbox-2,
#filters-block-filter-status-content-checkbox-3,
#filters-block-filter-status-content-checkbox-4,
#filters-block-filter-status-content-checkbox-5 {
    height: 28px;
    cursor: pointer;
}
