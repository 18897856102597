.filters-page-house-card-note-textarea {
  /* position: absolute;
  z-index: 2; */
  width: 100%;
  /* top: 50%;
  left: 0;
  transform: translate(0, -50%);
  -webkit-transform: translate(0, -50%); */
  height: 50%;
  border: 1px solid var(--gray);
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
  display: flex;
  flex-direction: column;
  cursor: initial;
  background-color: var(--third-bg);
  border-top: none;
  position: relative;
}

.filters-page-house-card-note-textarea-narrow {
  width: 100%;
  height: 50%;
  border: 1px solid var(--gray);
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
  display: flex;
  flex-direction: column;
  cursor: initial;
  background-color: var(--third-bg);
  border-top: none;
}

.filters-page-house-card-note-textarea-narrow textarea {
  /* width: calc(100% -32px); */
  height: 100%;
  border: none;
  outline: none;
  font-family: Inter;
  color: var(--main-bg);
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  letter-spacing: 0.24px;
  padding: 8px;
  background-color: white;
  border-radius: 10px;
  margin: 8px;
  box-shadow: 0 0 10px #719ece;
  padding-right: 95px;
}

.filters-page-house-card-note-textarea-narrow
  .filters-page-house-card-note-textarea-buttons-box {
  display: flex;
  background-color: var(--third-bg);
  justify-content: right;
  border-radius: 10px;
  margin: 0 8px 8px;
  gap: 16px;
  position: absolute;
  top: 50%;
  transform: translate(0%, -50%);
  -webkit-transform: translate(0%, -50%);
  right: 12px;
}

.filters-page-house-card-note-textarea
  .filters-page-house-card-note-textarea-buttons-box {
  display: flex;
  background-color: var(--third-bg);
  justify-content: right;
  border-radius: 10px;
  margin: 0 16px 16px 16px;
  gap: 16px;
  position: absolute;
  top: 50%;
  transform: translate(0%, -50%);
  -webkit-transform: translate(0%, -50%);
  right: 12px;
}

.filters-page-house-card-note-textarea-button-1 {
  border-radius: 4px;
  background-color: var(--red);
  height: 24px;
  width: 20%;
  min-width: 70px;
  text-align: center;
  color: var(--body-text);
  cursor: pointer;
}

.filters-page-house-card-note-textarea-narrow
  .filters-page-house-card-note-textarea-button-2 {
  border-radius: 4px;
  background-color: var(--blue);
  height: 32px;
  width: 100%;
  min-width: 70px;
  color: var(--body-text);
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
}

.filters-page-house-card-note-textarea
  .filters-page-house-card-note-textarea-button-2 {
  border-radius: 4px;
  background-color: var(--blue);
  height: 32px;
  width: 15%;
  min-width: 70px;
  color: var(--body-text);
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
}

.filters-page-house-card-note-textarea textarea {
  width: calc(100% -32px);
  height: 100%;
  border: none;
  outline: none;
  font-family: Inter;
  color: var(--main-bg);
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  letter-spacing: 0.24px;
  padding: 8px;
  background-color: white;
  border-radius: 10px;
  margin: 16px;
  box-shadow: 0 0 10px #719ece;
  padding-right: 95px;
}

.filters-page-house-card-note-textarea
  textarea:is(:focus, :active, :focus-visible) {
  outline: none !important;
  border: 1px solid var(--gray);
  /* box-shadow: 0 0 0px #719ECE; */
}

.filters-page-house-card-note-textarea-disabled {
  display: none;
}
