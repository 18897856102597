#filters-block-filter-filter-location {
  border: 1px solid var(--gray);
  background-color: var(--third-bg);
  padding: 24px;
  display: flex;
  flex-direction: column;
  border-bottom: none;
  gap: 24px;
  -moz-transition: max-height 0.6s ease;
  -webkit-transition: max-height 0.6s ease;
  -o-transition: max-height 0.6s ease;
  transition: max-height 0.6s ease;
  max-height: 2000px;
}

.filters-block-filter-filter-location-content-wrapper {
  display: flex;
  flex-direction: column;
  gap: 24px;
}

.collapsed-filter-location {
  max-height: 80px !important;
  overflow: hidden;
  -moz-transition: max-height 0.6s ease;
  -webkit-transition: max-height 0.6s ease;
  -o-transition: max-height 0.6s ease;
  transition: max-height 0.6s ease;
}

#filters-block-filter-filter-location .collapsedInput {
  overflow: hidden !important;
  opacity: 0 !important;
}

.filters-block-filter-filter-location-content-wrapper {
  -moz-transition: opacity 0.6s ease;
  -webkit-transition: opacity 0.6s ease;
  -o-transition: opacity 0.6s ease;
  transition: opacity 0.6s ease;
  opacity: 1;
}

#filters-block-filter-filter-location-title {
  width: 100%;
  display: flex;
  justify-content: space-between;
  cursor: pointer;
}

.filters-block-filter-filter-location-title-icon {
  display: flex;
  align-items: center;
}

#filters-block-filter-filter-location-title h4 {
  color: var(--main-bg);
  font-size: 26px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

.filters-block-filter-filter-location-content {
  display: flex;
  flex-direction: column;
  gap: 16px;
  height: auto !important;
  position: relative;
}

.filters-block-filter-filter-location-address-input-tooltip {
  position: absolute;
  bottom: -16px;
}

.filters-block-filter-filter-location-address-input-tooltip p {
  font-size: 12px !important;
}

.filters-block-filter-filter-location-content p {
  color: var(--second-bg);
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: 0.32px;
}

.filters-block-filter-filter-location-address-input {
  height: 50px;
}

.filters-block-filter-filter-location-address-input-multiple {
  height: auto;
}

.filters-page-multiple-active-input-list-wrapper input,
.filters-block-filter-filter-location-address-input-multiple input {
  color: var(--blue);
}

.filters-block-filter-filter-location-address-input ul {
  width: 100% !important;
}
