.pagination {
  display: flex;
  align-items: center;
  justify-content: center;
  list-style: none;
  outline: transparent;
  font-size: 14px;
}
.pagination li {
  margin: 0 2px;
  height: 30px;
  min-width: 30px;
}
.pagination li > a {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 30px;
  min-width: 30px;
  padding: 0 10px;
  color: var(--main-bg);
  background-color: transparent;
  border: 1px solid transparent;
  border-radius: 4px;
  outline: transparent;
  cursor: pointer;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: 0.32px;
  border: 1px solid var(--gray);
}

.pagination li:first-child a {
  border: none;
}

.pagination li:last-child a {
  border: none;
}
.pagination li > a:hover {
  background-color: #f2f2f2;
}

.pagination .pagination_active {
  background-color: var(--third-bg);
}

.pagination .pagination_active > a,
.pagination .pagination_active > a:hover,
.pagination .pagination_active > a:focus {
  /* background-color: var(--third-bg); */
  /* border-color:var(--gray); */
  border-color: var(--blue);
  outline: transparent;
  cursor: default;
}
.pagination > .disabled > a {
  cursor: default;
}

.pagination > .disabled a:hover {
  background-color: white;
}

.pagination .disabled svg path {
  fill: var(--gray);
}
