@media screen and (max-width: 18399px) {
    /* .filters-page-house-card-narrow-menu-label-2 {
    display: none !important;
  } */
}

/* @media screen and (max-width: 1500px) {

    .filters-page-house-card-narrow-menu-labels-right {
        flex-direction: column;
        margin-top: 40px;
    }

    .filters-page-house-card-narrow-menu-labels-right-note {
        flex-direction: column;
        margin-top: 60px;
    }

    .filters-page-house-card-narrow-menu-labels-right .rt-share-dropdown {
        top: 32px;
        right: -5px
    }

    .filters-page-house-card-narrow-img .filters-page-house-card-heard-list-wrapper-active {
        top: 52px !important;
    }
}

@media screen and (max-width: 1300px) {
    .filters-page-house-card-narrow-menu-label-2 {
        max-width: 120px !important;
    }

    .filters-page-house-card-narrow-menu-label-2 p {
        white-space: nowrap;
        width: 100%;
        overflow: hidden;
        -o-text-overflow: ellipsis;
        text-overflow: ellipsis;
    }
} */

@media screen and (min-width: 1440px) and (max-width: 1676px) {
    .filters-page-house-card-narrow-wrapper {
        height: calc(1.05*398px);
    }
    
    .filters-page-house-card-narrow-wrapper .filters-page-house-card-narrow-img-container {
        height: calc(1.05*398px);
    }

    /* BOTH */
    .filters-page-house-card-narrow-wrapper-both {
        height: calc(0.7*398px)!important;
    }
    .filters-page-house-card-narrow-wrapper-both .filters-page-house-card-narrow-img-container {
        height: calc(0.7*398px) !important;
    }
}

@media screen and (min-width: 1677px) and (max-width: 1970px) {
    .filters-page-house-card-narrow-wrapper {
        height: calc(1.15*398px);
    }
    
    .filters-page-house-card-narrow-wrapper .filters-page-house-card-narrow-img-container {
        height: calc(1.15*398px);
    }

    /* BOTH */
    .filters-page-house-card-narrow-wrapper-both {
        height: calc(0.8*398px)!important;
    }
    .filters-page-house-card-narrow-wrapper-both .filters-page-house-card-narrow-img-container {
        height: calc(0.8*398px) !important;
    }
}

@media screen and (min-width:1971px) and (max-width: 2284px) {
    .filters-page-house-card-narrow-wrapper {
        height: calc(1.45*398px);
    }
    
    .filters-page-house-card-narrow-wrapper .filters-page-house-card-narrow-img-container {
        height: calc(1.45*398px);
    }

    /* BOTH */
    .filters-page-house-card-narrow-wrapper-both {
        height: calc(0.9*398px)!important;
    }
    .filters-page-house-card-narrow-wrapper-both .filters-page-house-card-narrow-img-container {
        height: calc(0.9*398px) !important;
    }
}

@media screen and (min-width:2285px) and (max-width: 2598px) {
    .filters-page-house-card-narrow-wrapper {
        height: calc(1.7*398px);
    }
    
    .filters-page-house-card-narrow-wrapper .filters-page-house-card-narrow-img-container {
        height: calc(1.7*398px);
    }

    /* BOTH */
    .filters-page-house-card-narrow-wrapper-both {
        height: calc(1.2*398px)!important;
    }
    .filters-page-house-card-narrow-wrapper-both .filters-page-house-card-narrow-img-container {
        height: calc(1.2*398px) !important;
    }
}

@media screen and (min-width:2599px) and (max-width: 2906px) {
    .filters-page-house-card-narrow-wrapper {
        height: calc(2*398px);
    }
    
    .filters-page-house-card-narrow-wrapper .filters-page-house-card-narrow-img-container {
        height: calc(2*398px);
    }

    /* BOTH */
    .filters-page-house-card-narrow-wrapper-both {
        height: calc(1.5*398px)!important;
    }
    .filters-page-house-card-narrow-wrapper-both .filters-page-house-card-narrow-img-container {
        height: calc(1.5*398px) !important;
    }
}

@media screen and (min-width:2907px) and (max-width: 3224px) {
    .filters-page-house-card-narrow-wrapper {
        height: calc(2.3*398px);
    }
    
    .filters-page-house-card-narrow-wrapper .filters-page-house-card-narrow-img-container {
        height: calc(2.3*398px);
    }

    /* BOTH */
    .filters-page-house-card-narrow-wrapper-both {
        height: calc(1.8*398px)!important;
    }
    .filters-page-house-card-narrow-wrapper-both .filters-page-house-card-narrow-img-container {
        height: calc(1.8*398px) !important;
    }
}

@media screen and (min-width:3225px) and (max-width: 3535px) {
    .filters-page-house-card-narrow-wrapper {
        height: calc(2.6*398px);
    }
    
    .filters-page-house-card-narrow-wrapper .filters-page-house-card-narrow-img-container {
        height: calc(2.6*398px);
    }

    /* BOTH */
    .filters-page-house-card-narrow-wrapper-both {
        height: calc(2.1*398px)!important;
    }
    .filters-page-house-card-narrow-wrapper-both .filters-page-house-card-narrow-img-container {
        height: calc(2.1*398px) !important;
    }
}

@media screen and (min-width:3536px) and (max-width: 3848px) {
    .filters-page-house-card-narrow-wrapper {
        height: calc(2.9*398px);
    }
    
    .filters-page-house-card-narrow-wrapper .filters-page-house-card-narrow-img-container {
        height: calc(2.9*398px);
    }

    /* BOTH */
    .filters-page-house-card-narrow-wrapper-both {
        height: calc(2.4*398px)!important;
    }
    .filters-page-house-card-narrow-wrapper-both .filters-page-house-card-narrow-img-container {
        height: calc(2.4*398px) !important;
    }
}

@media screen and (min-width:3849px) and (max-width: 4160px) {
    .filters-page-house-card-narrow-wrapper {
        height: calc(3.2*398px);
    }
    
    .filters-page-house-card-narrow-wrapper .filters-page-house-card-narrow-img-container {
        height: calc(3.2*398px);
    }

    /* BOTH */
    .filters-page-house-card-narrow-wrapper-both {
        height: calc(2.7*398px)!important;
    }
    .filters-page-house-card-narrow-wrapper-both .filters-page-house-card-narrow-img-container {
        height: calc(2.7*398px) !important;
    }
}

@media screen and (min-width:4161px) and (max-width: 4480px) {
    .filters-page-house-card-narrow-wrapper {
        height: calc(3.2*398px);
    }
    
    .filters-page-house-card-narrow-wrapper .filters-page-house-card-narrow-img-container {
        height: calc(3.2*398px);
    }

    /* BOTH */
    .filters-page-house-card-narrow-wrapper-both {
        height: calc(2.7*398px)!important;
    }
    .filters-page-house-card-narrow-wrapper-both .filters-page-house-card-narrow-img-container {
        height: calc(2.7*398px) !important;
    }
}

@media screen and (min-width:4481px) and (max-width: 4790px) {
    .filters-page-house-card-narrow-wrapper {
        height: calc(3.5*398px);
    }
    
    .filters-page-house-card-narrow-wrapper .filters-page-house-card-narrow-img-container {
        height: calc(3.5*398px);
    }

    /* BOTH */
    .filters-page-house-card-narrow-wrapper-both {
        height: calc(3*398px)!important;
    }
    .filters-page-house-card-narrow-wrapper-both .filters-page-house-card-narrow-img-container {
        height: calc(3*398px) !important;
    }
}

@media screen and (min-width:4791px) and (max-width: 5100px) {
    .filters-page-house-card-narrow-wrapper {
        height: calc(3.8*398px);
    }
    
    .filters-page-house-card-narrow-wrapper .filters-page-house-card-narrow-img-container {
        height: calc(3.8*398px);
    }

    /* BOTH */
    .filters-page-house-card-narrow-wrapper-both {
        height: calc(3.3*398px)!important;
    }
    .filters-page-house-card-narrow-wrapper-both .filters-page-house-card-narrow-img-container {
        height: calc(3.3*398px) !important;
    }
}

@media screen and (min-width:5101px) and (max-width: 5410px) {
    .filters-page-house-card-narrow-wrapper {
        height: calc(4.1*398px);
    }
    
    .filters-page-house-card-narrow-wrapper .filters-page-house-card-narrow-img-container {
        height: calc(4.1*398px);
    }

    /* BOTH */
    .filters-page-house-card-narrow-wrapper-both {
        height: calc(3.6*398px)!important;
    }
    .filters-page-house-card-narrow-wrapper-both .filters-page-house-card-narrow-img-container {
        height: calc(3.6*398px) !important;
    }
}

@media screen and (min-width:5411px) and (max-width: 5730px) {
    .filters-page-house-card-narrow-wrapper {
        height: calc(4.4*398px);
    }
    
    .filters-page-house-card-narrow-wrapper .filters-page-house-card-narrow-img-container {
        height: calc(4.4*398px);
    }

    /* BOTH */
    .filters-page-house-card-narrow-wrapper-both {
        height: calc(3.9*398px)!important;
    }
    .filters-page-house-card-narrow-wrapper-both .filters-page-house-card-narrow-img-container {
        height: calc(3.9*398px) !important;
    }
}

@media screen and (min-width:5731px) and (max-width: 6040px) {
    .filters-page-house-card-narrow-wrapper {
        height: calc(4.7*398px);
    }
    
    .filters-page-house-card-narrow-wrapper .filters-page-house-card-narrow-img-container {
        height: calc(4.7*398px);
    }

    /* BOTH */
    .filters-page-house-card-narrow-wrapper-both {
        height: calc(4.2*398px)!important;
    }
    .filters-page-house-card-narrow-wrapper-both .filters-page-house-card-narrow-img-container {
        height: calc(4.2*398px) !important;
    }
}

@media screen and (min-width:6041px) {
    .filters-page-house-card-narrow-wrapper {
        height: calc(5*398px);
    }
    
    .filters-page-house-card-narrow-wrapper .filters-page-house-card-narrow-img-container {
        height: calc(5*398px);
    }

    /* BOTH */
    .filters-page-house-card-narrow-wrapper-both {
        height: calc(4.5*398px)!important;
    }
    .filters-page-house-card-narrow-wrapper-both .filters-page-house-card-narrow-img-container {
        height: calc(4.5*398px) !important;
    }
}