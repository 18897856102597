.alert-map-message-wrapper {
  position: relative;
  background-color: var(--main-bg);
  border-radius: 10px;
  padding: 10px 15px;
  z-index: 1055;
  border: 1px solid var(--gray);
  min-width: 256px; 
}

.alert-map-message-close {
  position: absolute;
  right: 12px;
  top: 12px;
  cursor: pointer;
}

.alert-map-message-content {
  display: flex;
  flex-direction: column;
  gap: 32px;
}

.alert-map-message-content-text-box {
  display: flex;
  flex-direction: column;
  gap: 4px;
  min-height: 38px;
}

.alert-map-message-content-header,
.alert-map-message-content-text {
  text-align: left;
}

.alert-map-message-content-header p{
  font-weight: 600;
  font-size: 18px;
  color: var(--body-text);
}

.alert-map-message-content-text p {
  font-weight: 400;
  font-size: 14px;
  line-height: 16.94px;
  color: var(--body-text);
}
